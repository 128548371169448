import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Decline = () => {



  return (
    <div className="row m-0">
      <div className="col-6">
        <div className="min-100vh d-flex flex-column  justify-content-center">
          <div className="welcome-message"
            style={{
              textAlign: "right"
            }}
          >
            <img className="w-50" src="/images/BevBridgeRFPLogo.png" />
          </div>
          <div className="login-image align-self-center">
            {/* <img className=" " src="/assets/media/bg/illustration_login.png" /> */}
          </div>
        </div>
      </div>
      <div className="col-6 col-lg-6 d-flex align-items-center min-100vh w-50">
        <div>
          <h2 className="mb-10 fw-bold">You have declined the BevBridge RFP Terms of Use</h2>
          <p style={{ width: "63%" }}>You have been logged out of our account. If you would like to continue using BevBridge RFP, please log in and agree to the Terms of Use. If you have any questions about the Terms of Use, please email <a href="mailto:support@bevbridge.com">support@bevbridge.com</a>.</p>
          <Link to={`/login`}
            style={{
              paddingRight: "70px",
              paddingLeft: "70px"
          }}
          className="btn btn-pill btn-dark">
          Login
        </Link>
      </div>
    </div>
    </div >
    )
}

export default Decline;