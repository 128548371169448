import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { verificationRequest, loginRequest } from "plugins/redux/reducers/AuthReducer";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues, // Use getValues to access field values
  } = useForm<any>({
    mode: "onBlur",
  });

  const onSubmit = async (data: Object) => {
    if (isValid) {
      data["email"] = id;
      const response = await dispatch(verificationRequest(data) as any);
      const userdata = {};
      if (response.payload.success) {
        userdata.email = response.payload.success.email;
        userdata.password = response.payload.success.email;
        userdata.remember = 0;
        userdata.verfication = 1;
        const user_response = await dispatch(loginRequest(userdata) as any);
        if (!user_response.payload?.success) {
          setErrorMessage(user_response.payload);
        }
        navigate('/rfps');
      } else {
        setErrorMessage(response.payload);
      }
    }
  };
  return (
    <div className="row m-0">
      <div className="col-4">
        <div className="left-logo">
          <img
            className="w-25 p-6"
            src="/images/BevBridgeRFPLogo.png"
          />
        </div>
        <div className="min-100vh d-flex flex-column  justify-content-center">
          <div className="welcome-message ">
            <h1 className="font-weight-bold text-uppercase mb-10 fw-bold">
              Verification
            </h1>
          </div>
          {/* <div className="login-image align-self-center">
            <img className="" src="/assets/media/bg/password-reset-bot.png" />
          </div> */}
        </div>
      </div>
      <div className="col-12 col-lg-8 d-flex align-items-center justify-content-center min-100vh bg-custom-light">
        <div style={{ width: "70%" }}>
          <h1 className="mb-10 fw-bold">Verification</h1>
          {/* <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-3">
              <div className="col-12 col-md-12 mb-5">
                <TextField
                  InputProps={{ sx: { borderRadius: "50px" } }}
                  label="Verification code"
                  variant="outlined"
                  fullWidth
                  {...register("verification_code", {
                    required: "Verification code is required",
                  })}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-12 d-flex flex-column">
                <span className="text-danger fw-bold">{errorMessage}</span>
                <button
                  type="submit"
                  className="btn btn-custom-primary w-100 mb-3"
                  disabled={!isValid}
                >
                  Submit
                </button>
              </div>
            </div>
          </form> */}
          <p className="font-weight-bold text-uppercase mb-10 fw-bold">
              Check Your Email To Verify Your Account
            </p>
            <p className="font-weight-bold text-uppercase fw-bold">
          If you do not receive the email in your inbox, check your junk, spam, or quarantine folder.
          </p>
          <p className="font-weight-bold text-uppercase fw-bold">
          You can also try adding <a href="https://rfp.bevbridge.com">rfp.bevbridge.com</a> to your safe sender list.
          </p>
          <p className="font-weight-bold text-uppercase fw-bold">
          If you’re still having issues with verification please contact <a href="mailto:support@bevbridge.com">support@bevbridge.com</a>
          </p>
        </div>
      </div>
    </div>
  );
}
