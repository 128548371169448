import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  addProduct,
  addPriceByState,
  addProductsBulk,
  fetchProduct,
  selectProduct,
  updateProduct,
} from "src/plugins/redux/reducers/ProductsReducer";
import {
  setHeaderTitle,
  setHeaderActionBtns,
  setHeaderArrowBack,
  setShowSearch,
  setShowPremiumHeader
} from "src/plugins/redux/reducers/HeaderReducer";

import {
  selectProminentFlavors,
  fetchProminentFlavors,
  selectRegions,
  fetchRegions,
  selectSizes,
  fetchUnitSizes,
  selectUnitsPerCase,
  fetchUnitsPerCase,
  fetchItemClosures,
  selectItemClosures,
  fetchProducers,
  selectProducers,
  fetchCountryOfOrigins,
  selectCountries,
  addDropdownData,
} from "src/plugins/redux/reducers/DropdownDataReducer";
import {
  fetchSuppliers,
  selectSuppliers,
} from "src/plugins/redux/reducers/AdminReducers";

import PricingByStateModal from "./PricingByStateModal";
import UploadedProductsPreview from "./UploadedProductsPreview";
import selectStyles from "src/themes/Helpers";
import existsIn from "src/componenets/ExistsIn";
import {
  selectCategories,
  selectCategoriesStatus,
  fetchCategories,
  poursOptions,
  selectPours,
  addType,
  addSubType
} from "src/plugins/redux/reducers/CategoriesReducer";
import { currentUserSelector } from "src/plugins/redux/reducers/AuthReducer";
import { uploadImageToS3 } from "src/plugins/redux/reducers/RFPsReducer";
import { useParams, useLocation } from "react-router-dom";
import { Button, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';
import downloadFileFromLink from "src/componenets/DownloadFileFromLink";
const Update = forwardRef((props: any, ref: any): JSX.Element => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const error = useSelector((state) => state.products.error);
  const categories = useSelector(selectCategories);
  const customSelectStyles = selectStyles.formSelectStyles;
  const prominent_flavors = useSelector(selectProminentFlavors);
  const regions = useSelector(selectRegions);
  const sizes = useSelector(selectSizes);
  const UnitsPerCase = useSelector(selectUnitsPerCase);
  const itemClosures = useSelector(selectItemClosures);
  const suppliers = useSelector(selectSuppliers);
  const Producers = useSelector(selectProducers);
  const countries = useSelector(selectCountries);
  const currentUser = useSelector(currentUserSelector);
  const pours = useSelector(selectPours);

  const [producers, setProducers] = useState();
  const [product, setProduct] = useState<any>();
  const [otherSize, setSizes] = useState(false);
  const [mainSize, setMainSize] = useState();
  const [itemClosure, setItemClosure] = useState();
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedType, setSelectedType] = useState();
  const [productsFile, setProductsFile] = useState();
  const [uploadedProducts, setUplaodedProducts] = useState([]);
  const [statesPricing, setStatePricing] = useState();
  const [otherProducer, setOtherProducer] = useState();
  const [otherProminent_flavors, setOtherprominent_flavors] = useState();
  const [otherFlavors, setOtherFlavors] = useState(false);
  const [addFlavors, setAddFlavors] = useState();
  const [other_Sise, setOther_Sise] = useState();
  const [addSize, setAddSize] = useState();
  const [otherCountry, setOtherCountry] = useState();
  const [otherRegion, setOtherRegion] = useState();
  const [selectedCat, setSelectedCat] = useState();
  const [selectedPour, setSelectedPour] = useState();
  const [files, setFiles] = useState({});
  const [recipes, setRecipes] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [status, setStatus] = useState('active');
  const [categoryValue, setCatgoryValue] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const [selectedVareitale, setSelectedVareitale] = useState(null);
  const [previewProducts, setPreviewProducts] = useState<boolean>(false);

  const { handleSubmit, control,
    formState: { errors, isValid, dirtyFields }, reset, register, setValue
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    dispatch(setShowPremiumHeader(true));
  }, [location, dispatch]);

  useEffect(() => {
    setProducers(undefined);
    setProduct(undefined);
    setSizes(false);
    setMainSize(undefined);
    setItemClosure(undefined);
    setSelectedStates([]);
    setSelectedCategory(undefined);
    setSelectedType(undefined);
    setProductsFile(undefined);
    setUplaodedProducts([]);
    setStatePricing(undefined);
    setOtherProducer(undefined);
    setOtherprominent_flavors(undefined);
    setOtherFlavors(false);
    setAddFlavors(undefined);
    setOther_Sise(undefined);
    setAddSize(undefined);
    setOtherCountry(undefined);
    setOtherRegion(undefined);
    setSelectedCat("");
    setSelectedPour(undefined);
    setFiles({});
    setRecipes([]);
    setSubTypes([]);
    setStatus('active');
    setCatgoryValue();
    setTypeValue();
    setSelectedVareitale();

    reset({
      units_per_case: '',
      name: '',
      description: '',
      abv: '',
      national_pricing: '',
      videos: '',
      awards: '',
      notes: ''
    });
  }, [id, location])

  useEffect(() => {
    dispatch(setHeaderActionBtns([]));
    dispatch(setHeaderArrowBack(false));
    dispatch(setShowSearch(false));
    dispatch(fetchCategories());
    dispatch(fetchProminentFlavors());
    dispatch(fetchRegions());
    dispatch(fetchUnitsPerCase());
    dispatch(fetchItemClosures());
    dispatch(fetchSuppliers());
    dispatch(fetchProducers());
    dispatch(fetchCountryOfOrigins());
    dispatch(poursOptions());
  }, [])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    var key = event.target.name;
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        var filesLocal = files;
        const file_url = await dispatch(
          uploadImageToS3({ file: file, dirName: "products" })
        );
        setFiles((prevFiles) => ({
          ...prevFiles,
          [key]: file_url ? file_url.payload : "",
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRecipesFileChnage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const recipesFiles = event.target.files;
    console.log(recipesFiles);
    if (recipesFiles) {
      Object.keys(recipesFiles).forEach(async (key) => {
        const file = recipesFiles[key];
        if (file instanceof Blob) {
          const file_url = await dispatch(
            uploadImageToS3({ file: file, dirName: "products" })
          );
          setRecipes((prevRecipes) => [...prevRecipes, file_url ? file_url.payload : ""]);
        }
      });
    }
  };

  const removeFile = (key) => {
    setFiles((prevFiles) => {
      const updatedFiles = { ...prevFiles };
      delete updatedFiles[key];
      return updatedFiles;
    });
  };

  const handlePreviewCsvProducts = () => {
    setPreviewProducts(true);
    setProductsFile(null);
  }

  const uploadToS3 = async (link, dirName) => {
    try {
      const file = await downloadFileFromLink(link);
      const result = await dispatch(uploadImageToS3({ file, dirName }));
      return result.payload;
    } catch (error) {
      console.error(`Error uploading file to S3: ${error}`);
      return '';
    }
  };

  const hanldeSubmitBulkUpload = async () => {
    try {
      const formattedProducts = await Promise.all(
        uploadedProducts.map(async (uploadedProduct) => {
          const {
            sell_sheets: sellSheets,
            product_image_front: productImageFront,
            product_image_back: productImageBack,
            label_image_front: labelImageFront,
            label_image_back: labelImageBack,
            logo,
            recipes: uploadedRecipes,
            producer,
            category,
            type,
            subtype,
            prominent_flavors: prominentFlavors,
            country_of_origin: countryOfOrigin,
            region,
            unit_size: unitSize,
            other_available_sizes: otherAvailableSizes,
            item_closure: itemClosure,
            ...rest
          } = uploadedProduct;

          const sellSheetsLink = sellSheets ? await uploadToS3(sellSheets, 'products') : '';
          const productImageFrontLink = productImageFront ? await uploadToS3(productImageFront, 'products') : '';
          const productImageBackLink = productImageBack ? await uploadToS3(productImageBack, 'products') : '';
          const labelImageFrontLink = labelImageFront ? await uploadToS3(labelImageFront, 'products') : '';
          const labelImageBackLink = labelImageBack ? await uploadToS3(labelImageBack, 'products') : '';
          const logoLink = logo ? await uploadToS3(logo, 'products') : '';
          const recipes = uploadedRecipes
            ? await Promise.all(uploadedRecipes.split(',').map((recipe) => uploadToS3(recipe, 'products')))
            : '';

          return JSON.stringify({
            ...rest,
            producer_id: producer?.id,
            supplier_id: currentUser?.company_id,
            category_id: category?.id,
            category_type_id: type?.id,
            category_sub_type_id: subtype?.id,
            prominent_flavors_id: prominentFlavors?.map((flavor) => flavor?.id).join(','),
            country_of_origin_id: countryOfOrigin?.id,
            region_id: region?.id,
            unit_size_id: unitSize?.id,
            abv: rest.abv,
            price_per_unit: rest.price_per_case,
            units_per_case: rest.units_per_case,
            other_available_sizes: otherAvailableSizes?.map((size) => size?.id).join(','),
            item_closure_id: itemClosure?.id,
            sell_sheets: sellSheetsLink,
            product_image_front: productImageFrontLink,
            product_image_back: productImageBackLink,
            label_image_front: labelImageFrontLink,
            label_image_back: labelImageBackLink,
            logos: logoLink,
            recipes: recipes,
            videos: rest.videos,
            awards: rest.awards,
            notes: rest.notes,
            status: 'active'
          });
        })
      );

      const response = await dispatch(addProductsBulk(formattedProducts));
      console.log('🚀 ~ hanldeSubmitBulkUpload ~ response:', response);

      if (response.payload?.success) {
        toast.success('Products Added Successfully');
        navigate('/products');
      }
    } catch (error) {
      console.error(error);
    }
  };




  const handleCreateDropdownData = async (type, inputValue) => {
    let newData;
    if (type == "unitSizes") {
      newData = await dispatch(
        addDropdownData({
          type: type,
          name: inputValue,
          category_id: selectedCategory?.id
        })
      );
    } else if (type == "subType") {
      newData = await dispatch(
        addSubType({
          'category_type_id': selectedType?.id ? selectedType?.id : selectedType?.value,
          name: inputValue
        })
      );
    } else {
      newData = await dispatch(
        addDropdownData({
          type: type,
          name: inputValue,
        })
      );
    }

    switch (type) {
      case "countyOfOrigin":
        dispatch(fetchCountryOfOrigins());
        break;
      case "prominentFlavors":
        dispatch(fetchProminentFlavors());
        break;
      case "producers":
        dispatch(fetchProducers());
        break;
      case "suppliers":
        dispatch(fetchSuppliers());
        break;
      case "regions":
        dispatch(fetchRegions());
      case "unitSizes":
        dispatch(fetchUnitSizes(selectedCategory?.id));
        break;
      case "subType":
        setSubTypes([...subTypes, { label: inputValue, value: newData?.payload?.success }]);
        break;
      default:
        console.log(`not found`);
    }

    return newData?.payload?.success;
  }

  useEffect(() => {
    if (otherProminent_flavors) {
      const tr = otherProminent_flavors?.some((other) => other?.value == "other")
      setOtherFlavors(tr)
    }
  }, [otherProminent_flavors]);



  useEffect(() => {
    setSubTypes(selectedType?.sub_type?.map((subtype, index) => {
      if (index === selectedType?.sub_type?.length - 1) {
        return [
          { label: subtype.name, value: subtype.id },
          { label: "Other", value: "other" },
        ];
      }
      return { label: subtype.name, value: subtype.id };
    }).flat());
  }, [selectedType]);



  useEffect(() => {
    async function fetchProductData() {
      const response = await dispatch(fetchProduct(id));
      const productData = response.payload.success;
      setProduct(productData);
      if (productData?.productSizeInfo?.length > 0) {
        const productSize = productData.productSizeInfo[0];
        setMainSize({ label: productSize?.name, value: productSize?.id });
      }
      setOther_Sise(productData?.productSizeInfo?.slice(1)?.map((size, index) => {
        return { label: size?.name, value: size?.id };
      }));
      setOtherprominent_flavors(productData?.prominentFlavorsInfo?.map((falvor, index) => {
        return { label: falvor?.name, value: falvor?.id };
      }));
      setOtherCountry({ label: productData?.countyOfOriginInfo?.name, value: productData?.countyOfOriginInfo?.id });
      setOtherRegion({ label: productData?.regionsInfo?.name, value: productData?.regionsInfo?.id });
      setOtherProducer({ label: productData?.producerInfo?.name, value: productData?.producerInfo?.id });
      setItemClosure({ label: productData?.itemClosureInfo?.name, value: productData?.itemClosureInfo?.id });

      setFiles((prevFiles) => {
        const updatedFiles = { ...prevFiles };
        productData.assets.forEach((data) => {
          updatedFiles[data.assets_type] = data.asset_file;
        });
        return updatedFiles;
      });
      const prominentFlavorIds = productData.prominentFlavorsInfo?.map(
        (data: any) => data.id
      );

      const joinedFlavorIds = prominentFlavorIds?.join(",");
      const sizeIds = productData?.productSizeInfo?.slice(1)?.map(
        (data: any) => data?.id
      );
      const joinedSizeIds = sizeIds?.join(",");
      setCatgoryValue({
        value: productData?.catinfo?.id,
        label: productData?.catinfo?.name,
      });
      setTypeValue({
        value: productData?.categoryTypeInfo?.category_id,
        label: productData?.categoryTypeInfo?.name,
      });
      setSelectedVareitale({
        value: productData?.categorySubTypeInfo?.category_type_id,
        label: productData?.categorySubTypeInfo?.name,
      });

      setRecipes(productData?.recipes?.map((recipe) => {
        return recipe.asset_file;
      }) ?? []);
      let mainProductSize;
      if (productData.productSizeInfo?.length > 0) {
        mainProductSize = productData?.productSizeInfo?.map(({ id, name }, index) => {
          if (index === 0) {
            return {
              value: id,
              label: name,
            };
          }
          return null;
        })?.filter(Boolean)[0];
      }

      reset({
        ...productData,
        sizes: joinedSizeIds,
        prominent_flavors_id: joinedFlavorIds,
        main_size: mainProductSize?.value,
      });
    }

    if (id) {
      fetchProductData();
      dispatch(setHeaderTitle("Edit Product"));
    } else {
      dispatch(setHeaderTitle("Add Product"));
    }
  }, [id]);

  useEffect(() => {
    if (categories && product) {
      const productCategory = categories?.find((category) => category?.id == product?.catinfo?.id);
      setSelectedCategory(productCategory);
      setSelectedType(productCategory?.types?.find((type) => type?.id == product?.categoryTypeInfo?.id));
    }
  }, [categories, product])

  useEffect(() => {
    dispatch(fetchUnitSizes(selectedCategory?.id));
  }, [selectedCategory]);


  const handleProductsFileChange = (e) => {
    const file = e.target.files[0];
    setProductsFile(file.name);
    Papa.parse(file, {
      header: true,
      complete: (result) => {
        const csvData = result.data.map((row) => {
          const productCategory = existsIn(categories, row["Category"]);
          const productType = productCategory?.types?.find((type) => type.name.includes(row["Type"]));
          console.log(row["Other Available Sizes"]?.split(","))
          return {
            category: productCategory,
            name: row["Product Name"],
            description: row["Product Description"],
            supplier: existsIn(suppliers, row["Supplier"]),
            producer: existsIn(Producers, row["Producer"]) ?? { name: row["Producer"] },
            type: productType,
            pour: existsIn(pours, row["Placement"]) ?? { id: null, name: row["Placement"] },
            subtype: existsIn(productType?.sub_type, row["Varietal/Sub-Type"]) ?? { id: null, name: row['Varietal/Sub-Type'] },
            abv: row.ABV,
            prominent_flavors: row["Prominent Flavors"]?.split(",")?.map((flavor) => { return existsIn(prominent_flavors, flavor) ?? { id: null, name: flavor } }),
            country_of_orgin: existsIn(countries, row["Country of Orgin"]) ?? { id: null, name: row["Country of Orgin"] },
            region: existsIn(regions, row.Region) ?? { id: null, name: row.Region },
            unit_size: existsIn(sizes, row["Unit Size"]),
            units_per_case: row["Units per Case"],
            price_per_case: row["Price per Case - Case 1"],
            other_available_sizes: row["Other Available Sizes"]?.split(",")?.map((size) => { return existsIn(sizes, size) }),
            item_closure: existsIn(itemClosures, row["Item Closure"]),
            sell_sheets: row["Sell Sheets (link)"],
            product_image_front: row["Product Image - Front (link)"],
            product_image_back: row["Product Image - Back (link)"],
            label_image_front: row["Label Image - Front (link)"],
            label_image_back: row["Label Image - Back (link)"],
            logo: row['Logos (link)'],
            recipes: row["Recipes (link)"],
            videos: row["Videos (links)"],
            awards: row["Awards / Accolades"],
            notes: row["Notes, if any"]
          }
        });
        setUplaodedProducts(csvData.filter((item) => item.name));
      },
    });
  };

  useEffect(() => {
    for (let i = 0; i < product?.PricingByState?.length; i++) {
      const item = product.PricingByState[i];
      const stateExist = selectedStates?.some(
        (state) => state.state.state_id == item.state_id
      );

      if (stateExist) {
        for (let j = 0; j < selectedStates.length; j++) {
          const state = selectedStates[j];
          if (state.state.state_id == item.state_id) {
            state.prices.push({
              price: item.price,
              distributor: item.distributor,
            });
            break;
          }
        }
      } else {
        selectedStates.push({
          state: item,
          prices: [
            {
              price: item.price,
              distributor: item.distributor,
            },
          ],
        });
      }
    }
    setSelectedStates([...selectedStates]);
    const restructuredData = [];

    for (const item of selectedStates) {
      const stateId = item.state.id;

      for (const priceItem of item.prices) {
        const distributor = priceItem.distributor;
        const price = priceItem.price;

        const restructuredItem = {
          state_id: stateId,
          distributor: distributor,
          price: price,
        };
        console.log(`restructuredItem = ${restructuredItem}`);

        restructuredData.push(restructuredItem);
      }
    }

    setStatePricing(restructuredData);
  }, [product]);


  const onSubmit = async (data: any) => {
    data.company_id = currentUser.company_id;
    data.user_id = currentUser.id;
    data.supplier_id = currentUser.company_id;

    if (!data.national_pricing || data.national_pricing == '') {
      const prices = statesPricing?.map(item => parseFloat(item.price));
      const sum = prices?.reduce((acc, curr) => acc + curr, 0);
      const averagePrice = sum / prices?.length;
      if (!isNaN(averagePrice / data.units_per_case)) {
        data.price_per_unit = averagePrice;
        data.national_pricing = averagePrice;
      }
    } else {
      if (data.national_pricing !== null) {
        data.price_per_unit = data.national_pricing;
      } else {
        data.price_per_unit = null;
      }
    }

    for (const key in data) {
      if (typeof data[key] === "number") {
        data[key] = data[key].toString();
      }
    }

    Object.keys(files).forEach((file) => {
      data[file] = files[file];
    });

    data.recipes = JSON.stringify(recipes);

    for (const key of ["pour_id", "item_closure_id", "category_sub_type_id"]) {
      if (data[key] === null || data[key] === undefined) {
        delete data[key];
      }
    }

    if (selectedVareitale?.value == "other" && selectedVareitale?.name) {
      const newData = await dispatch(
        addDropdownData({
          type: 'categorySubTypes',
          name: selectedVareitale?.name,
        })
      );
      data.category_sub_type_id = newData?.payload?.success.toString()
    }

    if (otherProducer?.value == "other" && otherProducer?.name) {
      const newData = await dispatch(
        addDropdownData({
          type: 'producers',
          name: otherProducer?.name,
        })
      );
      data.producer_id = newData?.payload?.success.toString()
    }

    if (otherCountry?.value == "other" && otherCountry?.name) {
      const newData = await dispatch(
        addDropdownData({
          type: 'countyOfOrigin',
          name: otherCountry?.name,
        })
      );
      data.country_of_origin_id = newData?.payload?.success.toString()
    }

    if (otherRegion?.value == "other" && otherRegion?.name) {
      const newData = await dispatch(
        addDropdownData({
          type: 'regions',
          name: otherRegion?.name,
        })
      );
      data.region_id = newData?.payload?.success.toString()
    }

    if (addFlavors && otherFlavors) {
      const newData = await dispatch(
        addDropdownData({
          type: 'prominentFlavors',
          name: addFlavors
        })
      );
      const newFlavor = newData?.payload?.success.toString()
      const flavor = otherProminent_flavors
        .map((item) => item.value)
        .join(",")
      data.prominent_flavors_id = flavor.replace("other", newFlavor)
    }

    data.sizes = mainSize.value + ',' + data.sizes;

    data.status = status;

    if (product && product?.id) {
      const response = await dispatch(updateProduct(data));
      const id = response.payload.success;
      if (id !== undefined || null) {
        toast.success("Product Updated!");
        navigate(`/products/${id}`);
      }
      if (id && statesPricing && statesPricing.length > 0) {
        statesPricing.map((price) => {
          price.product_id = id;
        });
        dispatch(addPriceByState(statesPricing));
      }
    } else {

      const response = await dispatch(addProduct(data));
      const id = response.payload?.success;
      toast.success("Product Added!");
      navigate(`/products/${id}`);
      const product_id = response.payload.success;
      if (product_id && statesPricing && statesPricing.length > 0) {
        statesPricing.map((price) => {
          price.product_id = product_id;
        });
        console.log(statesPricing);
        dispatch(addPriceByState(statesPricing));
      }
    }
  };


  if (previewProducts) {
    return <UploadedProductsPreview
      uploadedProducts={uploadedProducts}
      suppliers={suppliers}
      setUplaodedProducts={setUplaodedProducts}
      producers={Producers}
      categories={categories}
      flavors={prominent_flavors}
      countries={countries}
      regions={regions}
      sizes={sizes}
      itemClosures={itemClosures}
      setPreviewProducts={setPreviewProducts}
      handleCreateDropdownData={handleCreateDropdownData}
      hanldeSubmitBulkUpload={hanldeSubmitBulkUpload}
    />
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} ref={ref}>
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="row mb-6">
              {product === undefined && (
                <div className="col-12 col-md-12">
                  <span className="d-block kt_section">Bulk Upload by CSV</span>
                  <div>
                    <span className="d-block mb-3 text-muted">
                      <span className="text-custom-primary">Add CSV</span> (All of
                      the categories and fields will be automatically filled in)
                    </span>
                    <div className="d-flex align-items-center gap-2">
                      <button
                        type="button"
                        className="btn btn-pill btn-outline-custom-primary"
                        data-toggle="modal"
                        data-target="#uploadProductsModal"
                      >
                        <i className="la la-upload"></i>
                        Upload CSV
                      </button>
                    </div>
                  </div>
                  <hr />
                </div>
              )}
            </div>
            {error && (
              <div className="bg-danger text-white fw-bold p-4 my-2 w-fit">
                {error.error}
              </div>
            )}
            {product === undefined && (
              <div className="row mb-3">
                <div className="col-12 col-md-12">
                  <span className="d-block kt_section">Add Product Manually</span>
                </div>
              </div>
            )}
            <div className="card mb-6 ">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="row mb-4">
                      <div className="col-12 col-md-12">
                        <label className="w-100">Category*</label>
                        <div class="d-flex align-items-center gap-1">
                          <Controller
                            {...register("category_id", { required: true })}
                            control={control}
                            render={({ field: { onChange, value, ref, name } }) => (
                              <Select
                                placeholder=""
                                className={errors?.category_id ? "invalid-input" : ""}
                                isClearable
                                styles={customSelectStyles}
                                value={categoryValue}
                                options={
                                  categories?.length > 0 &&
                                  categories.map((category) => ({
                                    value: category.id,
                                    label: category.name,
                                  }))
                                }
                                onChange={(category) => {
                                  setOther_Sise([])
                                  setCatgoryValue(
                                    categories.find((categoryItem) => {
                                      categoryItem.id === category?.value,
                                        categoryItem.name === category?.label;
                                    })
                                  );
                                  setSelectedCategory(categories.find((categoryItem) => categoryItem.id === category?.value));
                                  onChange(category?.value);
                                  setSelectedCat(category?.label);
                                  setTypeValue();
                                  setSelectedVareitale();
                                  setSizes(true)
                                }}
                              />
                            )}
                          />
                          {errors?.category_id && (
                            <Tooltip title="Category is required" arrow>
                              <ErrorIcon color="error" />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-md-12">
                        <label className="w-100">Product Name*</label>
                        <div class="d-flex align-items-center gap-1">
                          <input
                            {...register("name", { required: true })}
                            type="text"
                            className={"form-control rounded-pill " + (errors?.name ? "invalid-input" : "")}
                            name="name"
                          />
                          {errors?.name && (
                            <Tooltip title="Product Name is required" arrow>
                              <ErrorIcon color="error" />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-12 col-md-12">
                        <label className="w-100">Product Description</label>
                        <textarea
                          {...register("description")}
                          className="form-control  rounded-xl"
                          style={{ resize: "none" }}
                          name="description"
                        />
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-12 col-md-12">
                        <label className="w-100">Producer{(selectedCategory?.id == 1 || selectedCategory?.id == 2) && '*'}</label>
                        <div class="d-flex align-items-center gap-1">
                          <Controller
                            {...register("producer_id", { required: selectedCategory?.id == 1 || selectedCategory?.id == 2 })}
                            control={control}
                            render={({
                              field: { onChange, value, ref, name },
                            }) => (
                              <CreatableSelect
                                isClearable
                                styles={customSelectStyles}
                                value={otherProducer}
                                placeholder=""
                                className={errors?.producer_id ? "invalid-input" : ""}
                                onCreateOption={async (newValue) => {
                                  const newProducer = await handleCreateDropdownData(
                                    "producers",
                                    newValue
                                  );
                                  setOtherProducer({ label: newValue, value: newProducer });
                                  onChange(newProducer);
                                }}
                                options={
                                  Producers?.length > 0 &&
                                  Producers.map((data, index) => {
                                    if (index === Producers.length - 1) {
                                      return [
                                        { value: data.id, label: data.name },
                                        { value: 'other', label: 'Other' },
                                      ];
                                    } else {
                                      return { value: data.id, label: data.name };
                                    }
                                  }).flat()?.sort((a, b) => {
                                    if (a.label === "Other") {
                                      return 1;
                                    } else if (b.label === "Other") {
                                      return -1;
                                    } else {
                                      return a.label.localeCompare(b.label);
                                    }
                                  })
                                }
                                onChange={(val) => {
                                  setOtherProducer(val)
                                  onChange(val.value)
                                }}
                              />
                            )}
                          />
                          {errors?.producer_id && (
                            <Tooltip title="Producer is required" arrow>
                              <ErrorIcon color="error" />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                    {otherProducer?.value == 'other' &&
                      <div className="row">
                        <div className="col-12 col-md-12 mb-4">
                          <label className="w-100">Please Enter Your Option Of Producer</label>
                          <input
                            type="text"
                            className="form-control  rounded-pill"
                            placeholder="Spicify your Producer"
                            onChange={(e) => setOtherProducer({ value: 'other', name: e.target.value })}
                          />
                        </div>
                      </div>
                    }
                    <div className="row mb-4">
                      <div className="col-12 col-md-12">
                        <label className="w-100">Type*</label>
                        <div class="d-flex align-items-center gap-1">
                          <Controller
                            {...register("category_type_id", { required: true })}
                            control={control}
                            render={({ field: { onChange, value, ref, name } }) => (
                              <Select
                                isClearable
                                styles={customSelectStyles}
                                value={typeValue}
                                placeholder=""
                                className={errors?.category_type_id ? "invalid-input" : ""}
                                options={
                                  selectedCategory?.types?.length > 0 ?
                                    selectedCategory?.types?.map((type, index) => {
                                      return { value: type.id, label: type.name };
                                    })
                                    : []
                                }
                                onChange={(newValue) => {
                                  if (newValue?.value === 'other') {
                                    setSelectedType({ id: 'other', name: 'Other' })
                                    setTypeValue(
                                      {
                                        value: newValue?.value,
                                        label: newValue?.label,
                                      }
                                    );
                                  } else {
                                    setSelectedType(
                                      selectedCategory?.types?.find(
                                        (type) => type.id === newValue?.value
                                      )
                                    );
                                    setTypeValue(
                                      selectedCategory?.types?.find((type) => {
                                        type.id === newValue?.value,
                                          type.name === newValue?.label;
                                      })
                                    );
                                    onChange(newValue?.value);
                                  }
                                }}
                              />
                            )}
                          />
                          {errors?.category_type_id && (
                            <Tooltip title="Type is required" arrow>
                              <ErrorIcon color="error" />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>

                    {(selectedCategory?.id == 2 || (selectedCategory?.id == 1 && selectedType?.id == 81)) &&
                      <div className="row mb-4">
                        <div className="col-12 col-md-12">
                          <label className="w-100">Varietal / Sub-Type{selectedCategory?.id == 2 ? '*' : ''}</label>
                          <div class="d-flex align-items-center gap-1">
                            <Controller
                              {...register("category_sub_type_id", { required: selectedCategory?.id == 2 ? true : false })}
                              control={control}
                              render={({ field: { onChange, value, ref, name } }) => (
                                <CreatableSelect
                                  isClearable
                                  placeholder=""
                                  className={errors?.category_sub_type_id ? "invalid-input" : ""}
                                  styles={customSelectStyles}
                                  value={selectedVareitale}
                                  onCreateOption={async (newValue) => {
                                    const newSubType = await handleCreateDropdownData('subType', newValue);
                                    setSelectedVareitale({ label: newValue, value: newSubType });
                                    onChange(newSubType);
                                  }}
                                  options={subTypes?.sort((a, b) => {
                                    if (a.label === "Other") {
                                      return 1;
                                    } else if (b.label === "Other") {
                                      return -1;
                                    } else {
                                      return a.label.localeCompare(b.label);
                                    }
                                  })}
                                  onChange={(newValue) => {
                                    setSelectedVareitale(newValue);
                                    onChange(newValue.value);
                                  }}
                                />
                              )}
                            />
                            {errors?.category_sub_type_id && (
                              <Tooltip title="Varietal / Sub-Type is required" arrow>
                                <ErrorIcon color="error" />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                    }
                    {selectedVareitale?.value == 'other' &&
                      <div className="row">
                        <div className="col-12 col-md-12 mb-4">
                          <label className="w-100">Please Enter Your Option Of Vareital</label>
                          <input
                            type="text"
                            className="form-control  rounded-pill"
                            placeholder="Spicify your Vareital"
                            onChange={(e) => setSelectedVareitale({ value: 'other', label: 'Other', name: e.target.value })}
                          />
                        </div>
                      </div>
                    }

                    {selectedCategory?.id && selectedCategory?.id != 4 && (
                      <div className="row mb-4">
                        <div className="col-12 col-md-12">
                          <label className="w-100">
                            Placement Type{selectedCategory?.id == '3' ? '*' : ''}
                          </label>
                          <div class="d-flex align-items-center gap-1">
                            <Controller
                              name="pour_id"
                              control={control}
                              {...register("pour_id", { required: selectedCategory?.id == '3' })}
                              render={({
                                field: { onChange, value, ref, name },
                              }) => (
                                <Select
                                  isClearable
                                  className={errors.pour_id ? "invalid-input" : ""}
                                  placeholder=""
                                  styles={customSelectStyles}
                                  value={selectedPour}
                                  options={
                                    pours?.length > 0 &&
                                    pours?.filter((pour) => {
                                      return pour.category_id == selectedCategory?.id;
                                    })?.map((pour) => ({
                                      value: pour.id,
                                      label: pour.name,
                                    })) || []
                                  }
                                  onChange={(pour) => {
                                    onChange(pour?.value);
                                    setSelectedPour(pour);
                                  }}
                                />
                              )}
                            />
                            {
                              errors?.pour_id && (
                                <Tooltip title="Placement Type is required" arrow>
                                  <ErrorIcon color="error" />
                                </Tooltip>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-12 col-md-12 mb-4">
                        <label className="w-100">ABV% *</label>
                        <div class="d-flex align-items-center gap-1">
                          <input
                            {...register("abv", { required: true })}
                            type="number"
                            className={"form-control  rounded-pill " + (errors?.abv ? "invalid-input" : "")}
                          />
                          {errors?.abv && (
                            <Tooltip title="ABV is required" arrow>
                              <ErrorIcon color="error" />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-12 col-md-12">
                        <label className="w-100">Prominent Flavors</label>
                        <Controller
                          name="prominent_flavors_id"
                          control={control}
                          render={({
                            field: { onChange, value, ref, name },
                          }) => (
                            <CreatableSelect
                              isClearable
                              isMulti
                              value={otherProminent_flavors}
                              styles={customSelectStyles}
                              onCreateOption={async (newValue) => {
                                const newFlavor = await handleCreateDropdownData(
                                  "prominentFlavors",
                                  newValue
                                );
                                const newFlavors = [...otherProminent_flavors, { label: newValue, value: newFlavor }];
                                setOtherprominent_flavors(newFlavors);
                                const selectedIds = newFlavors
                                  .map((item) => item.value)
                                  .join(",");
                                onChange(selectedIds);
                              }}
                              placeholder="Select flavors, up to 3"
                              options={
                                prominent_flavors?.length > 0 &&
                                prominent_flavors.map((data, index) => {
                                  if (index === prominent_flavors.length - 1) {
                                    return [
                                      { value: data.id, label: data.name },
                                      { value: 'other', label: 'Other' },
                                    ];
                                  } else {
                                    return { value: data.id, label: data.name };
                                  }
                                }).flat()?.sort((a, b) => {
                                  if (a.label === "Other") {
                                    return 1;
                                  } else if (b.label === "Other") {
                                    return -1;
                                  } else {
                                    return a.label.localeCompare(b.label);
                                  }
                                })
                              }
                              onChange={(val) => {
                                if (val.length <= 3) {
                                  const selectedIds = val
                                    .map((item) => item.value)
                                    .join(",");
                                  setOtherprominent_flavors(val)
                                  onChange(selectedIds);
                                }
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    {otherFlavors &&
                      <div className="row">
                        <div className="col-12 col-md-12 mb-4">
                          <label className="w-100">Please Enter Your Option Of prominentFlavors</label>
                          <input
                            type="text"
                            className="form-control  rounded-pill"
                            placeholder="Spicify your prominentFlavors"
                            onChange={(e) => setAddFlavors(e.target.value)}
                          />
                        </div>
                      </div>
                    }

                    <div className="row mb-4">
                      <div className="col-12 col-md-12">
                        <label className="w-100">Country of Origin*</label>
                        <div class="d-flex align-items-center gap-1">
                          <Controller
                            {...register("country_of_origin_id", {
                              required: true,
                            })}
                            control={control}
                            render={({
                              field: { onChange, value, ref, name },
                            }) => (
                              <CreatableSelect
                                isClearable
                                styles={customSelectStyles}
                                value={otherCountry}
                                placeholder=""
                                className={errors?.country_of_origin_id ? "invalid-input" : ""}
                                onCreateOption={async (newValue) => {
                                  const newCountry = await handleCreateDropdownData(
                                    "countyOfOrigin",
                                    newValue
                                  );
                                  setOtherCountry({ label: newValue, value: newCountry });
                                  onChange(newCountry);
                                }}
                                options={
                                  countries?.length > 0 &&
                                  countries.map((data, index) => {
                                    if (index === countries.length - 1) {
                                      return [
                                        { value: data.id, label: data.name },
                                        { value: 'other', label: 'Other' },
                                      ];
                                    } else {
                                      return { value: data.id, label: data.name };
                                    }
                                  }).flat()?.sort((a, b) => {
                                    if (a.label === "Other") {
                                      return 1;
                                    } else if (b.label === "Other") {
                                      return -1;
                                    } else {
                                      return a.label.localeCompare(b.label);
                                    }
                                  })
                                }
                                onChange={(val) => {
                                  setOtherCountry(val)
                                  onChange(val.value)
                                }}
                              />
                            )}
                          />
                          {errors?.country_of_origin_id && (
                            <Tooltip title="Country of Origin is required" arrow>
                              <ErrorIcon color="error" />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>

                    {otherCountry?.value == 'other' &&
                      <div className="row">
                        <div className="col-12 col-md-12 mb-4">
                          <label className="w-100">Please Enter Your Option Of Country*</label>
                          <input
                            type="text"
                            className="form-control  rounded-pill"
                            placeholder="Spicify your type"
                            onChange={(e) => setOtherCountry({ value: 'other', name: e.target.value })}
                            required={true}
                          />
                        </div>
                      </div>
                    }

                    <div className="row mb-4">
                      <div className="col-12 col-md-12">
                        <label className="w-100">Region / Appellation{selectedCategory?.id == 2 ? '*' : ''}</label>
                        <div class="d-flex align-items-center gap-1">
                          <Controller
                            {...register("region_id", {
                              required: selectedCategory?.id == 2 ? true : false,
                            })}
                            control={control}
                            render={({
                              field: { onChange, value, ref, name },
                            }) => (
                              <CreatableSelect
                                isClearable
                                placeholder=""
                                className={errors?.region_id ? "invalid-input" : ""}
                                styles={customSelectStyles}
                                value={otherRegion}
                                onCreateOption={async (newValue) => {
                                  const newRegion = await handleCreateDropdownData("regions", newValue);
                                  setOtherRegion({ label: newValue, value: newRegion });
                                  onChange(newRegion);
                                }}
                                options={
                                  regions?.length > 0 &&
                                  regions.map((data, index) => {
                                    if (index === regions.length - 1) {
                                      return [
                                        { value: data.id, label: data.name },
                                        { value: 'other', label: 'Other' },
                                      ];
                                    } else {
                                      return { value: data.id, label: data.name };
                                    }
                                  }).flat()?.sort((a, b) => {
                                    if (a.label === "Other") {
                                      return 1;
                                    } else if (b.label === "Other") {
                                      return -1;
                                    } else {
                                      return a.label.localeCompare(b.label);
                                    }
                                  })
                                }
                                onChange={(val) => {
                                  setOtherRegion(val)
                                  onChange(val.value)
                                }}
                              />
                            )}
                          />
                          {errors?.region_id && (
                            <Tooltip title="Region / Appellation is required" arrow>
                              <ErrorIcon color="error" />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                    {otherRegion?.value == 'other' &&
                      <div className="row">
                        <div className="col-12 col-md-12 mb-4">
                          <label className="w-100">Please Enter Your Option Of Region / Appellation*</label>
                          <input
                            type="text"
                            className="form-control  rounded-pill"
                            placeholder="Spicify your type"
                            onChange={(e) => setOtherRegion({ value: 'other', name: e.target.value })}
                          />
                        </div>
                      </div>
                    }

                    {selectedCategory?.id && (
                      <>
                        <div className="row mb-4">
                          <div className="col-12 col-md-12">
                            <label className="w-100">Unit Size*</label>
                            <div class="d-flex align-items-center gap-1">
                              <Controller
                                {...register("main_size", { required: true })}
                                control={control}
                                render={({
                                  field: { onChange, value, ref, name },
                                }) => (
                                  <Select
                                    isClearable
                                    className={errors?.main_size ? "invalid-input" : ""}
                                    styles={customSelectStyles}
                                    value={mainSize}
                                    options={
                                      sizes?.length > 0 &&
                                      sizes?.filter((size) => size.category_id == selectedCategory?.id).map((data, index) => {
                                        if (index === sizes.length - 1) {
                                          return [
                                            { value: data.id, label: data.name },
                                          ];
                                        } else {
                                          return { value: data.id, label: data.name };
                                        }
                                      }).flat()
                                    }
                                    onChange={(val) => {
                                      setMainSize(val);
                                      onChange(val?.value);
                                    }}
                                  />
                                )}
                              />
                              {errors?.main_size && (
                                <Tooltip title="Unit Size is required" arrow>
                                  <ErrorIcon color="error" />
                                </Tooltip>
                              )}
                            </div>

                          </div>
                        </div>
                      </>
                    )}


                  </div>
                  <div className="col-12 col-md-6">
                    {(selectedCategory?.id == 1 || selectedCategory?.id == 2) && (
                      <div className="row mb-4">
                        <div className="col-12 col-md-12">
                          <label className="w-100">Item Closure{selectedCategory?.id == 2 ? '*' : ''}</label>
                          <div class="d-flex align-items-center gap-1">
                            <Controller
                              {...register("item_closure_id", { required: selectedCategory?.id == 2 ? true : false })}
                              placeholder=""
                              control={control}
                              className={errors?.item_closure_id ? "invalid-input" : ""}
                              render={({
                                field: { onChange, value, ref, name },
                              }) => (
                                <CreatableSelect
                                  styles={customSelectStyles}
                                  isClearable
                                  onCreateOption={(newValue) => {
                                    handleCreateDropdownData(
                                      "itemClosures",
                                      newValue
                                    );
                                  }}
                                  value={itemClosure}
                                  options={
                                    itemClosures &&
                                    itemClosures.map((data) => ({
                                      value: data.id,
                                      label: data.name,
                                    }))?.sort((a, b) => {
                                      if (a.label === "Other") {
                                        return 1;
                                      } else if (b.label === "Other") {
                                        return -1;
                                      } else {
                                        return a.label.localeCompare(b.label);
                                      }
                                    })
                                  }
                                  onChange={(val) => {
                                    setItemClosure(val);
                                    onChange(val.value);
                                  }}
                                />
                              )}
                            />
                            {errors?.item_closure_id && (
                              <Tooltip title="Item Closure is required" arrow>
                                <ErrorIcon color="error" />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row mb-4">
                      <div className="col-12 col-md-12">
                        <label className="w-100">
                          Enter National Average Unit Price, or State by State Pricing Below
                        </label>
                        <input
                          readOnly={product?.rfp_id ? true : false}
                          type="number"
                          step="any"
                          {...register("national_pricing")}
                          onKeyDown={(e) => {
                            if (e.which === 38 || e.which === 40) {
                              e.preventDefault();
                            }
                          }}
                          name="national_pricing"
                          className="form-control mb-3  rounded-pill"
                          placeholder="Enter case 1 pricing, unless otherwise instructed"
                        />
                        {product?.rfp_id ? (<a
                          className="text-dark cursor-pointer text-muted"
                        >
                          <i className="la la-plus"></i>Add price by State
                        </a>) : (<a
                          className="text-dark cursor-pointer"
                          data-toggle="modal"
                          data-target="#pricingByStateModal"
                        >
                          <i className="la la-plus"></i>Add price by State
                        </a>)}
                      </div>
                      {selectedStates.length > 0 && (
                        <div
                          className="card rounded-xl p-3 w-100  custom-scroll"
                          style={{ maxHeight: "250px", overflowY: "auto" }}
                        >
                          {selectedStates.map((state) => (
                            <div className="py-5 border-bottom border-white">
                              <strong>{state.state.name}</strong>
                              <div>
                                {state.prices.map((price) => (
                                  <div className="d-flex flex-column align-items-start border-bottom border-white py-3">
                                    <span className="text-muted">
                                      Distributor:{" "}
                                      <strong>{price.distributor}</strong>
                                    </span>
                                    <span className="text-muted">
                                      Price: <strong>{price.price}</strong>
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-md-12">
                        <label className="w-100">Units per Case*</label>
                        <div class="d-flex align-items-center gap-1">
                          <input
                            {...register("units_per_case", { required: true })}
                            onKeyDown={(e) => {
                              if (e.which === 38 || e.which === 40) {
                                e.preventDefault();
                              }
                            }}
                            type="text"
                            className={"form-control mb-3 rounded-pill " + (errors?.units_per_case ? "invalid-input" : "")}
                          />
                          {errors?.units_per_case && (
                            <Tooltip title="Units per Case is required" arrow>
                              <ErrorIcon color="error" />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>

                    {selectedCategory?.id && (<>
                      <div className="row mb-4">
                        <div className="col-12 col-md-12">
                          <label className="w-100">Other Available Sizes</label>
                          <Controller
                            name="sizes"
                            control={control}
                            render={({
                              field: { onChange, value, ref, name },
                            }) => (
                              <Select
                                isClearable
                                placeholder="Select Available Sizes"
                                isMulti
                                styles={customSelectStyles}
                                value={other_Sise}
                                options={
                                  sizes?.length > 0 &&
                                  sizes?.filter((size) => size.category_id == selectedCategory?.id).map((data, index) => {
                                    if (index === sizes.length - 1) {
                                      return [
                                        { value: data.id, label: data.name },
                                      ];
                                    } else {
                                      return { value: data.id, label: data.name };
                                    }
                                  }).flat()
                                }
                                onChange={(val) => {
                                  const selectedIds = val
                                    .map((item) => item.value)
                                    .join(",");
                                  setOther_Sise(val)
                                  onChange(selectedIds);
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </>)}
                    <div className="row mb-4">
                      <div className="col-12">
                        <label className="w-100">Product Sell Sheet</label>
                        {files.sell_sheets ? (
                          <div className="d-flex align-items-center">
                            <Typography variant="body1" class="text-truncate">
                              {files?.sell_sheets.substring(
                                files?.sell_sheets.lastIndexOf("/") + 1
                              )}
                            </Typography>
                            <IconButton
                              onClick={() => removeFile("sell_sheets")}
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                        ) : (
                          <label
                            className="btn btn-pill btn-outline-custom-primary"
                            htmlFor="sellSheets"
                          >
                            <input
                              type="file"
                              name="sell_sheets"
                              className="d-none"
                              id="sellSheets"
                              onChange={handleFileChange}
                            />
                            <i className="la la-upload"></i>
                            Browse
                          </label>
                        )}
                      </div>
                      <div class="row col-12">
                        <div className="col-6 col-lg-6">
                          <label className="w-100">Product Image (Front)</label>
                          {files.product_image_front ? (
                            <div className="d-flex align-items-center">
                              <Typography variant="body1" class="text-truncate">
                                {files?.product_image_front.substring(
                                  files?.product_image_front.lastIndexOf("/") + 1
                                )}
                              </Typography>
                              <IconButton
                                onClick={() => removeFile("product_image_front")}
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                          ) : (
                            <label
                              className="btn btn-pill btn-outline-custom-primary"
                              htmlFor="product_image_front"
                            >
                              <input
                                type="file"
                                name="product_image_front"
                                id="product_image_front"
                                className="d-none"
                                accept="image/png, image/gif, image/jpeg, image/webp"
                                onChange={handleFileChange}
                              />
                              <i className="la la-upload"></i> Browse
                            </label>
                          )}
                        </div>
                        <div className="col-6 col-lg-6">
                          <label className="w-100">Product Image (Back)</label>
                          {files.product_image_back ? (
                            <div className="d-flex align-items-center">
                              <Typography variant="body1" class="text-truncate">
                                {files?.product_image_back.substring(
                                  files?.product_image_back.lastIndexOf("/") + 1
                                )}
                              </Typography>
                              <IconButton
                                onClick={() => removeFile("product_image_back")}
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                          ) : (
                            <label
                              className="btn btn-pill btn-outline-custom-primary"
                              htmlFor="product_image_back"
                            >
                              <input
                                type="file"
                                name="product_image_back"
                                id="product_image_back"
                                className="d-none"
                                accept="image/png, image/gif, image/jpeg, image/webp"
                                onChange={handleFileChange}
                              />
                              <i className="la la-upload"></i> Browse
                            </label>
                          )}
                        </div>
                      </div>
                      <div class="row col-12">
                        <div className="col-6 col-lg-6">
                          <label className="w-100">Label Image (Front)</label>
                          {files?.label_image_front ? (
                            <div className="d-flex align-items-center">
                              <Typography variant="body1" class="text-truncate">
                                {files?.label_image_front.substring(
                                  files?.label_image_front.lastIndexOf("/") + 1
                                )}
                              </Typography>
                              <IconButton
                                onClick={() => removeFile("label_image_front")}
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                          ) : (
                            <label
                              className="btn btn-pill btn-outline-custom-primary"
                              htmlFor="label_image_front"
                            >
                              <input
                                type="file"
                                name="label_image_front"
                                id="label_image_front"
                                className="d-none"
                                accept="image/png, image/gif, image/jpeg, image/webp"
                                onChange={handleFileChange}
                              />
                              <i className="la la-upload"></i> Browse
                            </label>
                          )}
                        </div>
                        <div className="col-6 col-lg-6">
                          <label className="w-100">Label Image (Back)</label>
                          {files.label_image_back ? (
                            <div className="d-flex align-items-center">
                              <Typography variant="body1" class="text-truncate">
                                {files?.label_image_back.substring(
                                  files?.label_image_back.lastIndexOf("/") + 1
                                )}
                              </Typography>
                              <IconButton
                                onClick={() => removeFile("label_image_back")}
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                          ) : (
                            <label
                              className="btn btn-pill btn-outline-custom-primary"
                              htmlFor="label_image_back"
                            >
                              <input
                                type="file"
                                name="label_image_back"
                                id="label_image_back"
                                className="d-none"
                                accept="image/png, image/gif, image/jpeg, image/webp"
                                onChange={handleFileChange}
                              />
                              <i className="la la-upload"></i> Browse
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-md-12">
                        <div className="row col-12">
                          <label className="w-100">Recipes</label>
                          <label
                            className="btn btn-outline-custom-primary btn-pill co-12"
                            htmlFor="recipesFile"
                          >
                            <input
                              type="file"
                              multiple
                              className="d-none"
                              id="recipesFile"
                              name="recipes"
                              onChange={handleRecipesFileChnage}
                            />
                            <i className="la la-upload"></i> Browse
                          </label>
                          <div class="col-12 d-flex align-items-center gap-1 flex-wrap">
                            {recipes?.map((recipe, index) => {
                              return (<label
                                onClick={() => {
                                  const updatedRecipes = [...recipes];
                                  updatedRecipes.splice(index, 1);
                                  setRecipes(updatedRecipes);
                                }}
                                className="col-6 col-lg-3 d-flex align-items-center gap-1"
                              >
                                <span class="text-truncate">
                                  {recipe?.substring(
                                    recipe.lastIndexOf("/") + 1
                                  )}
                                </span>
                                <i className="fa fa-times"></i>
                              </label>)
                            })}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">

                            <label className="w-100">Logos</label>
                            {files.logos ? (
                              <div className="d-flex align-items-center">
                                <Typography variant="body1" class="text-truncate">
                                  {files?.logos.substring(
                                    files?.logos.lastIndexOf("/") + 1
                                  )}
                                </Typography>
                                <IconButton onClick={() => removeFile("logos")}>
                                  <CloseIcon />
                                </IconButton>
                              </div>
                            ) : (
                              <label className="btn btn-pill btn-outline-custom-primary">
                                <input
                                  type="file"
                                  className="d-none"
                                  name="logos"
                                  id="logos"
                                  accept="image/png, image/gif, image/jpeg"
                                  onChange={handleFileChange}
                                />
                                <i className="la la-upload"></i> Browse
                              </label>
                            )}
                          </div>


                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-md-12">
                        <label className="w-100">
                          Video Links
                          <input
                            {...register("videos")}
                            type="text"
                            className="form-control rounded-pill"
                            placeholder="Enter video links, comma separated"
                            name="videos"
                            id="videos"
                          />
                        </label>
                        {errors?.videos && (
                          <p className="text-danger">{errors?.videos?.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-md-12">
                        <label className="w-100">Awards / Accolades</label>
                        <textarea
                          {...register("awards")}
                          className="form-control  rounded-xl"
                          style={{ resize: "none" }}
                          name="awards"
                          placeholder="Enter awards / accolades, comma separated"
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-md-12">
                        <label className="w-100">Notes, if any</label>
                        <textarea
                          {...register("notes")}
                          defaultValue={product?.notes}
                          className="form-control  rounded-xl"
                          style={{ resize: "none" }}
                          name="notes"
                          placeholder="Notes"
                        />
                      </div>
                    </div>

                  </div>
                </div>
                <PricingByStateModal
                  selectedStates={selectedStates}
                  setSelectedStates={setSelectedStates}
                  setStatePricing={setStatePricing}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12">
                <button className="btn btn-pill  btn-outline-custom-primary mr-3" onClick={() => setStatus('draft')}>
                  Save Draft
                </button>
                <button className="btn btn-pill btn-dark" type="submit" onClick={() => setStatus('active')}>
                Save Product
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="uploadProductsModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="uploadProductsModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered" role="document">
            <div className="modal-content px-5">
              <div className="modal-header">
                <h5
                  className="modal-title fw-bold fs-1"
                  id="uploadProductsModalLabel"
                >
                  Follow these steps to easily upload your data
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times fa-xl"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="d-flex flex-column mb-10 align-items-start">
                  <strong className="text-custom-primary mb-4">Step 1</strong>
                  <span className="text-muted fs-4">Download our template</span>
                  <a
                    className="btn btn-outline-custom-primary btn-pill"
                    href="/assets/add_products_template.csv"
                    download
                  >
                    Download template
                    <i className="la la-download"></i>
                  </a>
                </div>
                <div className="d-flex flex-column mb-10">
                  <strong>Step 2</strong>
                  <span className="text-muted fs-4">
                    Paste your data into the template
                  </span>
                </div>
                <div className="d-flex flex-column align-items-start">
                  <strong>Step 3</strong>
                  <span className="text-muted fs-4">
                    Upload the completed template here
                  </span>
                  <div>
                    {productsFile ? (
                      <div className="d-flex align-items-center gap-1">
                        <span className="text-primary-blue file-name">
                          {productsFile}
                        </span>
                        <i
                          className="fa fa-times fa-lg cursor-pointer"
                          onClick={() => {
                            setProductsFile(null);
                          }}
                        ></i>
                      </div>
                    ) : (
                      id === undefined && (
                        <label className="btn btn-outline-custom-primary btn-pill">
                          <input
                            type="file"
                            className="d-none rfp-csv-file"
                            onChange={handleProductsFileChange}
                          />
                          Upload template
                          <i className="la la-upload"></i>
                        </label>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  disabled={!productsFile}
                  type="button"
                  onClick={handlePreviewCsvProducts}
                  className="btn btn-pill btn-dark font-weight-bold w-100"
                  data-dismiss="modal"
                  data-target="#uploadProductsModal"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
});

export default Update;
