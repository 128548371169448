import { Link } from 'react-router-dom';

export default function SupplierUnlockPremium() {
    return (
        <div className="position-fixed bg-blur d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex flex-column align-items-center justify-content-center" style={{ maxWidth: '45rem' }}>
                {/* <h1 className="text-center">Answer RFPs in seconds with this powerful upgrade</h1>
                <p className="text-center">
                    upgrade to Premium and gain access to BevBridge’s powerful Auto-fill features and the ability to manage and share products with your team. BevBridge RFP Supplier Premium saves you and your team members from the hassles of answering RFPs. Unlock Premium features to see for yourself.
                </p>
                <Link to={"/users/upgrade-premium"} className="btn btn-custom-primary rounded-pill">Unlock Premium</Link> */}
            </div>
        </div>

    );
}