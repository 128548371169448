import { useParams } from "react-router-dom";
import RFPOverviewDetails from "./includes/RFPOverviewDetails";
import AskAQuestion from "./includes/AskAQuestion";
import Categories from "./includes/Categories";
import formatDate from "src/componenets/DateFormat";
import ProductsFilterRFPs from "./includes/ProductsFilterRFPs";
import {
  fetchItem,
  selectCurrentItem,
} from "src/plugins/redux/reducers/RFPsReducer";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  setHeaderTitle,
  setHeaderActionBtns,
} from "src/plugins/redux/reducers/HeaderReducer";
import ContactFormModal from "pages/Contacts/includes/ContactFormModal";
import AddProductModal from "pages/Products/includes/AddProductModal";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { currentUserSelector } from "plugins/redux/reducers/AuthReducer";
import { Box, Button, Modal, Typography } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import SupplierUploads from "./includes/SupplierUploads";

export default function () {
  const dispatch = useDispatch();

  const { id } = useParams();

  const currentUser = useSelector(currentUserSelector);

  const [filters, setFilters] = useState();
  const [sortBy, setSortBy] = useState();
  const [sortType, setSortType] = useState("ASC");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const rfp = useSelector(selectCurrentItem);
  console.log(new Date(rfp?.rfp_end_date));
  console.log(new Date(Date.now()));
  
  const [success, setSuccess] = useState(false);
  const [selectedRequest, setSelectedRquest] = useState<object | null>();
  const [addProductData, setAddProductData] = useState();
  const [formData, setFormData] = useState({});

  const notSuccess = () => setSuccess(!success);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        notSuccess();
      }, 3000);
    }
  }, [success]);
  useEffect(() => {
    if (rfp.rfp_title) {
      dispatch(setHeaderTitle(`${rfp?.rfp_title}`));
    }
  }, [rfp]);
  useEffect(() => {
    dispatch(
      fetchItem({
        id: id,
        filters: { ...filters },
        sortBy: sortBy,
        sortType: { ...sortType },
      })
    );
    if (currentUser.role_id == 2) {
      dispatch(
        setHeaderActionBtns([{ link: `/edit/${id}`, text: "Edit RFP" }])
      );
    }
  }, [filters, sortBy, sortType]);

  const handleTypeSort = (columnName: any) => {
    setSortBy(columnName);
    setSortType((current) => (current === "ASC" ? "DESC" : "ASC"));
  };

  const [selectedNotes, setSelectedNotes] = useState();
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: '1rem'
    },
  }));

  const handleClose = () => setNotesModalOpen(false);

  return (
    <div className="row">
      <div className="col-12 col-md-12">
        {success && (
          <Stack
            style={{
              position: "fixed",
              width: "200px",
              top: "80px",
              right: "0",
              zIndex: "1",
              border: "1px solid #3F4254",
            }}
            sx={{ width: "100%" }}
            spacing={2}
          >
            <Alert severity="success"> Product Added Successfuly </Alert>
          </Stack>
        )}
        <div className="row mb-6">
          <div className="col-12 col-md-12">
            <AskAQuestion rfp={rfp} />
          </div>
        </div>
        {currentUser?.role_id == 3 && <SupplierUploads uploads={rfp?.supplier_uploads} rfp_id={id} />}
        <div className="row mb-6">
          <div className="col-12 col-md-9 mb-6">
            <div className="row mb-6">
              <div className="col-12 col-md-12 mb-3">
                <h2 className="fw-bold mb-5">Product Request Listings</h2>
              </div>
              <div className="col-12 col-md-12">
                <Categories setFilters={setFilters} RFPs={rfp} />
              </div>
            </div>
            <ProductsFilterRFPs
              filters={filters}
              type={filters?.category_id}
              subType={filters?.type_id}
              setFilters={setFilters}
              setSortBy={setSortBy}
            />
            <div className="card mb-4 bg-custom-secondary rounded-pill mt-5">
              <div className="row p-5 align-items-center">
                <div
                  className="col-3 col-md-2 text-center text-white"
                  onClick={() => handleTypeSort("category_id")}
                  style={{ cursor: "pointer" }}
                >
                  Category
                </div>
                <div
                  className="col-3 col-md-2 text-center text-white"
                  onClick={() => handleTypeSort("category_type_name")}
                  style={{ cursor: "pointer" }}
                >
                  Type
                </div>
                <div
                  className="col-3 col-md-2 text-center text-white"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleTypeSort("sub_type_name")}
                >
                  Varietal / Sub-Type
                </div>
                <div className="col-3 col-md-1 text-center text-white" style={{ "word-wrap": "normal" }}>
                  Placement
                </div>
                <div className="col-3 col-md-2 text-center text-white">
                  Unit Price
                </div>
                <div className="col-3 col-md-1 text-center text-white" style={{ "word-wrap": "normal" }}>
                  Notes
                </div>
                <div className="col-3 col-md-2 text-center text-white">
                  Action
                </div>
              </div>
            </div>
            {/* row data  */}
            {rfp?.rfpsRequests &&
              rfp.rfpsRequests?.map((request, index) => {
                return (
                  <div
                    className="card border-secondary mb-4 rounded-xl"
                    key={"product-request-" + index}
                  >
                    <div className="row p-5 align-items-center">
                      <div className="col-3 col-md-2 text-center">
                        {request.category_name ?? '-'}
                      </div>
                      <div className="col-3 col-md-2 text-center">
                        {request.category_type_name ?? '-'}
                      </div>
                      {request.sub_type_name ?
                        <div className="col-2 col-md-2 text-center">
                          {request.sub_type_name}
                        </div>
                        :
                        <div className="col-2 col-md-2 text-center">
                          -
                        </div>
                      }

                      {request.category_name != "Non Alc" ? (
                        <div className="col-2 col-md-1 text-center">
                          {request?.pours_name}
                        </div>
                      ) : (
                        <div className="col-2 col-md-1 text-center" >
                          -
                        </div>
                      )}

                      {request.min_price && request.max_price ? (
                        <div className="col-2 col-md-2 text-center text-truncate">
                          <BootstrapTooltip title={`$${request.min_price}-$${request.max_price}`}>
                            <span style={{ fontSize: '1rem' }}>{`$${request.min_price}-$${request.max_price}`}</span>
                          </BootstrapTooltip>
                        </div>
                      ) : (
                        <div className="col-2 col-md-2 text-center" >
                          -
                        </div>
                      )}
                      {request.notes ? (
                        <div className="col-3 col-md-1 text-center">
                          <Button
                            size="small"
                            variant="outlined"
                            sx={{
                              borderRadius: "50px",
                              fontSize: "10px",
                              color: "gray",
                              borderColor: "gray",
                              "&:hover": {
                                borderColor: "black",
                                backgroundColor: "black",
                                color: "white",
                              },
                            }}
                            onClick={() => {
                              setSelectedNotes(request.notes);
                              setNotesModalOpen(true);
                            }}
                          >
                            View Note
                          </Button>
                        </div>
                      ) : (
                        <div className="col-3 col-md-1 text-center" >
                          -
                        </div>
                      )}
                      <div className="col-3  col-md-2 text-center">
                        {
                          rfp &&
                        <Button
                          type="button"
                          variant="outlined"
                          sx={{
                            borderRadius: "50px",
                            fontSize: "10px",
                            color: "gray",
                            borderColor: "gray",
                            "&:hover": {
                              borderColor: "black",
                              backgroundColor: "black",
                              color: "white",
                            },
                          }}
                          disabled={new Date(rfp?.rfp_end_date) < new Date(Date.now())}
                          onClick={() => {
                            setSelectedRquest(request);
                            toggle();
                          }}
                        >
                          Submit Product
                        </Button>
                        }
                      </div>
                    </div>
                  </div>
                );
              })}

          </div>
          <div className="col-12 col-md-3">
            <RFPOverviewDetails rfp={rfp} />
          </div>
        </div>
        <Modal
          open={notesModalOpen}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-title" variant="h6" component="h2">
              Notes
            </Typography>

            <Typography id="modal-description" sx={{ mt: 2 }}>
              {selectedNotes}
            </Typography>
            <Button onClick={handleClose} variant="outlined"
              sx={{
                mt: 3,
                borderRadius: "50px",
                fontSize: "10px",
                color: "gray",
                borderColor: "gray",
                "&:hover": {
                  borderColor: "black",
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
              Close
            </Button>
          </Box>
        </Modal>

        <AddProductModal
          modal={modal}
          toggle={toggle}
          success={success}
          notSuccess={notSuccess}
          selectedRequest={selectedRequest}
          setSelectedRquest={setSelectedRquest}
          addProductData={addProductData}
          setAddProductData={setAddProductData}
          formData={formData}
          setFromData={setFormData}
        />
        <ContactFormModal />
      </div>
    </div>
  );
}
