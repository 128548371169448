import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { me } from "plugins/redux/reducers/AuthReducer";

const PremiumModalComponent = ({ anchorEl, setAnchorEl }) => {
    const navigate = useNavigate();
    const currentUrl = location.pathname;
    const currentUser = useSelector(me);
    const [showMyProfile, setshowMyProfile] = useState<boolean | null>(currentUrl.includes('profile'));
    const open = Boolean(anchorEl);
    const id = open ? 'premium-popper' : undefined;
    const popperRef = useRef(null);

    const handleClickOutside = useCallback((event) => {
        if (
            popperRef.current &&
            !popperRef.current.contains(event.target) &&
            !event.target.closest('button')
        ) {
            setAnchorEl(null);
        }
    }, [setAnchorEl, popperRef]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [handleClickOutside]);

    return (
        <div ref={popperRef}>
            <Popper id={id} open={open} anchorEl={anchorEl} placement="left-start" sx={{ boxShadow: '0 6px 20px 0 rgba(124, 124, 124, 0.15)' }}>
                <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                    <svg width="37" height="38" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.667 24.4993H20.3337V28.166H16.667V24.4993ZM16.667 9.83268H20.3337V20.8327H16.667V9.83268ZM18.482 0.666016C8.36199 0.666016 0.166992 8.87935 0.166992 18.9993C0.166992 29.1193 8.36199 37.3327 18.482 37.3327C28.6203 37.3327 36.8337 29.1193 36.8337 18.9993C36.8337 8.87935 28.6203 0.666016 18.482 0.666016ZM18.5003 33.666C10.397 33.666 3.83366 27.1027 3.83366 18.9993C3.83366 10.896 10.397 4.33268 18.5003 4.33268C26.6037 4.33268 33.167 10.896 33.167 18.9993C33.167 27.1027 26.6037 33.666 18.5003 33.666Z" fill="#081324" />
                    </svg>
                    <br />
                    <br />
                    <p>To use these options, you need premium access!</p>
                    <button
                        onClick={() => {
                            navigate(`/users/${currentUser?.id}`);
                            setshowMyProfile(true);
                        }}
                        href={'javascript:void(0);'}
                        style={{
                            background: "#F2663A",
                            color: "#fdfdfd",
                            border: "solid 2px #F2663A",
                            borderRadius: "15px",
                            padding: "5px",
                            marginLeft: "15px"
                        }}
                    > Get Premium Access</button>
                </Box>
            </Popper>
        </div>
    );
}

export default PremiumModalComponent;
