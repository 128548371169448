import React from 'react'
import { useSelector, Provider } from "react-redux";
import { Routes, Route, useNavigate,/*useParams, Navigate*/ } from "react-router-dom";
import { currentUserSelector } from "plugins/redux/reducers/AuthReducer";
import StateContract from "plugins/redux/contracts/StateContract";
import StateContractAuth from "plugins/redux/contracts/StateAuthSessionContract";
import Cookies from 'js-cookie';
import AppLayout from "layouts/AppLayout";
import AuthLayout from "layouts/AuthLayout";
import NotFound from "themes/pages/NotFound";
import { jwtDecode } from "jwt-decode";
import RFPs from "pages/RFPs";
import SubmittedRFPs from "pages/Users/RFPs"
import Products from "pages/Products";
import ProductSubmissions from "pages/RFPs/includes/ProductSubmissions";
import ProductsShortList from "pages/RFPs/includes/ProductsShortList";
import FindRFPs from "pages/RFPs/includes/FindRFPs";
import InviteBeveragePartners from "pages/RFPs/includes/InviteBeveragePartners";

import Users from "pages/Users";
import Login from "pages/Users/Login";
import Registration from "pages/Users/Registration";
import Confirmation from "pages/Users/Confirmation";
import Reset from "pages/Users/Reset";
import routes from "src/routes";
import { useDispatch, useSelector } from "react-redux";
import { EditUser } from "src/plugins/redux/reducers/UsersReducers";
import { updateToken } from "src/plugins/redux/reducers/AuthReducer";

export default function App(): JSX.Element {
  const token = Cookies.get('session');
  const currentUser = useSelector(currentUserSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp) {
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          Cookies.remove('session');
          Cookies.remove('login_as');
          navigate('/login');
        }
      }
    }

  }, [token])
  
  return (
    <Routes>
      {routes.map((route, index) => {
        return (
          <Route path={route.path} element={route.element} key={'layout-' + index}>
            {route.children && route.children.map((child, index) => {
              if (child.allowedRoles) {
                return child.allowedRoles && child.allowedRoles.includes(currentUser?.role_id) ? (
                  <Route path={child.path} element={child.element} key={'route-' + index} />
                ) : (
                  <Route path={child.path} element={<NotFound />} key={'route-' + index} />
                )
              } else {
                return (
                  <Route path={child.path} element={child.element} key={'route-' + index} />
                )
              }
            })}
          </Route>
        );
      })}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );

}


