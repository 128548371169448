import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "plugins/redux/reducers/AuthReducer";
import { useEffect } from "react";
import { currentUserSelector } from "plugins/redux/reducers/AuthReducer";
import { CreateCompanySubscriptions } from "plugins/redux/reducers/SubscriptionReducer";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { Modal, Backdrop, Fade, Box, IconButton } from "@mui/material";
import Cookies from "js-cookie";
import TrialTimer from "src/componenets/TrialTimer";
import trialPopup from "src/pages/Users/TrialPopup";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Divider, IconButton, Typography } from "@mui/material";
import { useRouter } from 'next/router';
import { EditUser } from "src/plugins/redux/reducers/UsersReducers";
import { updateToken, me, getUserData } from "src/plugins/redux/reducers/AuthReducer";
import {
  fetchProducts,
  selectTotalProducts,
  selectProducts,
  selectDraft,
  selectTrash,
  selectProductsStatus,
  deleteProduct,
} from "src/plugins/redux/reducers/ProductsReducer";
export default function Menu() {

  const isRetailer = true;
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUrl = location.pathname;
  const currentUser = useSelector(me);
  const navigate = useNavigate();
  const loggedInAsUser = Cookies.get("login_as");
  const totalProducts = useSelector(selectTotalProducts);
  const totalDraftProducts = useSelector(selectDraft);
  const totalTrashProducts = useSelector(selectTrash);
  const totalActiveProducts = useSelector(selectProducts);

  const [Logo, setLogo] = useState<string | null>();
  const [showMyProducts, setShowMyProducts] = useState<boolean | null>(currentUrl.includes('products'));
  const [showMySubmissions, setShowMySubmissions] = useState<boolean | null>(currentUrl.includes('submissions'));
  const [showMyProfile, setshowMyProfile] = useState<boolean | null>(currentUrl.includes('users'));
  const [showSuccessModal, setShowSuccessModal] = useState<boolean | null>(false);
  const [showTrialModal, setShowUpdagradeModal] = useState<boolean | null>(null);
  const [activeMainTab, setActiveMainTab] = useState(null);
  const [activeSubTab, setActiveSubStab] = useState(null);

  const userData = useSelector(me);
  useEffect(() => {
    dispatch(getUserData());
  }, [])
  useEffect(() => {
    dispatch(fetchProducts());

    if (currentUser?.get_trial) {
      setShowUpdagradeModal(true);
    } else {
      setShowUpdagradeModal(false);
    }
    
    console.log("🚀 ~ Menu ~ currentUser:", currentUser)
  }, [currentUser]);
  const handleStartFreeTrial = async () => {
    const res = await dispatch(CreateCompanySubscriptions());

    // Cookies.set('session', res.payload.token)
    setShowUpdagradeModal(false);
    setShowSuccessModal(true);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  // useEffect(() => {

  //   if (currentUser) {
  //     const handleEditUser = async (data) => {
  //       const body = { ...data, verified: true }
  //       const response = await dispatch(EditUser(body));
  //       const x = await dispatch(updateToken(response.payload.token));
  //       window.location.href = `${window.location.origin}/rfps/find`
  //     };


  //     const urlParams = new URLSearchParams(window.location.search);
  //     if (urlParams.get("success") == "true") {
  //       handleEditUser(currentUser);
  //     }
  //   }
  // }, [currentUser]);

  useEffect(() => {
    $("#kt_aside_menu li a").each(function () {
      const href = $(this).attr("href");
      console.log("🚀 ~ href:", href)
      console.log("🚀 ~ currentUrl:", currentUrl)

      // if (href && currentUrl.startsWith(href)) {
      //   $(this).addClass("active");
      //   if ($(this).hasClass('sub-menu-link')) {
      //     $(this).find('svg').html('<path fill-rule="evenodd" clip-rule="evenodd" d="M8.63085 0.570951C8.85478 0.421662 9.14652 0.421662 9.37045 0.570951L16.8704 5.57095C17.0559 5.69459 17.1673 5.90275 17.1673 6.12565V14.2507C17.1673 14.5932 17.0312 14.9218 16.789 15.164C16.5468 15.4062 16.2182 15.5423 15.8757 15.5423H2.12565C1.78308 15.5423 1.45454 15.4062 1.2123 15.164C0.97007 14.9218 0.833984 14.5932 0.833984 14.2507V6.12565C0.833984 5.90275 0.945385 5.69459 1.13085 5.57095L8.63085 0.570951ZM2.16732 7.41972V13.548L6.48504 10.4981L2.16732 7.41972ZM7.84867 11.1673L3.5426 14.209H14.4587L10.1525 11.1673H7.84867ZM11.5162 10.4981L15.834 13.548V7.41972L11.5162 10.4981ZM15.3264 6.14407L9.00065 1.92688L2.67487 6.14407L7.85029 9.83398H10.151L15.3264 6.14407Z" fill="#081324" />')
      //     $(this).parent('li').css({ transform: 'translateX(-20%)' });
      //   }
      // } else {
      //   $(this).removeClass("active");
      //   if ($(this).hasClass('sub-menu-link')) {
      //     $(this).find('svg').html(`<path d="M15.666 0.333984H2.33268C1.41602 0.333984 0.674349 1.08398 0.674349 2.00065L0.666016 12.0007C0.666016 12.9173 1.41602 13.6673 2.33268 13.6673H15.666C16.5827 13.6673 17.3327 12.9173 17.3327 12.0007V2.00065C17.3327 1.08398 16.5827 0.333984 15.666 0.333984ZM15.666 12.0007H2.33268V3.66732L8.99935 7.83398L15.666 3.66732V12.0007ZM8.99935 6.16732L2.33268 2.00065H15.666L8.99935 6.16732Z" fill="#081324" />`)
      //     $(this).parent('li').css({ transform: 'translateX(0)' });
      //   }
      // }
    });
  }, [currentUrl, showMyProducts]);

  useEffect(() => {
    setLogo(currentUser?.company_logo);
  }, [currentUser]);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };
  const handleDecline = () => {
    Cookies.remove('session');
    window.location.href = '/decline'
  };


  const handleReturnToAdmin = () => {
    Cookies.remove('login_as');
    window.location.href = '/admin/agencies'
  }

  const [modalOpen, setModalOpen] = useState(false);

  if (isRetailer) {
    return (
      <div
        className="aside aside-left aside-fixed d-flex flex-column flex-row-auto bg-custom-light"
        id="kt_aside"
      >
        <div
          className="aside-menu-wrapper flex-column-fluid"
          id="kt_aside_menu_wrapper"
        >
          <div
            id="kt_aside_menu"
            className="aside-menu my-4  bg-custom-light"
            data-menu-vertical="1"
            data-menu-scroll="1"
            data-menu-dropdown-ti
            meout="500"
          >
            <div className="row">
              <div className="col-12 col-md-12 pt-2 text-center">
                <div
                  onClick={() => setModalOpen(true)}
                // style={{ cursor: "pointer" }}
                >
                  <img
                    id="kt_logo"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "/images/BevBridgeRFPLogo.png";
                    }}
                    style={{ width: 90, height: 90, objectFit: 'contain', borderRadius: '50%' }}
                    src={Logo ? Logo : "/images/BevBridgeRFPLogo.png"}
                    alt="logo"
                  />
                </div>
              </div>

              <div className="col-12 col-md-12 text-center">
                <div className="col-12 col-md-12 text-center">
                  <span id="kt_name">{currentUser?.company_name}</span>
                </div>
              </div>
              {(currentUser?.is_premium && currentUser?.role_id === 3) && (
                <div
                  className="col-12 col-md-12 text-center"
                  style={{
                    display: "flex",
                    justifyContent: "center"

                  }}>
                  <div style={{
                    width: "24%"
                  }}>
                    <p
                      style={{
                        padding: "0",
                        background: "#f2663a",
                        borderRadius: "6px",
                        color: "white",
                      }}
                    >Premium
                    </p>

                  </div>

                </div>
              )}
            </div>
            <Modal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                onClick: () => setModalOpen(false),
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <img
                  src={Logo ? Logo : "/images/BevBridgeRFPLogo.png"}
                  alt="logo"
                  style={{ width: "100px", height: "100px" }}
                />
              </Box>
            </Modal>
            {(currentUser?.role_id !== 1 && currentUser?.role_id !== 2) && (
              <>
                {!currentUser?.is_premium && !currentUser?.is_trial && !currentUser?.get_trial && (
                  <div
                    style={{
                      marginTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    <div className="user-access">
                      Limited Account
                    </div>
                    <button onClick={() => {
                      navigate(`/users/${currentUser?.id}`);
                      setshowMyProfile(true);
                    }}
                      href={'javascript:void(0);'}
                      style={{
                        background: "white",
                        color: "rgb(242, 102, 58)",
                        border: "2px solid rgb(255 255 255)",
                        borderRadius: "5px",
                        padding: "5px",
                        marginTop: "10px",
                        paddingRight: "20px",
                        paddingLeft: "20px"
                      }}

                    >Upgrade <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_4032_4898)">
                          <path d="M19.98 12.3501C18.41 8.27014 12.82 8.05014 14.17 2.12014C14.27 1.68014 13.8 1.34014 13.42 1.57014C9.79 3.71014 7.18 8.00014 9.37 13.6201C9.55 14.0801 9.01 14.5101 8.62 14.2101C6.81 12.8401 6.62 10.8701 6.78 9.46014C6.84 8.94014 6.16 8.69014 5.87 9.12014C5.19 10.1601 4.5 11.8401 4.5 14.3701C4.88 19.9701 9.61 21.6901 11.31 21.9101C13.74 22.2201 16.37 21.7701 18.26 20.0401C20.34 18.1101 21.1 15.0301 19.98 12.3501ZM10.7 17.3801C12.14 17.0301 12.88 15.9901 13.08 15.0701C13.41 13.6401 12.12 12.2401 12.99 9.98014C13.32 11.8501 16.26 13.0201 16.26 15.0601C16.34 17.5901 13.6 19.7601 10.7 17.3801Z" fill="#F2663A" />
                        </g>
                        <defs>
                          <clipPath id="clip0_4032_4898">
                            <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                )}

                {/* {currentUser?.get_trial && !currentUser?.is_trial && (
                  <div
                    style={{
                      marginTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    <div className="user-access">
                      Limited Account
                    </div>
                    <button onClick={() => {
                      navigate(`/users/${currentUser?.id}`);
                      setshowMyProfile(true);
                    }}
                      href={'javascript:void(0);'}
                      style={{
                        background: "white",
                        color: "rgb(242, 102, 58)",
                        border: "2px solid rgb(255 255 255)",
                        borderRadius: "5px",
                        padding: "5px",
                        marginTop: "10px",
                        paddingRight: "20px",
                        paddingLeft: "20px"
                      }}

                    >Upgrade <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_4032_4898)">
                          <path d="M19.98 12.3501C18.41 8.27014 12.82 8.05014 14.17 2.12014C14.27 1.68014 13.8 1.34014 13.42 1.57014C9.79 3.71014 7.18 8.00014 9.37 13.6201C9.55 14.0801 9.01 14.5101 8.62 14.2101C6.81 12.8401 6.62 10.8701 6.78 9.46014C6.84 8.94014 6.16 8.69014 5.87 9.12014C5.19 10.1601 4.5 11.8401 4.5 14.3701C4.88 19.9701 9.61 21.6901 11.31 21.9101C13.74 22.2201 16.37 21.7701 18.26 20.0401C20.34 18.1101 21.1 15.0301 19.98 12.3501ZM10.7 17.3801C12.14 17.0301 12.88 15.9901 13.08 15.0701C13.41 13.6401 12.12 12.2401 12.99 9.98014C13.32 11.8501 16.26 13.0201 16.26 15.0601C16.34 17.5901 13.6 19.7601 10.7 17.3801Z" fill="#F2663A" />
                        </g>
                        <defs>
                          <clipPath id="clip0_4032_4898">
                            <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                )} */}
                <Modal
                  id="TrialModal"
                  isOpen={showTrialModal}
                  toggle={() => setShowUpdagradeModal(!showTrialModal)}
                  scrollable={true}
                  backdrop="static"
                  keyboard={false}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "16px 24px",
                    }}
                  >
                    <Typography variant="h6" style={{
                      flexGrow: 1,
                      borderBottom: "1px solid #EFF2F5"
                    }}>
                      <h2 style={{
                        textAlign: "center"
                      }}>BevBridge RFP Terms of Use</h2>
                      <p style={{
                        textAlign: "center",
                        fontSize: "13px",
                        marginTop: "10px",
                      }}>Please review and accept the terms to continue using BevBridge RFP.</p>
                    </Typography>
                    {/* <IconButton onClick={() => setShowUpdagradeModal(!showTrialModal)}>
      <CloseIcon />
    </IconButton> */}
                  </div>
                  <ModalBody>
                    <h2>Acceptance of Terms</h2>
                    <p style={{
                      fontSize: "13px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}>By using BevBridge, you acknowledge that you have read, understood, and agree to these Terms of Use. If you do not agree, please refrain from using our services.</p>

                    <h2>Modifications to Terms</h2>
                    <p style={{
                      fontSize: "13px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}>We reserve the right to modify these Terms at any time. We will notify you of any changes by updating the date at the top of this document and, where appropriate, provide additional notice. Your continued use of BevBridge following any changes constitutes your acceptance of the new Terms.</p>

                    <h2>Use of Our Services</h2>
                    <ul style={{
                      listStyleType: "decimal",
                      fontSize: "13px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}>
                      <li>
                        Eligibility: You must be at least 18 years old to use BevBridge. By using our services, you confirm that you meet this age requirement.
                      </li>
                      <li>
                        Account Security: You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. Notify us immediately of any unauthorized use of your account
                      </li>
                      <li>
                        Prohibited Activities: You agree not to engage in any activity that:
                        <ul>
                          <li>
                            Violates any applicable law or regulation.
                          </li>
                          <li>
                            Involves the transmission of harmful, threatening, or otherwise objectionable content.
                          </li>
                          <li>
                            Interferes with or disrupts the integrity or performance of our services.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </ModalBody>
                  <ModalFooter>
                    <div style={{
                      width: "100%",
                      textAlign: "center"
                    }}>
                      <button
                        onClick={handleDecline}
                        style={{
                          background: "transparent",
                          border: "none"
                        }}>Decline</button>
                      <button
                        onClick={handleStartFreeTrial}
                        className="btn btn-pill btn-dark">Accept</button>
                    </div>
                  </ModalFooter>
                </Modal>

                <Modal
                  id="SuccessModal"
                  isOpen={showSuccessModal}
                  toggle={() => setShowSuccessModal(!showSuccessModal)}
                  scrollable={true}
                  backdrop="static"
                  keyboard={false}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "16px 24px",
                    }}
                  >
                    <Typography variant="h6" style={{
                      flexGrow: 1,
                      borderBottom: "1px solid #EFF2F5"
                    }}>
                      <h2 style={{
                        textAlign: "center"
                      }}>Thank You!</h2>
                    </Typography>
                    <IconButton onClick={() => setShowUpdagradeModal(!showTrialModal)}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <ModalBody>
                    <p style={{
                      textAlign: "center",
                      fontSize: "13px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}>You have accepted the BevBridge RFP Terms of Use and can now use our service without any problems!</p>



                  </ModalBody>
                </Modal>
                {currentUser?.is_trial && (
                  <div
                    style={{
                      marginTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    <div>
                      <TrialTimer endDate={currentUser.trial_end_date} />
                    </div>
                    <br></br>
                    {!currentUser?.is_paid && (
                      <a onClick={() => {
                        navigate(`/users/${currentUser?.id}`);
                        setshowMyProfile(true);
                      }}
                        href={'javascript:void(0);'}
                        style={{
                          background: "white",
                          color: "rgb(242, 102, 58)",
                          border: "2px solid rgb(255 255 255)",
                          borderRadius: "5px",
                          padding: "5px",
                          marginTop: "10px",
                          paddingRight: "20px",
                          paddingLeft: "20px"
                        }}

                      >Upgrade <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_4032_4898)">
                            <path d="M19.98 12.3501C18.41 8.27014 12.82 8.05014 14.17 2.12014C14.27 1.68014 13.8 1.34014 13.42 1.57014C9.79 3.71014 7.18 8.00014 9.37 13.6201C9.55 14.0801 9.01 14.5101 8.62 14.2101C6.81 12.8401 6.62 10.8701 6.78 9.46014C6.84 8.94014 6.16 8.69014 5.87 9.12014C5.19 10.1601 4.5 11.8401 4.5 14.3701C4.88 19.9701 9.61 21.6901 11.31 21.9101C13.74 22.2201 16.37 21.7701 18.26 20.0401C20.34 18.1101 21.1 15.0301 19.98 12.3501ZM10.7 17.3801C12.14 17.0301 12.88 15.9901 13.08 15.0701C13.41 13.6401 12.12 12.2401 12.99 9.98014C13.32 11.8501 16.26 13.0201 16.26 15.0601C16.34 17.5901 13.6 19.7601 10.7 17.3801Z" fill="#F2663A" />
                          </g>
                          <defs>
                            <clipPath id="clip0_4032_4898">
                              <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                    )}

                  </div>
                )}
              </>
            )}

            {currentUser?.email == 'tempAdmin@bevbridge.com' || loggedInAsUser && (
              <div className="d-flex align-items-center justify-content-center flex-column">
                <h5 class="text-center">
                  You are Logged as a temporary admin for this company.
                </h5>
                <button class="btn btn-sm btn-outline-custom-primary rounded-pill" onClick={handleReturnToAdmin}>
                  Return to Admin
                </button>
              </div>
            )}
            <ul className="menu-nav">
              <li className="menu-section">
                <h4 className="menu-text fw-bold text-center text-custom-primary mx-auto">
                  Main
                </h4>
                <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
              </li>
              {currentUser && currentUser.role_id == 1 && (
                <>
                  <li className="menu-item" aria-haspopup="true">
                    <Link
                      to={"/admin/agencies"}
                      className="menu-link menu-toggle"
                    >
                      <span className="svg-icon menu-icon">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_151_2684)">
                            <path
                              d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM6 4H13L18 9V17.58L16.16 15.74C17.44 13.8 17.23 11.17 15.52 9.46C14.55 8.49 13.28 8 12 8C10.72 8 9.45 8.49 8.47 9.46C6.52 11.41 6.52 14.57 8.47 16.51C9.44 17.48 10.72 17.97 12 17.97C12.96 17.97 13.92 17.69 14.75 17.14L17.6 20H6V4ZM14.11 15.1C13.55 15.66 12.8 15.98 12 15.98C11.2 15.98 10.45 15.67 9.89 15.1C9.33 14.54 9.01 13.79 9.01 12.99C9.01 12.19 9.32 11.44 9.89 10.88C10.45 10.31 11.2 10 12 10C12.8 10 13.55 10.31 14.11 10.88C14.67 11.44 14.99 12.19 14.99 12.99C14.99 13.79 14.68 14.54 14.11 15.1Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_151_2684">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <span className="menu-text">Agenceies</span>
                    </Link>
                  </li>
                  <li className="menu-item" aria-haspopup="true">
                    <Link
                      to={"/admin/suppliers"}
                      className="menu-link menu-toggle"
                    >
                      <span className="svg-icon menu-icon">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_151_2684)">
                            <path
                              d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM6 4H13L18 9V17.58L16.16 15.74C17.44 13.8 17.23 11.17 15.52 9.46C14.55 8.49 13.28 8 12 8C10.72 8 9.45 8.49 8.47 9.46C6.52 11.41 6.52 14.57 8.47 16.51C9.44 17.48 10.72 17.97 12 17.97C12.96 17.97 13.92 17.69 14.75 17.14L17.6 20H6V4ZM14.11 15.1C13.55 15.66 12.8 15.98 12 15.98C11.2 15.98 10.45 15.67 9.89 15.1C9.33 14.54 9.01 13.79 9.01 12.99C9.01 12.19 9.32 11.44 9.89 10.88C10.45 10.31 11.2 10 12 10C12.8 10 13.55 10.31 14.11 10.88C14.67 11.44 14.99 12.19 14.99 12.99C14.99 13.79 14.68 14.54 14.11 15.1Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_151_2684">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <span className="menu-text">Suppliers</span>
                    </Link>
                  </li>
                  <li className="menu-item" aria-haspopup="true">
                    <Link
                      to={"/admin/operators"}
                      className="menu-link menu-toggle"
                    >
                      <span className="svg-icon menu-icon">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_151_2684)">
                            <path
                              d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM6 4H13L18 9V17.58L16.16 15.74C17.44 13.8 17.23 11.17 15.52 9.46C14.55 8.49 13.28 8 12 8C10.72 8 9.45 8.49 8.47 9.46C6.52 11.41 6.52 14.57 8.47 16.51C9.44 17.48 10.72 17.97 12 17.97C12.96 17.97 13.92 17.69 14.75 17.14L17.6 20H6V4ZM14.11 15.1C13.55 15.66 12.8 15.98 12 15.98C11.2 15.98 10.45 15.67 9.89 15.1C9.33 14.54 9.01 13.79 9.01 12.99C9.01 12.19 9.32 11.44 9.89 10.88C10.45 10.31 11.2 10 12 10C12.8 10 13.55 10.31 14.11 10.88C14.67 11.44 14.99 12.19 14.99 12.99C14.99 13.79 14.68 14.54 14.11 15.1Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_151_2684">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <span className="menu-text">Operators</span>
                    </Link>
                  </li>
                </>
              )}
              {currentUser && currentUser.role_id === 3 && (
                <>
                  <li className="menu-item" aria-haspopup="true">
                    <Link to={"/rfps/find"}
                      onClick={() => {
                        setActiveMainTab('myrfp');
                      }}
                      className={activeMainTab == 'myrfp' ? "menu-link menu-toggle active" : "menu-link menu-toggle"}>
                      <span className="svg-icon menu-icon">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_151_2684)">
                            <path
                              d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM6 4H13L18 9V17.58L16.16 15.74C17.44 13.8 17.23 11.17 15.52 9.46C14.55 8.49 13.28 8 12 8C10.72 8 9.45 8.49 8.47 9.46C6.52 11.41 6.52 14.57 8.47 16.51C9.44 17.48 10.72 17.97 12 17.97C12.96 17.97 13.92 17.69 14.75 17.14L17.6 20H6V4ZM14.11 15.1C13.55 15.66 12.8 15.98 12 15.98C11.2 15.98 10.45 15.67 9.89 15.1C9.33 14.54 9.01 13.79 9.01 12.99C9.01 12.19 9.32 11.44 9.89 10.88C10.45 10.31 11.2 10 12 10C12.8 10 13.55 10.31 14.11 10.88C14.67 11.44 14.99 12.19 14.99 12.99C14.99 13.79 14.68 14.54 14.11 15.1Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_151_2684">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <span className="menu-text">My RFPs</span>
                    </Link>
                  </li>
                  <li className={"menu-item d-flex align-items-center flex-row"} aria-haspopup="true">
                    <Link
                      to={'/submissions'}
                      onClick={() => {
                        navigate('/submissions');
                        setShowMySubmissions(true);
                        setShowMyProducts(false);
                        setshowMyProfile(false);
                        setActiveMainTab('submissions');
                        setActiveSubStab('completed')
                      }} className={activeMainTab == 'submissions' ? "menu-link menu-toggle active" : "menu-link menu-toggle"}>
                      <span className="svg-icon menu-icon">
                        <svg
                          width="18"
                          height="10"
                          viewBox="0 0 18 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 6H2V4H0V6ZM0 10H2V8H0V10ZM0 2H2V0H0V2ZM4 6H18V4H4V6ZM4 10H18V8H4V10ZM4 0V2H18V0H4ZM0 6H2V4H0V6ZM0 10H2V8H0V10ZM0 2H2V0H0V2ZM4 6H18V4H4V6ZM4 10H18V8H4V10ZM4 0V2H18V0H4Z"
                            fill="#B5B5C3"
                          />
                        </svg>
                      </span>
                      <span className="menu-text">
                        My Submissions
                      </span>
                    </Link>
                  </li>
                  {showMySubmissions &&
                    <ul className="d-flex flex-column gap-2 align-items-start justify-content-center mt-3 my-products-list">
                      <li>
                        <Link to={'/submissions'}
                          onClick={() => {
                            setActiveMainTab('submissions');
                            setActiveSubStab('completed')
                          }}
                          className={activeSubTab == 'completed' ? "d-flex align-items-center gap-1 sub-menu-link text-custom-primary active" : "d-flex align-items-center gap-1 sub-menu-link text-custom-primary"}>
                          <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            {activeSubTab == 'completed' ?
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.63085 0.570951C8.85478 0.421662 9.14652 0.421662 9.37045 0.570951L16.8704 5.57095C17.0559 5.69459 17.1673 5.90275 17.1673 6.12565V14.2507C17.1673 14.5932 17.0312 14.9218 16.789 15.164C16.5468 15.4062 16.2182 15.5423 15.8757 15.5423H2.12565C1.78308 15.5423 1.45454 15.4062 1.2123 15.164C0.97007 14.9218 0.833984 14.5932 0.833984 14.2507V6.12565C0.833984 5.90275 0.945385 5.69459 1.13085 5.57095L8.63085 0.570951ZM2.16732 7.41972V13.548L6.48504 10.4981L2.16732 7.41972ZM7.84867 11.1673L3.5426 14.209H14.4587L10.1525 11.1673H7.84867ZM11.5162 10.4981L15.834 13.548V7.41972L11.5162 10.4981ZM15.3264 6.14407L9.00065 1.92688L2.67487 6.14407L7.85029 9.83398H10.151L15.3264 6.14407Z" fill="#081324" /> :
                              <path d="M15.666 0.333984H2.33268C1.41602 0.333984 0.674349 1.08398 0.674349 2.00065L0.666016 12.0007C0.666016 12.9173 1.41602 13.6673 2.33268 13.6673H15.666C16.5827 13.6673 17.3327 12.9173 17.3327 12.0007V2.00065C17.3327 1.08398 16.5827 0.333984 15.666 0.333984ZM15.666 12.0007H2.33268V3.66732L8.99935 7.83398L15.666 3.66732V12.0007ZM8.99935 6.16732L2.33268 2.00065H15.666L8.99935 6.16732Z" fill="#081324" />}
                          </svg>
                          <span>Completed</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={'/submissions/draft'}
                          onClick={() => {
                            setActiveMainTab('submissions');
                            setActiveSubStab('drafts')
                          }}
                          className={activeSubTab == 'drafts' ? "d-flex align-items-center gap-1 sub-menu-link text-custom-primary active" : "d-flex align-items-center gap-1 sub-menu-link text-custom-primary"}>
                          <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            {activeSubTab == 'drafts' ?
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.63085 0.570951C8.85478 0.421662 9.14652 0.421662 9.37045 0.570951L16.8704 5.57095C17.0559 5.69459 17.1673 5.90275 17.1673 6.12565V14.2507C17.1673 14.5932 17.0312 14.9218 16.789 15.164C16.5468 15.4062 16.2182 15.5423 15.8757 15.5423H2.12565C1.78308 15.5423 1.45454 15.4062 1.2123 15.164C0.97007 14.9218 0.833984 14.5932 0.833984 14.2507V6.12565C0.833984 5.90275 0.945385 5.69459 1.13085 5.57095L8.63085 0.570951ZM2.16732 7.41972V13.548L6.48504 10.4981L2.16732 7.41972ZM7.84867 11.1673L3.5426 14.209H14.4587L10.1525 11.1673H7.84867ZM11.5162 10.4981L15.834 13.548V7.41972L11.5162 10.4981ZM15.3264 6.14407L9.00065 1.92688L2.67487 6.14407L7.85029 9.83398H10.151L15.3264 6.14407Z" fill="#081324" /> :
                              <path d="M15.666 0.333984H2.33268C1.41602 0.333984 0.674349 1.08398 0.674349 2.00065L0.666016 12.0007C0.666016 12.9173 1.41602 13.6673 2.33268 13.6673H15.666C16.5827 13.6673 17.3327 12.9173 17.3327 12.0007V2.00065C17.3327 1.08398 16.5827 0.333984 15.666 0.333984ZM15.666 12.0007H2.33268V3.66732L8.99935 7.83398L15.666 3.66732V12.0007ZM8.99935 6.16732L2.33268 2.00065H15.666L8.99935 6.16732Z" fill="#081324" />}                          </svg>
                          <span>Drafts</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={'/submissions/trash'}
                          onClick={() => {
                            setActiveMainTab('submissions');
                            setActiveSubStab('trash')
                          }}
                          className={activeSubTab == 'trash' ? "d-flex align-items-center gap-1 sub-menu-link text-custom-primary active" : "d-flex align-items-center gap-1 sub-menu-link text-custom-primary"}>
                          <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            {activeSubTab == 'trash' ?
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.63085 0.570951C8.85478 0.421662 9.14652 0.421662 9.37045 0.570951L16.8704 5.57095C17.0559 5.69459 17.1673 5.90275 17.1673 6.12565V14.2507C17.1673 14.5932 17.0312 14.9218 16.789 15.164C16.5468 15.4062 16.2182 15.5423 15.8757 15.5423H2.12565C1.78308 15.5423 1.45454 15.4062 1.2123 15.164C0.97007 14.9218 0.833984 14.5932 0.833984 14.2507V6.12565C0.833984 5.90275 0.945385 5.69459 1.13085 5.57095L8.63085 0.570951ZM2.16732 7.41972V13.548L6.48504 10.4981L2.16732 7.41972ZM7.84867 11.1673L3.5426 14.209H14.4587L10.1525 11.1673H7.84867ZM11.5162 10.4981L15.834 13.548V7.41972L11.5162 10.4981ZM15.3264 6.14407L9.00065 1.92688L2.67487 6.14407L7.85029 9.83398H10.151L15.3264 6.14407Z" fill="#081324" /> :
                              <path d="M15.666 0.333984H2.33268C1.41602 0.333984 0.674349 1.08398 0.674349 2.00065L0.666016 12.0007C0.666016 12.9173 1.41602 13.6673 2.33268 13.6673H15.666C16.5827 13.6673 17.3327 12.9173 17.3327 12.0007V2.00065C17.3327 1.08398 16.5827 0.333984 15.666 0.333984ZM15.666 12.0007H2.33268V3.66732L8.99935 7.83398L15.666 3.66732V12.0007ZM8.99935 6.16732L2.33268 2.00065H15.666L8.99935 6.16732Z" fill="#081324" />}                          </svg>
                          <span>Trash</span>
                        </Link>
                      </li>
                    </ul>}
                  <li className={"menu-item d-flex align-items-center flex-row"} aria-haspopup="true">
                    <Link
                      to={'/products'}
                      onClick={() => {
                        navigate('/products');
                        setShowMySubmissions(false);
                        setshowMyProfile(false)
                        setShowMyProducts(true);
                        setActiveMainTab('products');
                        setActiveSubStab('active')
                      }} className={activeMainTab == 'products' ? "menu-link menu-toggle active" : "menu-link menu-toggle"}>
                      <span className="svg-icon menu-icon">
                        <svg
                          width="18"
                          height="10"
                          viewBox="0 0 18 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 6H2V4H0V6ZM0 10H2V8H0V10ZM0 2H2V0H0V2ZM4 6H18V4H4V6ZM4 10H18V8H4V10ZM4 0V2H18V0H4ZM0 6H2V4H0V6ZM0 10H2V8H0V10ZM0 2H2V0H0V2ZM4 6H18V4H4V6ZM4 10H18V8H4V10ZM4 0V2H18V0H4Z"
                            fill="#B5B5C3"
                          />
                        </svg>
                      </span>
                      <span className="menu-text">
                        My Products ({totalProducts})
                      </span>
                    </Link>
                  </li>
                  {showMyProducts && <ul className="d-flex flex-column gap-2 align-items-start justify-content-center mt-3 my-products-list">
                    <li>
                      <Link to={'/products'}
                        onClick={() => {
                          setActiveMainTab('products');
                          setActiveSubStab('active')
                        }}
                        className={activeSubTab == 'active' ? "d-flex align-items-center gap-1 sub-menu-link text-custom-primary active" : "d-flex align-items-center gap-1 sub-menu-link text-custom-primary"}>
                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          {activeSubTab == 'active' ?
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.63085 0.570951C8.85478 0.421662 9.14652 0.421662 9.37045 0.570951L16.8704 5.57095C17.0559 5.69459 17.1673 5.90275 17.1673 6.12565V14.2507C17.1673 14.5932 17.0312 14.9218 16.789 15.164C16.5468 15.4062 16.2182 15.5423 15.8757 15.5423H2.12565C1.78308 15.5423 1.45454 15.4062 1.2123 15.164C0.97007 14.9218 0.833984 14.5932 0.833984 14.2507V6.12565C0.833984 5.90275 0.945385 5.69459 1.13085 5.57095L8.63085 0.570951ZM2.16732 7.41972V13.548L6.48504 10.4981L2.16732 7.41972ZM7.84867 11.1673L3.5426 14.209H14.4587L10.1525 11.1673H7.84867ZM11.5162 10.4981L15.834 13.548V7.41972L11.5162 10.4981ZM15.3264 6.14407L9.00065 1.92688L2.67487 6.14407L7.85029 9.83398H10.151L15.3264 6.14407Z" fill="#081324" /> :
                            <path d="M15.666 0.333984H2.33268C1.41602 0.333984 0.674349 1.08398 0.674349 2.00065L0.666016 12.0007C0.666016 12.9173 1.41602 13.6673 2.33268 13.6673H15.666C16.5827 13.6673 17.3327 12.9173 17.3327 12.0007V2.00065C17.3327 1.08398 16.5827 0.333984 15.666 0.333984ZM15.666 12.0007H2.33268V3.66732L8.99935 7.83398L15.666 3.66732V12.0007ZM8.99935 6.16732L2.33268 2.00065H15.666L8.99935 6.16732Z" fill="#081324" />}
                        </svg>
                        <span>Active ({totalActiveProducts?.length})</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={'/products/create'}
                        onClick={() => {
                          setActiveMainTab('products');
                          setActiveSubStab('add_new')
                        }}
                        className={activeSubTab == 'add_new' ? "d-flex align-items-center gap-1 sub-menu-link text-custom-primary active" : "d-flex align-items-center gap-1 sub-menu-link text-custom-primary"}>
                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">

                          {activeSubTab == 'add_new' ?
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.63085 0.570951C8.85478 0.421662 9.14652 0.421662 9.37045 0.570951L16.8704 5.57095C17.0559 5.69459 17.1673 5.90275 17.1673 6.12565V14.2507C17.1673 14.5932 17.0312 14.9218 16.789 15.164C16.5468 15.4062 16.2182 15.5423 15.8757 15.5423H2.12565C1.78308 15.5423 1.45454 15.4062 1.2123 15.164C0.97007 14.9218 0.833984 14.5932 0.833984 14.2507V6.12565C0.833984 5.90275 0.945385 5.69459 1.13085 5.57095L8.63085 0.570951ZM2.16732 7.41972V13.548L6.48504 10.4981L2.16732 7.41972ZM7.84867 11.1673L3.5426 14.209H14.4587L10.1525 11.1673H7.84867ZM11.5162 10.4981L15.834 13.548V7.41972L11.5162 10.4981ZM15.3264 6.14407L9.00065 1.92688L2.67487 6.14407L7.85029 9.83398H10.151L15.3264 6.14407Z" fill="#081324" /> :
                            <path d="M15.666 0.333984H2.33268C1.41602 0.333984 0.674349 1.08398 0.674349 2.00065L0.666016 12.0007C0.666016 12.9173 1.41602 13.6673 2.33268 13.6673H15.666C16.5827 13.6673 17.3327 12.9173 17.3327 12.0007V2.00065C17.3327 1.08398 16.5827 0.333984 15.666 0.333984ZM15.666 12.0007H2.33268V3.66732L8.99935 7.83398L15.666 3.66732V12.0007ZM8.99935 6.16732L2.33268 2.00065H15.666L8.99935 6.16732Z" fill="#081324" />}
                        </svg>
                        <span>Add New</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={'/products/draft'}
                        onClick={() => {
                          setActiveMainTab('products');
                          setActiveSubStab('product_draft')
                        }}
                        className={activeSubTab == 'product_draft' ? "d-flex align-items-center gap-1 sub-menu-link text-custom-primary active" : "d-flex align-items-center gap-1 sub-menu-link text-custom-primary"}>
                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          {activeSubTab == 'product_draft' ?
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.63085 0.570951C8.85478 0.421662 9.14652 0.421662 9.37045 0.570951L16.8704 5.57095C17.0559 5.69459 17.1673 5.90275 17.1673 6.12565V14.2507C17.1673 14.5932 17.0312 14.9218 16.789 15.164C16.5468 15.4062 16.2182 15.5423 15.8757 15.5423H2.12565C1.78308 15.5423 1.45454 15.4062 1.2123 15.164C0.97007 14.9218 0.833984 14.5932 0.833984 14.2507V6.12565C0.833984 5.90275 0.945385 5.69459 1.13085 5.57095L8.63085 0.570951ZM2.16732 7.41972V13.548L6.48504 10.4981L2.16732 7.41972ZM7.84867 11.1673L3.5426 14.209H14.4587L10.1525 11.1673H7.84867ZM11.5162 10.4981L15.834 13.548V7.41972L11.5162 10.4981ZM15.3264 6.14407L9.00065 1.92688L2.67487 6.14407L7.85029 9.83398H10.151L15.3264 6.14407Z" fill="#081324" /> :
                            <path d="M15.666 0.333984H2.33268C1.41602 0.333984 0.674349 1.08398 0.674349 2.00065L0.666016 12.0007C0.666016 12.9173 1.41602 13.6673 2.33268 13.6673H15.666C16.5827 13.6673 17.3327 12.9173 17.3327 12.0007V2.00065C17.3327 1.08398 16.5827 0.333984 15.666 0.333984ZM15.666 12.0007H2.33268V3.66732L8.99935 7.83398L15.666 3.66732V12.0007ZM8.99935 6.16732L2.33268 2.00065H15.666L8.99935 6.16732Z" fill="#081324" />}
                        </svg>
                        <span>Drafts ({totalDraftProducts?.length})</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={'/products/trash'}
                        onClick={() => {
                          setActiveMainTab('products');
                          setActiveSubStab('product_trash')
                        }}
                        className={activeSubTab == 'product_trash' ? "d-flex align-items-center gap-1 sub-menu-link text-custom-primary active" : "d-flex align-items-center gap-1 sub-menu-link text-custom-primary"}>
                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          {activeSubTab == 'product_trash' ?
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.63085 0.570951C8.85478 0.421662 9.14652 0.421662 9.37045 0.570951L16.8704 5.57095C17.0559 5.69459 17.1673 5.90275 17.1673 6.12565V14.2507C17.1673 14.5932 17.0312 14.9218 16.789 15.164C16.5468 15.4062 16.2182 15.5423 15.8757 15.5423H2.12565C1.78308 15.5423 1.45454 15.4062 1.2123 15.164C0.97007 14.9218 0.833984 14.5932 0.833984 14.2507V6.12565C0.833984 5.90275 0.945385 5.69459 1.13085 5.57095L8.63085 0.570951ZM2.16732 7.41972V13.548L6.48504 10.4981L2.16732 7.41972ZM7.84867 11.1673L3.5426 14.209H14.4587L10.1525 11.1673H7.84867ZM11.5162 10.4981L15.834 13.548V7.41972L11.5162 10.4981ZM15.3264 6.14407L9.00065 1.92688L2.67487 6.14407L7.85029 9.83398H10.151L15.3264 6.14407Z" fill="#081324" /> :
                            <path d="M15.666 0.333984H2.33268C1.41602 0.333984 0.674349 1.08398 0.674349 2.00065L0.666016 12.0007C0.666016 12.9173 1.41602 13.6673 2.33268 13.6673H15.666C16.5827 13.6673 17.3327 12.9173 17.3327 12.0007V2.00065C17.3327 1.08398 16.5827 0.333984 15.666 0.333984ZM15.666 12.0007H2.33268V3.66732L8.99935 7.83398L15.666 3.66732V12.0007ZM8.99935 6.16732L2.33268 2.00065H15.666L8.99935 6.16732Z" fill="#081324" />}
                        </svg>
                        <span>Trash ({totalTrashProducts?.length})</span>
                      </Link>
                    </li>
                  </ul>}

                </>
              )}
              {currentUser && currentUser.role_id === 2 && (
                <>
                  {/* <li className="menu-item" aria-haspopup="true">
                    <Link to={`/my-clients`} className="menu-link menu-toggle">
                      <span className="svg-icon menu-icon">
                        <svg
                          viewBox="0 0 640 512"
                          fill="currentColor"
                          height="1em"
                          width="1em"
                        >
                          <path d="M144 160c-44.2 0-80-35.8-80-80S99.8 0 144 0s80 35.8 80 80-35.8 80-80 80zm368 0c-44.2 0-80-35.8-80-80s35.8-80 80-80 80 35.8 80 80-35.8 80-80 80zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7-1.3 7.2-1.9 14.7-1.9 22.3 0 38.2 16.8 72.5 43.3 96H21.3C9.6 320 0 310.4 0 298.7zM405.3 320h-.7c26.6-23.5 43.3-57.8 43.3-96 0-7.6-.7-15-1.9-22.3 13.6-6.3 28.7-9.7 44.6-9.7h42.7c58.9 0 106.7 47.8 106.7 106.7 0 11.8-9.6 21.3-21.3 21.3H405.3zm10.7-96c0 53-43 96-96 96s-96-43-96-96 43-96 96-96 96 43 96 96zM128 485.3c0-73.6 59.7-133.3 133.3-133.3h117.4c73.6 0 133.3 59.7 133.3 133.3 0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z" />
                        </svg>
                      </span>
                      <span className="menu-text">Manage Clients</span>
                    </Link>
                  </li> */}
                  <li className="menu-item" aria-haspopup="true">
                    <Link to={`/rfps`} className="menu-link menu-toggle">
                      <span className="svg-icon menu-icon">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_128_4457)">
                            <path
                              d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM6 20V4H13V9H18V20H6Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_128_4457">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <span className="menu-text">Manage RFPs</span>
                    </Link>
                  </li>
                  <li className="menu-item" aria-haspopup="true">
                    <Link to={"/create"} className="menu-link menu-toggle">
                      <span className="svg-icon menu-icon">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_128_4462)">
                            <path
                              d="M4 6H2V20C2 21.1 2.9 22 4 22H18V20H4V6ZM20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H8V4H20V16ZM13 15H15V11H19V9H15V5H13V9H9V11H13V15Z"
                              fill="#081324"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_128_4462">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <span className="menu-text">Add RFP</span>
                    </Link>
                  </li>
                </>
              )}
              {currentUser && currentUser.role_id === 4 && (
                <li className="menu-item" aria-haspopup="true">
                  <Link to={`/opertator-rfps`} className="menu-link menu-toggle">
                    <span className="svg-icon menu-icon">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_128_4457)">
                          <path
                            d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM6 20V4H13V9H18V20H6Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_128_4457">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span className="menu-text">My RFPs</span>
                  </Link>
                </li>
              )}
              <li className="menu-section">
                <h4 className="menu-text fw-bold text-center text-custom-primary mx-auto">
                  Account
                </h4>
                <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
              </li>
              <li className={"menu-item"} aria-haspopup="true">
                <Link to={`/users`} onClick={() => {
                  navigate(`/users`);
                  setShowMySubmissions(false);
                  setShowMyProducts(false);
                  setshowMyProfile(true);
                  setActiveMainTab('profile');
                  setActiveSubStab('sub_profile')
                }} className={activeMainTab == 'profile' ? "menu-link menu-toggle active" : "menu-link menu-toggle"}>
                  <span className="svg-icon menu-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="18px"
                      height="18px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          opacity="0.3"
                        />
                        <path
                          d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                          fill="#000000"
                          fillRule="nonzero"
                        />
                      </g>
                    </svg>
                  </span>
                  <span className="menu-text">
                    Profile
                  </span>
                </Link>
              </li>
              {currentUser?.role_id != 2 && (
                <>
                  {showMyProfile && <ul className="d-flex flex-column gap-2 align-items-start justify-content-center mt-3 my-products-list">
                    <li>
                      <Link to={`/users`} 
                      onClick={()=>{
                        setActiveSubStab('sub_profile')
                      }}
                      className={activeMainTab == 'sub_profile' ? "menu-link menu-toggle active" : "menu-link menu-toggle"}>
                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        {activeSubTab == 'sub_profile' ?
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.63085 0.570951C8.85478 0.421662 9.14652 0.421662 9.37045 0.570951L16.8704 5.57095C17.0559 5.69459 17.1673 5.90275 17.1673 6.12565V14.2507C17.1673 14.5932 17.0312 14.9218 16.789 15.164C16.5468 15.4062 16.2182 15.5423 15.8757 15.5423H2.12565C1.78308 15.5423 1.45454 15.4062 1.2123 15.164C0.97007 14.9218 0.833984 14.5932 0.833984 14.2507V6.12565C0.833984 5.90275 0.945385 5.69459 1.13085 5.57095L8.63085 0.570951ZM2.16732 7.41972V13.548L6.48504 10.4981L2.16732 7.41972ZM7.84867 11.1673L3.5426 14.209H14.4587L10.1525 11.1673H7.84867ZM11.5162 10.4981L15.834 13.548V7.41972L11.5162 10.4981ZM15.3264 6.14407L9.00065 1.92688L2.67487 6.14407L7.85029 9.83398H10.151L15.3264 6.14407Z" fill="#081324" /> :
                              <path d="M15.666 0.333984H2.33268C1.41602 0.333984 0.674349 1.08398 0.674349 2.00065L0.666016 12.0007C0.666016 12.9173 1.41602 13.6673 2.33268 13.6673H15.666C16.5827 13.6673 17.3327 12.9173 17.3327 12.0007V2.00065C17.3327 1.08398 16.5827 0.333984 15.666 0.333984ZM15.666 12.0007H2.33268V3.66732L8.99935 7.83398L15.666 3.66732V12.0007ZM8.99935 6.16732L2.33268 2.00065H15.666L8.99935 6.16732Z" fill="#081324" />}
                        </svg>
                        <span>Profile</span>
                      </Link>
                    </li>
                    <li>
                      {(currentUser?.is_trial || currentUser?.is_premium) && (
                        <a href={'https://billing.stripe.com/p/login/test_cN24jtfLGdSE4rSdQQ'} target="_blank" className="d-flex align-items-center gap-1 sub-menu-link text-custom-primary">
                          <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.666 0.333984H2.33268C1.41602 0.333984 0.674349 1.08398 0.674349 2.00065L0.666016 12.0007C0.666016 12.9173 1.41602 13.6673 2.33268 13.6673H15.666C16.5827 13.6673 17.3327 12.9173 17.3327 12.0007V2.00065C17.3327 1.08398 16.5827 0.333984 15.666 0.333984ZM15.666 12.0007H2.33268V3.66732L8.99935 7.83398L15.666 3.66732V12.0007ZM8.99935 6.16732L2.33268 2.00065H15.666L8.99935 6.16732Z" fill="#081324" />
                          </svg>
                          <span>Billing</span>
                        </a>
                      )}
                    </li>
                  </ul>}
                </>
              )}
              <li className="menu-item mt-3" aria-haspopup="true">
                <Link
                  to={`/get-help`}
                  onClick={() => {
                    setActiveMainTab('gethelp');
                  }}
                  className={activeMainTab == 'gethelp' ? "menu-link menu-toggle active" : "menu-link menu-toggle"}
                >
                  <span className="svg-icon menu-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M11.95 18q.525 0 .888-.363t.362-.887q0-.525-.362-.888t-.888-.362q-.525 0-.887.363t-.363.887q0 .525.363.888t.887.362m-.9-3.85h1.85q0-.825.188-1.3t1.062-1.3q.65-.65 1.025-1.238T15.55 8.9q0-1.4-1.025-2.15T12.1 6q-1.425 0-2.312.75T8.55 8.55l1.65.65q.125-.45.563-.975T12.1 7.7q.8 0 1.2.438t.4.962q0 .5-.3.938t-.75.812q-1.1.975-1.35 1.475t-.25 1.825M12 22q-2.075 0-3.9-.787t-3.175-2.138q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22" /></svg>
                  </span>
                  <span className="menu-text">Get Help</span>
                </Link>
              </li>
              <li className="menu-item" aria-haspopup="true">
                <a className="menu-link menu-toggle" onClick={handleLogout}>
                  <span className="svg-icon menu-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect
                          opacity="0.300000012"
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                        />
                        <polygon
                          fill="#000000"
                          fillRule="nonzero"
                          opacity="0.3"
                          points="7 4.89473684 7 21 5 21 5 3 11 3 11 4.89473684"
                        />
                        <path
                          d="M10.1782982,2.24743315 L18.1782982,3.6970464 C18.6540619,3.78325557 19,4.19751166 19,4.68102291 L19,19.3190064 C19,19.8025177 18.6540619,20.2167738 18.1782982,20.3029829 L10.1782982,21.7525962 C9.63486295,21.8510675 9.11449486,21.4903531 9.0160235,20.9469179 C9.00536265,20.8880837 9,20.8284119 9,20.7686197 L9,3.23140966 C9,2.67912491 9.44771525,2.23140966 10,2.23140966 C10.0597922,2.23140966 10.119464,2.2367723 10.1782982,2.24743315 Z M11.9166667,12.9060229 C12.6070226,12.9060229 13.1666667,12.2975724 13.1666667,11.5470105 C13.1666667,10.7964487 12.6070226,10.1879981 11.9166667,10.1879981 C11.2263107,10.1879981 10.6666667,10.7964487 10.6666667,11.5470105 C10.6666667,12.2975724 11.2263107,12.9060229 11.9166667,12.9060229 Z"
                          fill="#000000"
                        />
                      </g>
                    </svg>
                  </span>
                  <span className="menu-text">Log Out</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
      id="kt_aside"
      style={{ background: "#F1F3F4" }}
    >
      <div
        className="aside-menu-wrapper flex-column-fluid"
        id="kt_aside_menu_wrapper"
      >
        <div
          id="kt_aside_menu"
          className="aside-menu my-4"
          data-menu-vertical="1"
          data-menu-scroll="1"
          data-menu-dropdown-timeout="500"
          style={{ background: "#F1F3F4" }}
        >
          <div className="row">
            <div className="col-12 col-md-12 pt-5 text-center">
              <img id="kt_logo" src="/images/logo.svg" alt="logo" />
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-12 col-md-12 text-center">
              <span id="kt_name">Constellation Brands</span>
            </div>
          </div>
          <ul className="menu-nav">
            <li className="menu-section">
              <h4 className="menu-text">Main</h4>
              <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
            </li>
            <li className="menu-item" aria-haspopup="true">
              <Link to={"/"} className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                    </g>
                  </svg>
                </span>
                <span className="menu-text">Find RFP's</span>
              </Link>
            </li>
            <li className="menu-item" aria-haspopup="true">
              <Link to={"/users/1/RFPs"} className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z M10.875,15.75 C11.1145833,15.75 11.3541667,15.6541667 11.5458333,15.4625 L15.3791667,11.6291667 C15.7625,11.2458333 15.7625,10.6708333 15.3791667,10.2875 C14.9958333,9.90416667 14.4208333,9.90416667 14.0375,10.2875 L10.875,13.45 L9.62916667,12.2041667 C9.29375,11.8208333 8.67083333,11.8208333 8.2875,12.2041667 C7.90416667,12.5875 7.90416667,13.1625 8.2875,13.5458333 L10.2041667,15.4625 C10.3958333,15.6541667 10.6354167,15.75 10.875,15.75 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                </span>
                <span className="menu-text">Submitted RFP's</span>
              </Link>
            </li>
            <li className="menu-item" aria-haspopup="true">
              <Link to={"/products"} className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M20.4061385,6.73606154 C20.7672665,6.89656288 21,7.25468437 21,7.64987309 L21,16.4115967 C21,16.7747638 20.8031081,17.1093844 20.4856429,17.2857539 L12.4856429,21.7301984 C12.1836204,21.8979887 11.8163796,21.8979887 11.5143571,21.7301984 L3.51435707,17.2857539 C3.19689188,17.1093844 3,16.7747638 3,16.4115967 L3,7.64987309 C3,7.25468437 3.23273352,6.89656288 3.59386153,6.73606154 L11.5938615,3.18050598 C11.8524269,3.06558805 12.1475731,3.06558805 12.4061385,3.18050598 L20.4061385,6.73606154 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                      <polygon
                        fill="#000000"
                        points="14.9671522 4.22441676 7.5999999 8.31727912 7.5999999 12.9056825 9.5999999 13.9056825 9.5999999 9.49408582 17.25507 5.24126912"
                      />
                    </g>
                  </svg>
                </span>
                <span className="menu-text">Products</span>
              </Link>
            </li>
            <li className="menu-section">
              <h4 className="menu-text">Account</h4>
              <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
            </li>
            <li className="menu-item" aria-haspopup="true">
              <Link to={"/users/1"} className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                    </g>
                  </svg>
                </span>
                <span className="menu-text">Profile</span>
              </Link>
            </li>
            <li className="menu-item" aria-haspopup="true">
              <Link to={"/users/logout"} className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect
                        opacity="0.300000012"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                      />
                      <polygon
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                        points="7 4.89473684 7 21 5 21 5 3 11 3 11 4.89473684"
                      />
                      <path
                        d="M10.1782982,2.24743315 L18.1782982,3.6970464 C18.6540619,3.78325557 19,4.19751166 19,4.68102291 L19,19.3190064 C19,19.8025177 18.6540619,20.2167738 18.1782982,20.3029829 L10.1782982,21.7525962 C9.63486295,21.8510675 9.11449486,21.4903531 9.0160235,20.9469179 C9.00536265,20.8880837 9,20.8284119 9,20.7686197 L9,3.23140966 C9,2.67912491 9.44771525,2.23140966 10,2.23140966 C10.0597922,2.23140966 10.119464,2.2367723 10.1782982,2.24743315 Z M11.9166667,12.9060229 C12.6070226,12.9060229 13.1666667,12.2975724 13.1666667,11.5470105 C13.1666667,10.7964487 12.6070226,10.1879981 11.9166667,10.1879981 C11.2263107,10.1879981 10.6666667,10.7964487 10.6666667,11.5470105 C10.6666667,12.2975724 11.2263107,12.9060229 11.9166667,12.9060229 Z"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                </span>
                <span className="menu-text">Log Out</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
