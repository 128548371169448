import axios from "src/plugins/axios";
import { useParams } from 'react-router-dom';
import { currentUserSelector } from "src/plugins/redux/reducers/AuthReducer";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Alert from '@mui/material/Alert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useState } from "react";
import { sendContactEmail } from "src/plugins/redux/reducers/RFPsReducer";

export default function ContactFormModal() {

  const dispatch = useDispatch();
  const [messageSent, setMessageSent] = useState<boolean>(false);

  const user = useSelector(currentUserSelector);
  const { register, handleSubmit, reset, formState: { errors }, control } = useForm();

  const { id } = useParams()

  const handleSendMessage = async (data: any) => {
    const user_id = user?.id.toString();
    data.rfp_id = id;
    data.user_id = user_id;
    const response = await dispatch(sendContactEmail(data))

    if (response.payload.success) {
      reset();
      setMessageSent(true);
      setTimeout(() => {
        setMessageSent(false);
      }, 3000);
    }
  }

  return (
    <div className="modal fade" id="contactFormModal" tabIndex={-1} role="dialog" aria-labelledby="contactFormModalLabel"
      aria-hidden="true">
      <div className="modal-dialog" role="document">
        <form onSubmit={handleSubmit(handleSendMessage)} className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="contactFormModalLabel">Contact Form</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <i aria-hidden="true" className="ki ki-close"></i>
            </button>
          </div>
          <div className="modal-body">

            <div className="row mb-3">
              <div className="col-12 col-md-12">
                <label>Name*</label>
                <input {...register("name", { required: true })} type="text" className="form-control" name="name" placeholder="Name" />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-12">
                <label>Email*</label>
                <input  {...register("email", { required: true })} type="text" className="form-control" name="email" placeholder="Email" />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-12">
                <label>Message</label>
                <textarea  {...register("message", { required: true })} className="form-control" name="message" placeholder="Message" style={{ resize: 'none' }} />
              </div>
            </div>

            {messageSent && <Alert
              iconMapping={{
                success: <CheckCircleOutlineIcon fontSize="inherit" />,
              }}
            >
              Your message has been submitted
            </Alert>}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-pill btn-outline-dark font-weight-bold" data-dismiss="modal">Cancel</button>
            <button type="submit" className="btn btn-pill btn-dark font-weight-bold">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}
