import ReduxStore from "plugins/redux";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import LightTheme from "themes/LightTheme";
import GlobalStyle from "themes/styles/GlobalStyle";
import App from './App';
import ToasterProvider from "./componenets/ToasterProvider";

const rootElement = document.getElementById('root') as HTMLElement;

const root = createRoot(rootElement);

root.render(
  <Provider store={ReduxStore}>
    <ThemeProvider theme={LightTheme}>

      <GlobalStyle />
      <BrowserRouter>
        <ToasterProvider />
        <App />

      </BrowserRouter>

    </ThemeProvider>
  </Provider>
);