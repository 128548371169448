import { forwardRef } from "react";
import { useEffect, useState } from "react";
import {
    categorySubTypes, fetchCategories, selectCategories,
    categoryTypes, selectTypes, addType, selectSubTypes, addSubType,
    poursOptions, selectPours, addPour,
} from 'src/plugins/redux/reducers/CategoriesReducer';
import { useSelector, useDispatch } from "react-redux";

const AddProductRequestToRFP = forwardRef((props: any, ref: any): JSX.Element => {
    const dispatch = useDispatch();

    const [modalType, setModalType] = useState('');
    const [selectedValues, setSelectedValues] = useState([]);
    const [otherOption, setOtherOption] = useState(false);
    const [otherData, setOtherData] = useState('');
    const [currentCategory, setCurrentCategory] = useState();
    const [currentColumn, setCurrentColumn] = useState('');
    const [currentIndex, setCurrentIndex] = useState(1);
    const [showCatgeories, setShowCategories] = useState(false);
    const [types, setTypes] = useState();
    const [parentType, setParentType] = useState(null);
    const [varietals, setVarietals] = useState([]);
    const [viewNote, setViewNote] = useState();
    const [tempProducts, setTempProducts] = useState([]);
    const selectedCategories = props.selectedCategories;
    const [products, setProducts] = useState([]);
    const pours = useSelector(selectPours);
    const categories = useSelector(selectCategories);




    useEffect(() => {
        $('#selectTypeModal').on('hidden.bs.modal', function (e) {
            setSelectedValues([]);
        });
    }, [])

    useEffect(() => {
        setProducts(selectedCategories.map((category) => {
            const categoryExist = products.find((productsCategory) => productsCategory.category.id == category.id);
            if (categoryExist) {
                return categoryExist;
            } else {
                return {
                    'category': category,
                    'products': { 'type': [], 'varietal': [], 'pour': [], 'price': [], 'notes': [] }
                }
            }
        }));

    }, [selectedCategories]);

    useEffect(() => {
        dispatch(poursOptions());
        dispatch(fetchCategories());
        const savedProducts = JSON.parse(localStorage.getItem('rfpRequestsData'));
        if (savedProducts && savedProducts?.length > 0 && !props.fromEdit) {
            setProducts(savedProducts);
        }
    }, []);


    useEffect(() => {
        if (products && products?.length > 0 && !props.fromEdit) {
            localStorage.setItem('rfpRequestsData', JSON.stringify(products));
        }
        props.setAddedProducts(products);
    }, [products]);


    useEffect(() => {
        if (props.AddedProducts.length > 0) {
            setProducts(props.AddedProducts)
        }
    }, []);


    useEffect(() => {
        var categoryVarietals = [];
        if (currentCategory) {
            const newCurrentCategory = categories.find((category) => category?.id == currentCategory?.id);
            setTypes(newCurrentCategory.types);
        }
        if (parentType?.id && currentCategory) {
            const newCurrentCategory = categories.find((category) => category?.id == currentCategory?.id);
            const parentValue = newCurrentCategory.types?.find((type) => type.id == parentType.id);
            setVarietals(parentValue?.sub_type);
        } else {
            const newCurrentCategory = categories.find((category) => category?.id == currentCategory?.id);
            newCurrentCategory?.types?.map((type) => {
                const sub_types = type.sub_type;
                if (Array.isArray(sub_types)) {
                    categoryVarietals = [...categoryVarietals, ...sub_types];
                }
            });
            setVarietals(categoryVarietals);
        }
    }, [currentCategory, parentType])

    const handleModalType = (type: string) => {
        setModalType(type)
        setCurrentColumn(type)
        $('#selectTypeModal').modal('show')
    }


    const handleDeleteRow = (rowIndex, category) => {

        const updatedProducts = products.map((cat) => {
            if (cat.category.id == category.id) {

                const updatedType = cat.products.type.filter((row) => row.rowIndex != rowIndex);
                const updatedNotes = cat.products.notes.filter((row) => row.rowIndex != rowIndex);
                const updatedVarietals = cat.products.varietal.filter((row) => row.rowIndex != rowIndex);
                const updatedPrice = cat.products.price.filter((row) => row.rowIndex != rowIndex);
                const updatedPour = cat.products.pour.filter((row) => row.rowIndex != rowIndex);

                const updatedCategory = {
                    category: cat.category,
                    products: {
                        price: updatedPrice,
                        varietal: updatedVarietals,
                        type: updatedType,
                        notes: updatedNotes,
                        pour: updatedPour,
                    },
                };

                return updatedCategory;
            }
            return cat;
        });

        setProducts(updatedProducts);
        setSelectedValues([]);
        setCurrentColumn('');
        setModalType('');
        setCurrentCategory(null);
        setParentType(null);
        setVarietals([]);
        setTypes([]);
        setOtherData('');
        setOtherOption(false);
    };

    const handleEditPrice = (new_min_pice, new_max_price) => {

    }
    const handleEditNotes = (new_notes) => {
        let editedProducts = products.map((category) => {
            if (category.category.id == currentCategory.id) {
                category.products[currentColumn].map((row) => {
                    if (row.rowIndex == currentIndex) {
                        row.text = new_notes;
                    }
                });
            }
            return category;
        });

        setProducts(editedProducts);
    }


    const handleAddValues = async () => {
        if (otherOption && otherData && otherData != '') {
            if (currentColumn == 'pour') {
                const response = await dispatch(addPour({ 'name': otherData }));
                selectedValues.push({ 'id': response.payload.success, 'name': otherData })
            } if (currentColumn == 'type') {
                const response = await dispatch(addType({ 'name': otherData, 'category_id': currentCategory.id }));
                selectedValues.push({ 'id': response.payload.success, 'name': otherData })
            } if (currentColumn == 'varietal') {
                const response = await dispatch(addSubType({ 'name': otherData, 'category_type_id': parentType.id }));
                selectedValues.push({ 'id': response.payload.success, 'name': otherData, 'category_type_id': parentType.id });
                setVarietals([...varietals, { 'id': response.payload.success, 'name': otherData, 'category_type_id': parentType.id }])

            }
            dispatch(fetchCategories());
            setCurrentCategory(null);
        }
        let newProducts = [...products];
        newProducts.map((category) => {
            var firstRowTypeData;
            var firstRowVarietalData;
            if (currentCategory.id == category.category.id) {
                var rowIndex = currentIndex;
                selectedValues.map((value, valueIndex) => {
                    if (valueIndex == 0) {
                        firstRowTypeData = category.products['type'].find((type) => type.rowIndex == rowIndex);
                        firstRowVarietalData = category.products['varietal'].find((varietal) => varietal.rowIndex == rowIndex);
                    }
                    const columnValues = category.products[currentColumn].length > 0 ? category.products[currentColumn] : null;
                    let lastRowIndex = category.products[currentColumn][category.products[currentColumn].length - 1]?.rowIndex;
                    if (!columnValues) {
                        for (const key in category.products) {
                            if (key == 'price') {
                                category.products[key].push({
                                    'minPrice': null,
                                    'maxPrice': null,
                                    'rowIndex': rowIndex,
                                });
                            } else if (key == 'notes') {
                                category.products[key].push({
                                    'text': null,
                                    'rowIndex': rowIndex,
                                });
                            } else {
                                category.products[key].push({
                                    'id': null,
                                    'name': null,
                                    'rowIndex': rowIndex,
                                });
                            }
                        }
                        if (currentColumn == 'price') {
                            category.products[currentColumn] = [{
                                'minPrice': value.minPrice,
                                'maxPrice': value.maxPrice,
                                'rowIndex': rowIndex
                            }];
                        } else if (currentColumn == 'notes') {
                            category.products[currentColumn] = [{
                                'text': value.text,
                                'rowIndex': rowIndex
                            }];
                        } else if (currentColumn == 'varietal') {
                            const currentParentType = types.find((type) => type.id == value.category_type_id);
                            category.products[currentColumn] = [{
                                'id': value.id,
                                'name': value.name,
                                'rowIndex': rowIndex
                            }];
                            category.products['type'] = [{
                                'id': currentParentType.id,
                                'name': currentParentType.name,
                                'rowIndex': rowIndex
                            }]
                        } else {
                            category.products[currentColumn] = [{
                                'id': value.id,
                                'name': value.name,
                                'rowIndex': rowIndex
                            }];
                        }
                    } else {
                        if (rowIndex > lastRowIndex) {
                            for (const key in category.products) {
                                if (key == 'price') {
                                    category.products[key] = [...category.products[key], {
                                        'minPrice': null,
                                        'maxPrice': null,
                                        'rowIndex': rowIndex,
                                    }];
                                } else if (key == 'notes') {
                                    category.products[key] = [...category.products[key], {
                                        'text': null,
                                        'rowIndex': rowIndex,
                                    }];
                                } else {
                                    category.products[key] = [...category.products[key], {
                                        'id': null,
                                        'name': null,
                                        'rowIndex': rowIndex,
                                    }];
                                }
                            }
                            if (currentColumn == 'price') {
                                category.products[currentColumn] = [...columnValues, {
                                    'minPrice': value.minPrice,
                                    'maxPrice': value.maxPrice,
                                    'rowIndex': rowIndex
                                }];
                            } else if (currentColumn == 'notes') {
                                category.products[currentColumn] = [...columnValues, {
                                    'text': value.text,
                                    'rowIndex': rowIndex
                                }];

                            } else if (currentColumn == 'varietal') {
                                const currentType = category.products['type'].find((type) => type.rowIndex == rowIndex);
                                const selectedType = types.find((type) => type.id == value.category_type_id);
                                if (!currentType.id) {
                                    category.products['type'].map((row) => {
                                        if (row.rowIndex == rowIndex) {
                                            row.id = selectedType.id;
                                            row.name = selectedType.name;
                                        }
                                    });
                                }
                                category.products[currentColumn] = [...columnValues, {
                                    'id': value.id,
                                    'name': value.name,
                                    'rowIndex': rowIndex
                                }];
                            } else if (currentColumn == 'pour') {
                                category.products['type'].map((row) => {
                                    if (row.rowIndex == rowIndex) {
                                        row.id = firstRowTypeData?.id;
                                        row.name = firstRowTypeData?.name;
                                    }
                                });
                                category.products['varietal'].map((row) => {
                                    if (row.rowIndex == rowIndex) {
                                        row.id = firstRowVarietalData?.id;
                                        row.name = firstRowVarietalData?.name;
                                    }
                                });
                                category.products[currentColumn] = [...columnValues, {
                                    'id': value.id,
                                    'name': value.name,
                                    'rowIndex': rowIndex
                                }];
                            }
                            else {
                                category.products[currentColumn] = [...columnValues, {
                                    'id': value.id,
                                    'name': value.name,
                                    'rowIndex': rowIndex
                                }];
                            }
                        } else {
                            var isBroken = false;
                            category.products[currentColumn].map((row) => {
                                if (!isBroken) {
                                    if (row.rowIndex == rowIndex) {
                                        if (currentColumn == 'price') {
                                            row.minPrice = value.minPrice;
                                            row.maxPrice = value.maxPrice;
                                        } else if (currentColumn == 'notes') {
                                            row.text = value.text;
                                        }
                                        else if (currentColumn == 'varietal') {
                                            if (valueIndex == 0) {
                                                row.id = value.id;
                                                row.name = value.name;
                                            } else {
                                                lastRowIndex++;
                                                for (const key in category.products) {
                                                    if (key == 'price') {
                                                        category.products[key].push({
                                                            'minPrice': null,
                                                            'maxPrice': null,
                                                            'rowIndex': lastRowIndex,
                                                        });
                                                    } else if (key == 'notes') {
                                                        category.products[key].push({
                                                            'text': null,
                                                            'rowIndex': lastRowIndex,
                                                        });
                                                    } else if (key == 'type') {

                                                        category.products['type'].push({
                                                            'id': firstRowTypeData?.id,
                                                            'name': firstRowTypeData?.name,
                                                            'rowIndex': lastRowIndex,
                                                        });

                                                        category.products['varietal'].push({
                                                            'id': value?.id,
                                                            'name': value?.name,
                                                            'rowIndex': lastRowIndex,
                                                        });

                                                        category.products['pour'].push({
                                                            'id': null,
                                                            'name': null,
                                                            'rowIndex': lastRowIndex,
                                                        });

                                                    }
                                                }
                                                rowIndex = lastRowIndex - 1;
                                            }

                                        } else if (currentColumn == 'pour') {
                                            if (valueIndex == 0) {
                                                row.id = value.id;
                                                row.name = value.name;
                                            } else {
                                                lastRowIndex++;
                                                for (const key in category.products) {
                                                    if (key == 'price') {
                                                        category.products[key].push({
                                                            'minPrice': null,
                                                            'maxPrice': null,
                                                            'rowIndex': lastRowIndex,
                                                        });
                                                    } else if (key == 'notes') {
                                                        category.products[key].push({
                                                            'text': null,
                                                            'rowIndex': lastRowIndex,
                                                        });
                                                    } else if (key == 'type') {

                                                        category.products['type'].push({
                                                            'id': firstRowTypeData?.id,
                                                            'name': firstRowTypeData?.name,
                                                            'rowIndex': lastRowIndex,
                                                        });
                                                        category.products['varietal'].push({
                                                            'id': firstRowVarietalData?.id,
                                                            'name': firstRowVarietalData?.name,
                                                            'rowIndex': lastRowIndex,
                                                        });
                                                        category.products['pour'].push({
                                                            'id': value?.id,
                                                            'name': value?.name,
                                                            'rowIndex': lastRowIndex,
                                                        });
                                                    }
                                                }
                                                rowIndex = lastRowIndex - 1;
                                            }

                                        } else {
                                            row.id = value.id;
                                            row.name = value.name;
                                        }
                                        isBroken = true;
                                    }
                                }

                            });
                        }
                    }
                    rowIndex++

                });
            }
        });



        newProducts.map((category) => {
            category.products['price'].sort();
            return category
        })
        setProducts(newProducts);
        setSelectedValues([]);
        setCurrentColumn('');
        setModalType('');
        setCurrentCategory(null);
        setParentType(null);
        setVarietals([]);
        setTypes([]);
        setOtherData('');
        setOtherOption(false);
    }

    const productsCopy = JSON.parse(JSON.stringify(products));
    return (
        <div>
            {productsCopy && productsCopy.map((category, index) => {

                var arrayToSort = Object.values(category.products).reduce((prev, current) => {
                    return current.length > prev.length ? current : prev;
                }, []);

                const types = category.products.type;
                const pours = category.products.pour;
                const varietal = category.products.varietal;
                const price = category.products.price;
                const notes = category.products.notes;

                var biggestArray = arrayToSort;

                biggestArray.sort((a, b) => {
                    const typeA = types.find((typeValue) => typeValue.rowIndex === a.rowIndex);
                    const typeB = types.find((typeValue) => typeValue.rowIndex === b.rowIndex);

                    const nameA = typeA?.name?.toLowerCase();
                    const nameB = typeB?.name?.toLowerCase();

                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    return 0;
                });

                let maxRowIndex = null;
                for (const obj of types) {
                    if (maxRowIndex === null || obj.rowIndex > maxRowIndex) {
                        maxRowIndex = obj.rowIndex;
                    }
                }


                return (
                    <div key={`table-${category.category?.name}-${index}`}>
                        <strong className="text-muted">{category.category.name}</strong>
                        <table className="table overflow-hidden rounded-top-pill text-center mt-5 mb-13">
                            <thead className="bg-custom-secondary">
                                <tr className="text-white">
                                    <th>Type</th>
                                    <th>Varietal / Sub-Type</th>
                                    {category.category.name != 'Non Alc' &&
                                        <th>Placement</th>
                                    }
                                    <th>Unit Price</th>
                                    <th>Notes</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {biggestArray.length > 0 ? (biggestArray.map((row, index) => {
                                    const currentType = types.find((typeValue) => typeValue.rowIndex == row.rowIndex);
                                    const currentVarietal = varietal.find((varietalValue) => varietalValue.rowIndex == row.rowIndex);
                                    const varietalParentType = category.category?.types?.find((type) => type.id == currentType.id);
                                    const currentPour = pours.find((pourValue) => pourValue.rowIndex == row.rowIndex);
                                    const currentPrice = price.find((priceValue) => priceValue.rowIndex == row.rowIndex);
                                    const currentNote = notes.find((noteValue) => noteValue.rowIndex == row.rowIndex);

                                    {
                                        return (
                                            index != (biggestArray.length - 1) ? (
                                                <tr key={`row-${category.category?.name}-${row.rowIndex}-${index}`}>
                                                    {currentType.id ? (<td>
                                                        <label className="d-flex align-items-center justify-content-center gap-2 cursor-pointer">
                                                            <span>{currentType.name}</span>
                                                        </label>
                                                    </td>
                                                    ) : (
                                                        <td>
                                                            <label className="d-flex align-items-center justify-content-center gap-2 cursor-pointer" onClick={() => {
                                                                handleModalType('type');
                                                                setCurrentCategory(category.category);
                                                                setCurrentIndex(row.rowIndex);
                                                            }}>
                                                                <i className="fa fa-plus"></i>
                                                                <span>Type</span>
                                                            </label>
                                                        </td>
                                                    )}


                                                    {currentVarietal?.id ? (<td>
                                                        <label className="d-flex align-items-center justify-content-center gap-2 cursor-pointer">
                                                            <span>{currentVarietal.name}</span>
                                                        </label>
                                                    </td>
                                                    ) : (
                                                        (varietalParentType?.sub_type && varietalParentType.sub_type?.length > 0 && currentType?.id) ? (<td>
                                                            <label className="d-flex align-items-center justify-content-center gap-2 cursor-pointer" onClick={() => {
                                                                handleModalType('varietal');
                                                                setCurrentCategory(category.category);
                                                                setCurrentIndex(row.rowIndex);
                                                                if (currentType) {
                                                                    setParentType(currentType)
                                                                }
                                                            }}>
                                                                <i className="fa fa-plus"></i>
                                                                <span>Varietal</span>
                                                            </label>
                                                        </td>) : (
                                                            <td>
                                                                <span>-</span>
                                                            </td>
                                                        )
                                                    )}

                                                    {category.category.name != 'Non Alc' &&
                                                        <>
                                                            {
                                                                currentPour?.id ? (<td>
                                                                    <label className="d-flex align-items-center justify-content-center gap-2 cursor-pointer">
                                                                        <span>{currentPour.name}</span>
                                                                    </label>
                                                                </td>
                                                                ) : (
                                                                    <td>
                                                                        <label className="d-flex align-items-center justify-content-center gap-2 cursor-pointer" onClick={() => {
                                                                            handleModalType('pour');
                                                                            setCurrentCategory(category.category);
                                                                            setCurrentIndex(row.rowIndex);
                                                                        }}>
                                                                            <i className="fa fa-plus"></i>
                                                                            <span>Placement</span>
                                                                        </label>
                                                                    </td>
                                                                )
                                                            }
                                                        </>
                                                    }

                                                    {(currentPrice?.minPrice && currentPrice?.maxPrice) ? (<td>
                                                        <label onClick={() => {
                                                            handleModalType('price');
                                                            setCurrentCategory(category.category);
                                                            setCurrentIndex(row.rowIndex);
                                                        }} className="d-flex align-items-center justify-content-center gap-2 cursor-pointer">
                                                            <span>{'$' + currentPrice.minPrice + ' - $' + currentPrice.maxPrice}</span>
                                                        </label>
                                                    </td>
                                                    ) : (
                                                        <td>
                                                            <label className="d-flex align-items-center justify-content-center gap-2 cursor-pointer" onClick={() => {
                                                                handleModalType('price');
                                                                setCurrentCategory(category.category);
                                                                setCurrentIndex(row.rowIndex);
                                                            }}>
                                                                <i className="fa fa-plus"></i>
                                                                <span>Price</span>
                                                            </label>
                                                        </td>
                                                    )}
                                                    {(currentNote?.text) ? (<td className="d-flex align-items-center justify-content-center">
                                                        <label data-toggle="modal" data-target="#editModal" onClick={() => {
                                                            setViewNote(currentNote.text);
                                                            setCurrentColumn('notes');
                                                            setCurrentCategory(category.category);
                                                            setCurrentIndex(row.rowIndex);
                                                        }} className="d-flex align-items-center justify-content-center gap-2 cursor-pointer btn-pill w-fit btn-sm btn btn-outline-custom-primary">
                                                            <span className="text-truncate">
                                                                Manage Notes
                                                            </span>
                                                        </label>
                                                    </td>
                                                    ) : (
                                                        <td>
                                                            <label className="d-flex align-items-center justify-content-center gap-2 cursor-pointer" onClick={() => {
                                                                handleModalType('notes');
                                                                setCurrentCategory(category.category);
                                                                setCurrentIndex(row.rowIndex);
                                                            }}>
                                                                <i className="fa fa-plus"></i>
                                                                <span>Notes</span>
                                                            </label>
                                                        </td>
                                                    )}
                                                    <td>
                                                        <label className="d-flex align-items-center justify-content-center cursor-pointer" onClick={() => {
                                                            handleDeleteRow(row.rowIndex, category.category)
                                                        }}>
                                                            <i className="fa fa-trash text-danger"></i>
                                                        </label>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <>
                                                    <tr key={`row-${category.category?.name}-${row.rowIndex}-${index}`}>
                                                        {currentType.id ? (<td>
                                                            <label className="d-flex align-items-center justify-content-center gap-2 cursor-pointer">
                                                                <span>{currentType.name}</span>
                                                            </label>
                                                        </td>
                                                        ) : (
                                                            <td>
                                                                <label className="d-flex align-items-center justify-content-center gap-2 cursor-pointer" onClick={() => {
                                                                    handleModalType('type');
                                                                    setCurrentCategory(category.category);
                                                                    setCurrentIndex(row.rowIndex);
                                                                }}>
                                                                    <i className="fa fa-plus"></i>
                                                                    <span>Type</span>
                                                                </label>
                                                            </td>
                                                        )}


                                                        {currentVarietal?.id ? (<td>
                                                            <label className="d-flex align-items-center justify-content-center gap-2 cursor-pointer">
                                                                <span>{currentVarietal.name}</span>
                                                            </label>
                                                        </td>
                                                        ) : (
                                                            ((varietalParentType?.sub_type) && (varietalParentType.sub_type?.length > 0) && (currentType?.id)) ? (<td>
                                                                <label className="d-flex align-items-center justify-content-center gap-2 cursor-pointer" onClick={() => {
                                                                    handleModalType('varietal');
                                                                    setCurrentCategory(category.category);
                                                                    setCurrentIndex(row.rowIndex);
                                                                    if (currentType) {
                                                                        setParentType(currentType)
                                                                    }
                                                                }}>
                                                                    <i className="fa fa-plus"></i>
                                                                    <span>Varietal</span>
                                                                </label>
                                                            </td>) : (
                                                                <td>
                                                                    <span>-</span>
                                                                </td>
                                                            )
                                                        )}

                                                        {category.category.name != 'Non Alc' &&
                                                            <>
                                                                {
                                                                    currentPour?.id ? (<td>
                                                                        <label className="d-flex align-items-center justify-content-center gap-2 cursor-pointer">
                                                                            <span>{currentPour.name}</span>
                                                                        </label>
                                                                    </td>
                                                                    ) : (
                                                                        <td>
                                                                            <label className="d-flex align-items-center justify-content-center gap-2 cursor-pointer" onClick={() => {
                                                                                handleModalType('pour');
                                                                                setCurrentCategory(category.category);
                                                                                setCurrentIndex(row.rowIndex);
                                                                            }}>
                                                                                <i className="fa fa-plus"></i>
                                                                                <span>Placement</span>
                                                                            </label>
                                                                        </td>
                                                                    )
                                                                }
                                                            </>
                                                        }

                                                        {(currentPrice?.minPrice && currentPrice?.maxPrice) ? (<td>
                                                            <label className="d-flex align-items-center justify-content-center gap-2 cursor-pointer">
                                                                <span>{'$' + currentPrice.minPrice + ' - $' + currentPrice.maxPrice}</span>
                                                            </label>
                                                        </td>
                                                        ) : (
                                                            <td>
                                                                <label className="d-flex align-items-center justify-content-center gap-2 cursor-pointer" onClick={() => {
                                                                    handleModalType('price');
                                                                    setCurrentCategory(category.category);
                                                                    setCurrentIndex(row.rowIndex);
                                                                }}>
                                                                    <i className="fa fa-plus"></i>
                                                                    <span>Price</span>
                                                                </label>
                                                            </td>
                                                        )}
                                                        {(currentNote?.text) ? (<td className="d-flex align-items-center justify-content-center">
                                                            <label data-toggle="modal" data-target="#editModal" onClick={() => {
                                                                setViewNote(currentNote.text);
                                                                setCurrentColumn('notes');
                                                                setCurrentCategory(category.category);
                                                                setCurrentIndex(row.rowIndex);

                                                            }} className="d-flex align-items-center justify-content-center gap-2 cursor-pointer btn-pill w-fit btn-sm btn btn-outline-custom-primary">
                                                                <span className="text-truncate">
                                                                    Manage Notes
                                                                </span>
                                                            </label>
                                                        </td>
                                                        ) : (
                                                            <td>
                                                                <label className="d-flex align-items-center justify-content-center gap-2 cursor-pointer" onClick={() => {
                                                                    handleModalType('notes');
                                                                    setCurrentCategory(category.category);
                                                                    setCurrentIndex(row.rowIndex);
                                                                }}>
                                                                    <i className="fa fa-plus"></i>
                                                                    <span>Notes</span>
                                                                </label>
                                                            </td>
                                                        )}
                                                        <td>
                                                            <label className="d-flex align-items-center justify-content-center cursor-pointer" onClick={() => {
                                                                handleDeleteRow(row.rowIndex, category.category)
                                                            }}>
                                                                <i className="fa fa-trash text-danger"></i>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <label className="d-flex align-items-center justify-content-center gap-2 cursor-pointer" onClick={() => {
                                                                handleModalType('type');
                                                                setCurrentCategory(category.category);
                                                                setCurrentIndex(maxRowIndex + 1);
                                                            }}>
                                                                <i className="fa fa-plus"></i>
                                                                <span>Type</span>
                                                            </label>
                                                        </td>

                                                        <td><span>-</span></td>
                                                        {category.category.name != 'Non Alc' &&
                                                            <td><span>-</span></td>
                                                        }
                                                        <td><span>-</span></td>
                                                        <td><span>-</span></td>
                                                        <td>
                                                        </td>
                                                    </tr >
                                                </>
                                            )
                                        )
                                    }
                                })
                                ) : (<tr>
                                    <td>
                                        <label className="d-flex align-items-center justify-content-center gap-2 cursor-pointer" onClick={() => {
                                            handleModalType('type');
                                            setCurrentCategory(category.category);
                                            setCurrentIndex(0);
                                        }}>
                                            <i className="fa fa-plus"></i>
                                            <span>Type</span>
                                        </label>
                                    </td>
                                    <td>
                                        <span> - </span>
                                    </td>
                                    {category.category.name != 'Non Alc' &&
                                        <td>
                                            <span> - </span>
                                        </td>
                                    }
                                    <td>
                                        <span> - </span>

                                    </td>
                                    <td>
                                        <span> - </span>
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </table>

                    </div>
                );
            })}
            <div>
                <a className="d-flex align-items-center gap-2 mb-5 cursor-pointer" onClick={() => {
                    setShowCategories(!showCatgeories);
                }}>
                    Add Category
                    <i className="fa fa-plus"></i>
                </a>
                {showCatgeories &&
                    <div className="d-flex align-items-center gap-2">
                        {categories.map((category, index) => {
                            return (
                                <label key={`category-checkbox-${index}`}>
                                    <input type="checkbox" className="mr-2 my-0" onChange={(e) => {
                                        if (e.target.checked) {
                                            props.setSelectedCategories([...selectedCategories, category])
                                            if (tempProducts.some((cat) => cat.category.id == category.id)) {
                                                setProducts([...products, tempProducts.find((cat) => cat.category.id == category.id)]);
                                            }
                                        } else {
                                            setTempProducts([...tempProducts, products.find((cat) => cat.category.id == category.id)]);
                                            props.setSelectedCategories(selectedCategories.filter((selectedCategory) => selectedCategory.id != category.id))
                                        }
                                    }}
                                        checked={selectedCategories.some((selectedCategory) => category.id == selectedCategory.id)}
                                    />
                                    {category.name}
                                </label>
                            );
                        })}
                    </div>
                }
            </div>
            <div className="d-flex align-items-center gap-3 py-5 border-top">
                <button type="button"
                    onClick={() => {
                        props.handlePrevious();
                        setSelectedValues([]);
                    }}
                    className="rounded-circle btn btn-outline-custom-primary p-4 d-flex align-items-center justify-content-center">
                    <i className="la la-arrow-left p-0"></i>
                </button>
                <button
                    id="save-draft-button"
                    disabled={props.isLoading}
                    type="submit" className="btn btn-outline-custom-primary btn-pill d-flex align-items-cetner justify-content-center gap-1"
                    onClick={() => {
                        props.setSelectedStatus('draft')
                    }}>
                    {props.isLoading && <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    }
                    Save Draft
                </button>
                <button type="button" className="btn btn-outline-custom-primary btn-pill" onClick={props.handlePreview}>Preview</button>
                <button disabled={props.isLoading} onClick={() => {
                    props.setSelectedStatus('published')
                }} type="submit" className="btn btn-custom-primary btn-pill d-flex align-items-center justify-content-center">
                    {props.isLoading && <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    }
                    Publish</button>
            </div>


            {/* Type Modal */}
            <div className="modal fade" id="selectTypeModal" tabIndex="-1" role="dialog" aria-labelledby="selectTypeModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content px-4">
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold" id="selectTypeModalLabel">
                                {modalType == 'notes' && <>Manage Notes</>}
                                {modalType == 'price' && <>Manage Price</>}
                                {modalType != 'notes' && modalType != 'price' && <>Choose a Type <span className="text-muted">(One or More)</span></>}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times fa-xl"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            {modalType == 'type' &&
                                <div className="types d-flex flex-column gap-2">
                                    {types && types.map((type, index) => {
                                        return (
                                            <label key={index} className="d-flex align-items-center gap-1">
                                                <input type="checkbox" defaultValue={type.id} onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setSelectedValues([...selectedValues, type]);
                                                    } else {
                                                        setSelectedValues(selectedValues.filter((selectedValue) => selectedValue.id != type.id));
                                                    }
                                                }} />
                                                <span>{type.name}</span>
                                            </label>
                                        );
                                    })}
                                    <label className="d-flex align-items-center gap-1" >
                                        <input type="checkbox"
                                            checked={otherOption}
                                            onChange={(e) => {
                                                e.target.checked ? setOtherOption(true) : setOtherOption(false);
                                            }} />
                                        <span>other</span>
                                    </label>
                                    {otherOption &&
                                        <label className="fs-3 text-muted" name="other_type">
                                            Please Enter Your Option
                                            <input type="text" className="form-control rounded-pill" onChange={(e) => setOtherData(e.target.value)} />
                                        </label>
                                    }
                                </div>
                            }{modalType == 'varietal' &&
                                <div className="types d-flex flex-column gap-2">
                                    {varietals && varietals.map((varietal, index) => {
                                        return (
                                            <label key={index} className="d-flex align-items-center gap-1">
                                                <input type="checkbox" defaultValue={varietal?.id} onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setSelectedValues([...selectedValues, varietal]);
                                                    } else {
                                                        setSelectedValues(selectedValues.filter((selectedValue) => selectedValue.id != varietal.id));
                                                    }
                                                }} />
                                                <span>{varietal?.name}</span>
                                            </label>
                                        );
                                    })}
                                    <label className="d-flex align-items-center gap-1" >
                                        <input type="checkbox"
                                            checked={otherOption}
                                            onChange={(e) => {
                                                e.target.checked ? setOtherOption(true) : setOtherOption(false);
                                            }} />
                                        <span>other</span>
                                    </label>
                                    {otherOption &&
                                        <label className="fs-3 text-muted" name="other_type">
                                            Please Enter Your Option
                                            <input type="text" className="form-control rounded-pill" onChange={(e) => setOtherData(e.target.value)} />
                                        </label>
                                    }
                                </div>
                            }{modalType == 'pour' &&
                                <div className="types d-flex flex-column gap-2">
                                    {pours && pours.length > 0 && pours.filter((pour) => {
                                        return pour.category_id == currentCategory.id;
                                    })?.map((pour, index) => {
                                        return (
                                            <label key={index} className="d-flex align-items-center gap-1">
                                                <input type="checkbox" defaultValue={pour?.id} onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setSelectedValues([...selectedValues, pour]);
                                                    } else {
                                                        setSelectedValues(selectedValues.filter((selectedValue) => selectedValue.id != pour.id));
                                                    }
                                                }} />
                                                <span>{pour?.name}</span>
                                            </label>
                                        );
                                    })}
                                    <label className="d-flex align-items-center gap-1" >
                                        <input type="checkbox"
                                            checked={otherOption}
                                            onChange={(e) => {
                                                e.target.checked ? setOtherOption(true) : setOtherOption(false);
                                            }} />
                                        <span>other</span>
                                    </label>
                                    {otherOption &&
                                        <label className="fs-3 text-muted" name="other_type">
                                            Please Enter Your Option
                                            <input type="text"
                                                className="form-control rounded-pill" onChange={(e) => setOtherData(e.target.value)} />
                                        </label>
                                    }
                                </div>
                            }{modalType == 'notes' &&
                                <div className="types d-flex flex-column gap-2">
                                    <label>
                                        Notes
                                        <textarea className="form-control rounded-xl" id="notesInput" onChange={(e) => {
                                            setSelectedValues([{ 'text': e.target.value }])
                                        }} placeholder="Add Product Notes" rows="5"></textarea>
                                    </label>
                                </div>
                            }{
                                modalType === 'price' &&
                                <div className="types d-flex justify-content-between">
                                    <label>
                                        Minimum Price
                                        <input type="number" id="minPrice"
                                            onKeyDown={(e) => {
                                                if (e.which === 38 || e.which === 40) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={(e) => {
                                                setSelectedValues([{ 'minPrice': e.target.value, 'maxPrice': document.getElementById('maxPrice').value }]);

                                            }} className="form-control rounded-pill" />
                                    </label>
                                    <label>
                                        Maximum Price
                                        <input type="number"
                                            onKeyDown={(e) => {
                                                if (e.which === 38 || e.which === 40) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            id="maxPrice" onChange={(e) => {
                                                setSelectedValues([{ 'minPrice': document.getElementById('minPrice').value, 'maxPrice': e.target.value }]);
                                            }} className="form-control rounded-pill" />
                                    </label>
                                </div>
                            }

                        </div>
                        <div className="d-flex flex-column my-5">
                            <button
                                disabled={document.getElementById('minPrice')?.value == '' || document.getElementById('maxPrice')?.value == ''} type="button" className="btn btn-custom-primary btn-pill mb-5" data-dismiss="modal" data-target="#selectTypeModal" onClick={() => {
                                    if (modalType == 'price') {
                                        handleAddValues();
                                        document.getElementById('maxPrice').value = '';
                                        document.getElementById('minPrice').value = '';
                                    } else {
                                        handleAddValues();
                                    }
                                }}>
                                Apply
                            </button>
                            <button type="button" className="btn btn-outline-custom-primary btn-pill" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>


            {/*Edit Value Modal */}
            <div className="modal fade" id="editModal" tabIndex="-1" role="dialog" aria-labelledby="editModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content px-4">
                        <div className="modal-header border-bottom-0">
                            <h5 className="modal-title fw-bold" id="viewNoteModalLabel">
                                {currentColumn == 'price' && <>Manage Price</>}
                                {currentColumn == 'notes' && <>Manage Notes</>}
                            </h5>
                            <button type="button" className="close py-3" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times fa-xl"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            {currentColumn == 'notes' &&
                                <div className="types d-flex flex-column gap-2">
                                    <label>
                                        Notes
                                        <textarea className="form-control rounded-xl" id="notesInputEdit" placeholder="Add Product Notes"
                                            onChange={(e) => {
                                                setViewNote(e.target.value);
                                            }}
                                            value={viewNote} rows="5">{viewNote}</textarea>
                                    </label>
                                </div>
                            }{
                                currentColumn === 'price' &&
                                <div className="types d-flex justify-content-between">
                                    <label>
                                        Minimum Price
                                        <input type="number"
                                            onKeyDown={(e) => {
                                                if (e.which === 38 || e.which === 40) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            id="minPrice" onChange={(e) => {
                                                setSelectedValues([{ 'minPrice': e.target.value, 'maxPrice': document.getElementById('maxPrice').value }]);

                                            }} className="form-control rounded-pill" />
                                    </label>
                                    <label>
                                        Maximum Price
                                        <input type="number" id="maxPrice"
                                            onKeyDown={(e) => {
                                                if (e.which === 38 || e.which === 40) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={(e) => {
                                                setSelectedValues([{ 'minPrice': document.getElementById('minPrice').value, 'maxPrice': e.target.value }]);
                                            }} className="form-control rounded-pill" />
                                    </label>
                                </div>
                            }
                        </div>
                        <div className="modal-footer">
                            <button
                                disabled={document.getElementById('minPrice')?.value == '' || document.getElementById('maxPrice')?.value == ''} type="button" className="btn btn-custom-primary btn-pill mb-5" data-dismiss="modal" data-target="#selectTypeModal" onClick={() => {
                                    if (currentColumn == 'price') {
                                        const min_price = document.getElementById('maxPrice').value = '';
                                        const max_price = document.getElementById('minPrice').value = '';
                                        handleEditPrice(min_price, max_price);

                                    } else {
                                        const notes = document.getElementById('notesInputEdit').value;
                                        handleEditNotes(notes);
                                        document.getElementById('notesInputEdit').value == '';
                                    }
                                }}>
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
});

export default AddProductRequestToRFP;


