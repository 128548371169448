import { forwardRef } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchProducts,
    selectDraft,
    selectProductsStatus,
    deleteProduct
} from "src/plugins/redux/reducers/ProductsReducer";
import {
    setHeaderTitle,
    setHeaderActionBtns,
    setHeaderArrowBack,
    setShowSearch,
    setTitleLink,
    setShowPremiumHeader
} from "src/plugins/redux/reducers/HeaderReducer";
import ProductsFilter from "./ProductsFilter";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import toast from "react-hot-toast";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { currentUserSelector } from "src/plugins/redux/reducers/AuthReducer";
import SupplierUnlockPremium from "src/componenets/SupplierUnlockPremium";
import SupplierDummyProducts from "src/componenets/SupplierDummyProducts";


const ProductsDraft = forwardRef((props: any, ref: any): JSX.Element => {

    const location = useLocation();
    const dispatch = useDispatch();
    const fethcedProducts = useSelector(selectDraft);
    const [products, setProducts] = useState([]);
    const status = useSelector(selectProductsStatus);
    const [filters, setFilters] = useState({});
    const [sortBy, setSortBy] = useState();
    const [sortType, setSortType] = useState("DESC");
    const [selectedProduct, setSelectedProduct] = useState<any>(null);
    const [showDeletePopUp, setShowDeletePopUp] = useState<boolean>(false);
    const searchValue = useSelector((state) => state.header.search);
    const currentUser = useSelector(currentUserSelector);

    useEffect(() => {
        dispatch(setHeaderTitle("Drafts"));
        dispatch(
            setHeaderActionBtns([
                {
                    link: "/products/create",
                    text: "Add Products",
                },
                {
                    link: "/rfps/find",
                    text: "My RFPs",
                },
            ])
        );
        dispatch(setHeaderArrowBack(false));
        dispatch(setShowSearch(true));
        if (currentUser?.is_premium || currentUser?.is_trial) {
            dispatch(fetchProducts());
          }
    }, [dispatch]);

    useEffect(() => {
        dispatch(setShowPremiumHeader(true));
    }, [location, dispatch]);


    useEffect(() => {
        let filteredProducts = [...fethcedProducts];

        for (const key in filters) {
            if (key == 'category_id' && filters[key]) {
                filteredProducts = filteredProducts?.filter((product) => product.catinfo?.id == filters[key]);
            } if (key == 'category_type_id' && filters[key]) {
                filteredProducts = filteredProducts?.filter((product) => product.categoryTypeInfo?.id == filters[key]);
            } if (key == 'category_sub_type_id' && filters[key]) {
                filteredProducts = filteredProducts?.filter((product) => product.categorySubTypeInfo?.id == filters[key]);
            }
        }

        if (searchValue && searchValue != '') {
            filteredProducts = filteredProducts?.filter((product) => {
                const productName = product.name.toLowerCase();
                const category = product.catinfo?.name.toLowerCase();
                const categoryType = product.categoryTypeInfo?.name.toLowerCase();
                const subType = product.categorySubTypeInfo?.name.toLowerCase();
                const producer = product.producerInfo?.name.toLowerCase();

                const searchLowercase = searchValue.toLowerCase();

                return (
                    productName?.includes(searchLowercase) ||
                    category?.includes(searchLowercase) ||
                    categoryType?.includes(searchLowercase) ||
                    subType?.includes(searchLowercase) ||
                    producer?.includes(searchLowercase)
                );
            });
        }
        setProducts(filteredProducts);
    }, [filters, searchValue]);


    useEffect(() => {
        setProducts(fethcedProducts)
    }, [fethcedProducts]);

    const handleTypeSort = (columnName: string) => {
        const sortedProducts = [...products].sort((a, b) => {
            let comparison = 0;

            if (columnName === "name") {
                comparison = compareString(a.name, b.name);
            } else if (columnName === "category") {
                comparison = compareString(a.catinfo?.name, b.catinfo?.name);
            } else if (columnName === "type") {
                comparison = compareString(a.categoryTypeInfo?.name, b.categoryTypeInfo?.name);
            } else if (columnName === "subType") {
                comparison = compareString(a.categorySubTypeInfo?.name, b.categorySubTypeInfo?.name);
            } else if (columnName === "producer") {
                comparison = compareString(a.producerInfo?.name, b.producerInfo?.name);
            }

            return sortType === "DESC" ? comparison : -comparison;
        });

        setSortBy(columnName);
        setSortType((current) => (current === "ASC" ? "DESC" : "ASC"));
        setProducts(sortedProducts);
    };

    const compareString = (a: string | undefined, b: string | undefined) => {
        if (a === null || a === undefined || a === "") {
            return b === null || b === undefined || b === "" ? 0 : 1;
        }
        if (b === null || b === undefined || b === "") {
            return -1;
        }
        return a.localeCompare(b);
    };

    const handleCloseDeletePopup = () => {
        setShowDeletePopUp(false);
    }

    const handleDelteProduct = async () => {
        const response = await dispatch(deleteProduct({ 'id': selectedProduct, 'trash': true }));
        if (response?.payload?.success) {
            toast.success('Product Deleted Successfully');
            dispatch(fetchProducts());
        } else {
            toast.error('Error Deleting Product Please Try again')
        }
        setShowDeletePopUp(false);
    }

    return (
        <div className="row">
            {(!currentUser?.is_premium && !currentUser?.is_trial) &&
                <SupplierUnlockPremium />
            }
            <ProductsFilter
                type={filters?.category_id}
                subType={filters?.category_type_id}
                filters={filters}
                setFilters={setFilters}
                setSortBy={setSortBy}
            />
            <div className="col-12 col-md-12">
                <div className="card mb-4 rounded-pill bg-custom-secondary">
                    <div className="row p-5">
                        <div className="col-2 col-md-2 text-center text-white"
                            onClick={() => handleTypeSort("name")}
                            style={{
                                cursor: "pointer"
                            }}
                        >
                            Product Name
                            {sortBy == 'name' && (
                                sortType == 'ASC' ? (
                                    <ArrowDropDownIcon />
                                ) : (
                                    <ArrowDropUpIcon />
                                ))}
                        </div>
                        <div className="col-2 col-md-2 text-center text-white cursor-pointer" onClick={() => handleTypeSort("producer")}>
                            Producer
                            {sortBy == 'producer' && (
                                sortType == 'ASC' ? (
                                    <ArrowDropDownIcon />
                                ) : (
                                    <ArrowDropUpIcon />
                                ))}
                        </div>
                        <div className="col-2 col-md-2 text-center text-white cursor-pointer" onClick={() => handleTypeSort("category")}>
                            Category
                            {sortBy == 'category' && (
                                sortType == 'ASC' ? (
                                    <ArrowDropDownIcon />
                                ) : (
                                    <ArrowDropUpIcon />
                                ))}
                        </div>
                        <div className="col-2 col-md-2 text-center text-white"
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => handleTypeSort("type")}
                        >
                            Type
                            {sortBy == 'type' && (
                                sortType == 'ASC' ? (
                                    <ArrowDropDownIcon />
                                ) : (
                                    <ArrowDropUpIcon />
                                ))}
                        </div>
                        <div className="col-2 col-md-2 text-center text-white"
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => handleTypeSort("subType")}>
                            Varietal / Sub-Type
                            {sortBy == 'subType' && (
                                sortType == 'ASC' ? (
                                    <ArrowDropDownIcon />
                                ) : (
                                    <ArrowDropUpIcon />
                                ))}
                        </div>
                        <div className="col-2 col-md-2 text-center text-white">Actions</div>
                    </div>
                </div>

                {products?.length == 0 && status == 'idle' && currentUser?.is_premium && (
                    <div
                        className="d-flex flex-column gap-2 align-items-center justify-content-center"
                        style={{ minHeight: "50vh" }}
                    >
                        <strong className="text-center fs-2 text-muted">
                            Since you have just registered, you need to add your program(s) so
                            that you can get started.
                        </strong>
                        <Link
                            className="btn btn-custom-primary btn-pill"
                            to={"/products/crerate"}
                        >
                            Add New Product
                        </Link>
                    </div>
                )}

{!currentUser?.is_premium && !currentUser?.is_trial && <SupplierDummyProducts />}

                {products?.length > 0 && products.map((product: any, key: any) => (
                    <div
                        key={key}
                        className="card mb-4 bg-white border border-secondary rounded-lg"
                    >
                        <div className="row p-5 align-items-center text-custom-primary">
                            <div className="col-2 col-md-2 text-center">{product.name}</div>
                            <div className="col-2 col-md-2 text-center">
                                {product.producerInfo?.name}
                            </div>
                            <div className="col-2 col-md-2 text-center">
                                {product.catinfo?.name ?? "Category"}
                            </div>
                            {product.categoryTypeInfo?.name ?
                                <div className="col-2 col-md-2 text-center">
                                    {product.categoryTypeInfo?.name}
                                </div>
                                :
                                <div className="col-2 col-md-2 text-center">
                                    -
                                </div>
                            }
                            {product.categorySubTypeInfo?.name ?
                                <div className="col-2 col-md-2 text-center">
                                    {product.categorySubTypeInfo?.name}
                                </div>
                                :
                                <div className="col-2 col-md-2 text-center">
                                    -
                                </div>
                            }
                            <div className="col-2 col-md-2 text-center d-flex align-items-center justify-content-center">
                                <Link
                                    className="mr-6"
                                    to={`/products/${product.id}`}
                                    state={{ product: product }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <g clipPath="url(#clip0_4_3167)">
                                            <path
                                                d="M12 6C15.79 6 19.17 8.13 20.82 11.5C19.17 14.87 15.79 17 12 17C8.21 17 4.83 14.87 3.18 11.5C4.83 8.13 8.21 6 12 6ZM12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 9C13.38 9 14.5 10.12 14.5 11.5C14.5 12.88 13.38 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 10.12 10.62 9 12 9ZM12 7C9.52 7 7.5 9.02 7.5 11.5C7.5 13.98 9.52 16 12 16C14.48 16 16.5 13.98 16.5 11.5C16.5 9.02 14.48 7 12 7Z"
                                                fill="#003ACE"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_4_3167">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </Link>
                                <Link to={`/products/${product.id}/edit`} className="mr-6">
                                    <i className="fa fa-edit text-primary-blue"></i>
                                </Link>
                                <span className="cursor-pointer" onClick={() => {
                                    setSelectedProduct(product?.id);
                                    setShowDeletePopUp(true);
                                }}>
                                    <i className="fa fa-times text-primary-blue"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
                {status === "loading" && (
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ minHeight: "50vh" }}
                    >
                        <div className="spinner-border text-custom-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                {status === "error" && (
                    <div
                        className="d-flex flex-column gap-2 align-items-center justify-content-center"
                        style={{ minHeight: "50vh" }}
                    >
                        <strong className="text-center fs-1 text-custom-primary">
                            Error occurred while fetching items.
                        </strong>
                        <button
                            className="btn btn-custom-primary btn-pill"
                            onClick={() => dispatch(fetchProducts() as any)}
                        >
                            <i className="fa fa-redo-alt" />
                            Retry
                        </button>
                    </div>
                )}
            </div>
            <Dialog onClose={handleCloseDeletePopup} open={showDeletePopUp} className="p-4">
                <DialogTitle>Are you sure you want to delete this product?</DialogTitle>
                <div className="d-flex align-items-center justify-content-center gap-3 mb-3">
                    <button className="btn btn-outline-custom-primary btn-pill" onClick={handleCloseDeletePopup}>Close</button>
                    <button className="btn btn-danger btn-pill" onClick={handleDelteProduct}>Delete</button>
                </div>
            </Dialog>
        </div >
    );
});

export default ProductsDraft;
