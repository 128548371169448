import axios from 'plugins/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from "js-cookie";

interface Product {
    id: number;
    name: string;
}

interface ProductsState {
    totalProducts: number;
    products: Product[];
    trashProducts: Product[];
    draftProducts: Product[];
    submissions: Product[];
    draftSubmissions: Product[];
    trashSubmissions: Product[];
    currentProduct: {};
    status: 'idle' | 'loading' | 'error';
    error: string | null;
}

const initialState: ProductsState = {
    totalProducts: 0,
    products: [],
    draftProducts: [],
    trashProducts: [],
    submissions: [],
    draftSubmissions: [],
    trashSubmissions: [],
    currentProduct: {},
    status: 'idle',
    error: ''
};

export const fetchProducts = createAsyncThunk('products/fetchProducts', async (filterBy: any = {}) => {
    const params = {
        'filterBy': JSON.stringify(filterBy.filters),
        'query': filterBy.query,
        'sortBy': filterBy.sortBy,
        'sortType': filterBy.sortType
    };
    console.log(params);
    const response = await axios.get('/products', { params });

    return response.data;
});

export const addProduct = createAsyncThunk('products/addProduct', async (product: any, thunkAPI) => {

    try {
        const formData = new FormData();
        for (const key in product) {
            if (key == 'statesPricing') {
                const statesPricing = product[key];
                for (const priceKey in product[key]) {
                    formData.append('statesPricing[]', statesPricing[priceKey]);
                }
            } else {
                formData.append(key, product[key]);
            }
        }

        const response = await axios.post('/products/create', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const deleteProduct = createAsyncThunk('products/deleteProduct', async (data: any) => {

    const params: any = {};
    if (data?.trash) {
        params.trash = true;
    }
    const response = await axios.delete(`/products/${data?.id}/delete`, { params });

    return response.data;
});


export const updateProduct = createAsyncThunk('products/updateProduct', async (product: any) => {

    try {
        const formData = new FormData();
        formData.append('product', JSON.stringify(product));
        for (const key in product) {
            formData.append(key, product[key]);
        }
        const response = await axios.put(`/products/${product.id}/update`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }

});


export const restoreProduct = createAsyncThunk('products/restoreProduct', async (id: any) => {
    try {
        const formData = new FormData();
        const response = await axios.put(`/products/${id}/restore`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }

});


export const addPriceByState = createAsyncThunk('products/addPriceByState', async (data: any) => {

    const formData = new FormData();
    for (const key in data.statesPricing) {
        formData.append('products_prices[]', JSON.stringify(data.statesPricing[key]));
    }
    formData.append('product_id', data?.product_id);
    const response = await axios.post(`/products_prices/create`, formData);
    console.log(response);
    return response.data;
});

export const addProductsBulk = createAsyncThunk('products/addProductsBulk', async (products: any) => {
    const formData = new FormData();
    console.log(products);
    for (const key in products) {
        formData.append(`data[]`, products[key]);
    }
    const response = await axios.post('/products/bulk', formData, { headers: { 'Content-Type': 'multipart/form-data', }, });

    return response.data;
});

export const fetchProduct = createAsyncThunk('products/fetchProduct', async (id) => {
    const response = await axios.get(`/products/${id}`);
    return response.data;
});

export const fetchSubmissions = createAsyncThunk('products/fetchSubmissions', async () => {
    const response = await axios.get(`/user_submissions`);
    return response.data;
});

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProducts.pending, (state, action) => {
            state.status = 'loading';
        });
        builder.addCase(fetchProducts.fulfilled, (state, action) => {
            state.status = 'idle';
            const products = action.payload?.success
            state.totalProducts = products?.length;
            state.products = products?.filter((product: any) => product.status == 'active');
            state.trashProducts = products?.filter((product: any) => product.status == 'trash');
            state.draftProducts = products?.filter((product: any) => product.status == 'draft');
        });
        builder.addCase(fetchProducts.rejected, (state, action) => {
            state.status = 'error';
        });

        builder.addCase(fetchSubmissions.pending, (state, action) => {
            state.status = 'loading';
        });
        builder.addCase(fetchSubmissions.fulfilled, (state, action) => {
            state.status = 'idle';
            const products = action.payload?.success
            state.submissions = products.filter((product) => product.status == 'active');
            state.draftSubmissions = products.filter((product) => product.status == 'draft');
            state.trashSubmissions = products.filter((product) => product.status == 'trash');
        });
        builder.addCase(fetchSubmissions.rejected, (state, action) => {
            state.status = 'error';
        });

        builder.addCase(addProduct.pending, (state, action) => {
            state.status = 'loading';
        });
        builder.addCase(addProduct.fulfilled, (state, action) => {
            state.status = 'idle';
        });
        builder.addCase(addProduct.rejected, (state, action) => {
            state.status = 'error';
            console.log(action);
        });

        builder.addCase(restoreProduct.rejected, (state, action) => {
            console.log(action);
        });

        builder.addCase(restoreProduct.fulfilled, (state, action) => {
            console.log(action);
        });


        builder.addCase(addPriceByState.rejected, (state, action) => {
            console.log(action);
            state.status = 'error';
        });

        builder.addCase(addPriceByState.fulfilled, (state, action) => {
            state.status = 'idle';
            console.log(action);
        });

        builder.addCase(fetchProduct.fulfilled, (state, action) => {
            state.currentProduct = action.payload.success;
            state.status = 'idle';
        });

        builder.addCase(addProductsBulk.fulfilled, (state, action) => {
            console.log(action);
            return state;
        }).addCase(addProductsBulk.rejected, (state, action) => {
            console.log(action); state.status = 'error';
        });

        builder.addCase(deleteProduct.pending, (state, action) => {
        });
        builder.addCase(deleteProduct.fulfilled, (state, action) => {
        });
        builder.addCase(deleteProduct.rejected, (state, action) => {
            console.log(action);
        });

    }
});




export const selectProducts = (state: any) => state.products.products;
export const selectTotalProducts = (state: number) => state.products.totalProducts;
export const selectTrash = (state: any) => state.products.trashProducts;
export const selectDraft = (state: any) => state.products.draftProducts;
export const selectProductsStatus = (state: any) => state.products.status;
export const selectProduct = (state: any) => state.products.currentProduct;
export const selectSubmissions = (state: any) => state.products.submissions;
export const selectDraftSubmissions = (state: any) => state.products.draftSubmissions;
export const selectTrashSubmissions = (state: any) => state.products.trashSubmissions;
export const selectStatus = (state: any) => state.products.status;

export default productsSlice.reducer;
