import { forwardRef } from "react";
import { useSelector } from "react-redux";
import { currentUserSelector } from "src/plugins/redux/reducers/AuthReducer";

const AskAQuestion = forwardRef((props: any, ref: any): JSX.Element => {
  const currentUser = useSelector(currentUserSelector);

  const rfp = props.rfp || {};
  return (
    <div className="row">
      <div className="col-12 col-md-12">
        <div className="card p-6 bg-custom-light rounded-xl">
          <div className="d-flex align-items-center justify-content-between text-center">
            <div className="d-flex align-items-center gap-2">
              <div>
                <img src={rfp.logo?.asset_file} width="130px" className="rounded-xl" />
              </div>
              <div className="col-8 col-md-8 text-left">
                <span className="d-block w-100 kt_section mb-3">
                  {rfp.rfp_title}
                </span>
                <span className="d-block w-100 text-muted">Managed by:</span>
                {rfp?.comInfo?.name}
              </div>
            </div>
            <div>
              {currentUser?.role_id != 2 && <button type="button" className="btn btn-pill btn-outline-custom-primary" data-toggle="modal" data-target="#contactFormModal">
                Ask a Question
              </button>}

            </div>
          </div>
        </div>
      </div>
    </div>
  );

});

export default AskAQuestion;
