import axios from 'plugins/axios';
import floatAlert from "plugins/floatAlert";
import { Buffer } from "buffer";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';

/* Contracts */
import StateContract from 'plugins/redux/contracts/StateContract';
import StateContractAuth from 'plugins/redux/contracts/StateAuthSessionContract';
import Cookies from "js-cookie"



/* Core */
const getJwtData = (token: string): any => {
  const jwt = token.split('.');
  return JSON.parse(Buffer.from(jwt[1], 'base64').toString());
}

const getSession = (): any => {
  const session = {
    auth: false,
    currentUser: null,
    token: null,
    userData: {}
  };

  const localStorageSession: any = Cookies.get('login_as') ?? Cookies.get('session');

  if (!localStorageSession) return session;

  return {
    auth: localStorageSession,
    currentUser: getJwtData(localStorageSession),
    token: localStorageSession
  };
};

/* Initial State */
const initialState: StateContractAuth = getSession();

/* Requests */
export const loginRequest = createAsyncThunk('auth/login', async (request: any) => {

  const formData = new FormData();
  formData.append('email', request.email);
  formData.append('password', request.password);
  formData.append('remember', request.remember);
  formData.append('verfication', request.verfication);

  try {
    const { data } = await axios.post('/login', formData);
    return data;
  } catch (error: any) {
    return error.response?.data?.error
  }

});

export const verifyEmail = createAsyncThunk('auth/verifyemail', async (token: any) => {

  const formData = new FormData();
  formData.append('token', token);

  try {
    const { data } = await axios.post('/verification', formData);
    return data;
  } catch (error: any) {
    return error.response?.data?.error
  }

});



export const confirmationRequest = createAsyncThunk('auth/reset', async (request: any) => {

  const formData = new FormData();
  formData.append('confirmation_code', request.confirmation_code);
  formData.append('new_password', request.new_password);
  formData.append('confirm_new_password', request.confirm_new_password);
  try {
    const { data } = await axios.post('/confirmation', formData);
    return data;
  } catch (error: any) {
    return error.response.data.error
  }

});

export const resetPasswordRequest = createAsyncThunk('auth/reset', async (request: any) => {

  const formData = new FormData();
  formData.append('email', request.email);
  try {
    const { data } = await axios.post('/reset', formData);
    return data;
  } catch (error: any) {
    return error.response.data.error
  }

});

export const updateToken = createAsyncThunk('auth/updateToken', async (newToken: any) => {
  console.log('updateTokenupdateTokenupdateTokenupdateToken');
  return newToken;
});

export const verificationRequest = createAsyncThunk('auth/verification', async (request: any) => {

  const formData = new FormData();
  formData.append('email', request.email);
  formData.append('verification_code', request.verification_code);

  try {
    const { data } = await axios.post('/verification', formData);
    return data;
  } catch (error: any) {
    return error.response.data.error
  }

});

export const registerRequest = createAsyncThunk('auth/register', async (request: any) => {
  const formData = new FormData();
  formData.append('first_name', request.first_name);
  formData.append('last_name', request.last_name);
  formData.append('email', request.email);
  formData.append('password', request.password);
  formData.append('password_confirmation', request.password_confirmation);
  formData.append('company', request.company);
  formData.append('role_id', request.role_id);
  formData.append('agreed_at', request.agreed_at);

  try {
    const { data } = await axios.post('/register', formData);
    return data;
  } catch (error: any) {
    return error.response.data.error
  }
});

export const loginAs = createAsyncThunk('users/login', async (company_id) => {
  const response = await axios.post(`/userlogin/create`, { company_id: company_id });
  return response.data;
});

export const loginAsUser = createAsyncThunk('users/userlogin', async (userEmail) => {
  const response = await axios.post(`/userlogin/create`, { userEmail: userEmail });
  return response.data;
});

export const getUserData = createAsyncThunk('users/getUserData', async () => {
  const response = await axios.get(`/users_rep`);
  return response.data;
});





export const logout = createAsyncThunk('auth/logout', async () => {
  Cookies.remove('session');
  Cookies.remove('login_as');
});



/* Slice */
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state = action.payload;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(loginRequest.pending, (state, action) => {
      return state;
    });
    builder.addCase(getUserData.fulfilled, (state: any, action) => {
      state.userData = action.payload.user;
      return state;
    });

    builder.addCase(loginRequest.fulfilled, (state: any, action) => {
      if (typeof action.payload === 'object' && action.payload.success) {
        const isAuth = !!action.payload.success.token;
        const token = action.payload.success.token;
        const currentUser = getJwtData(token);
        Cookies.set('session', token);
        state.auth = !!token;
        state.currentUser = currentUser;
        state.token = token;
        return state;
      } else {
        return state;
      }

    });

    builder.addCase(updateToken.fulfilled, (state, action) => {
      console.log(action, "actionactionactionactionaction")
      const newToken = action.payload;
      const currentUser = getJwtData(newToken);
      // Cookies.remove('session');
      Cookies.set('session', newToken);
      // state.auth = !!newToken;
      // state.currentUser = currentUser;
      // state.token = newToken;
      return state;
    });


    builder.addCase(loginRequest.rejected, (state: any, action) => {
      return state;
    });

    builder.addCase(registerRequest.pending, (state, action) => {
      return state;
    }).addCase(registerRequest.fulfilled, (state: any, action) => {
      console.log(action)
      if (!action.payload.success) return action.payload.error
      return state;
    });

    builder.addCase(logout.fulfilled, (state) => {
      state.auth = false;
      state.currentUser = null;
      state.token = null;
    });

    builder.addCase(verifyEmail.fulfilled, (state) => {
      console.log(state);
    });

    builder.addCase(loginAs.fulfilled, (state, action) => {
      const login_as_token = action.payload.success?.token;
      Cookies.set('login_as', login_as_token);
      window.open('/login-as', '_blank');
    });

    builder.addCase(loginAsUser.fulfilled, (state, action) => {
      const login_as_token = action.payload.success?.token;
      Cookies.set('login_as', login_as_token);
      window.open('/login-as', '_blank');
    });
  },
});

/* Actions */
export const authActions = authSlice.actions;

/* Selectors */
export const authSelector = (state: StateContract): StateContractAuth => state.auth;
export const me = (state: StateContract): any => state.auth.userData;
export const tokenSelector = (state: StateContract): StateContractAuth => state.auth.token;
export const currentUserSelector = (state: StateContract) => state.auth.currentUser;
export default authSlice.reducer;
