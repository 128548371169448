import React, { useState, useEffect } from 'react';
import moment from 'moment';

const TrialTimer = ({ endDate }) => {
  const calculateTimeLeft = () => {
    const now = moment();
    console.log("🚀 ~ calculateTimeLeft ~ now:", now)
    const end = moment(endDate, "YYYY-MM-DD HH:mm:ss", "America/New_York");
    console.log("🚀 ~ calculateTimeLeft ~ end:", end)
    const difference = end.diff(now, 'days');
    console.log("🚀 ~ calculateTimeLeft ~ difference:", difference)

    return { days: difference >= 0 ? difference : 0 };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  let message;
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000 * 60 * 60 * 24);

    return () => clearInterval(timer);
  }, []);

  const formattedEndDate = moment(endDate).format("MM/DD/YY");
  if (timeLeft.days == 1) {
    message = 'Free Trial: ' + timeLeft.days + ' Day Left';
  } else if (timeLeft.days < 1) {
    message = 'Free Trial: Last Day';
  } else {
    message = 'Free Trial: ' + timeLeft.days + ' Days Left';
  }
  return (
    <div className="user-access">
      {message}
    </div>
  );
};

export default TrialTimer;
