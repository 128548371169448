import { forwardRef } from "react";
import selectStyles from "src/themes/Helpers";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    selectProducers,
    fetchProducers,
} from "src/plugins/redux/reducers/DropdownDataReducer";
import { useParams } from "react-router-dom";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
    fetchItems,
    selectItems,
    selectStatus,
} from "src/plugins/redux/reducers/RFPsReducer";

const RFPNameFilter = forwardRef((props: any, ref: any): JSX.Element => {

    const filters = props.filters;
    console.log("🚀 ~ RFPNameFilter ~ filters:", filters)
    const setFilters = props.setFilters;
    const customSelectStyles = selectStyles.filterSelectStyles;
    const dispatch = useDispatch();
    const { id } = useParams();
    const rfps = useSelector(selectItems);

    useEffect(() => {
        dispatch(
            fetchItems()
        );
    }, [])

    return (
        <div className="d-flex align-items-center justify-content-between flex-warap mr-5">
            <div className="d-flex align-items-center justify-content-start gap-1 flex-warap">
                <div>
                    <FormControl>
                        <InputLabel id="category-select-label">RFP Name</InputLabel>
                        <Select
                            labelId="category-select-label"
                            label="RFP Name"
                            id="rfp-select"
                            value={filters?.rfp_id || ""}
                            sx={{ borderRadius: "50px", width: "12rem", height: "4rem" }}
                            onChange={(e) => {
                                setFilters({ ...filters, rfp_id: e.target.value })
                            }}
                        >
                            {rfps?.length > 0 &&
                                rfps.map((rfp) => (
                                    <MenuItem key={rfp.id} value={rfp.id}>
                                        {rfp.rfp_title}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
            {filters?.rfp_id && (
                <div>
                    <IconButton color="default" onClick={() => setFilters({ ...filters, rfp_id: null })}>
                        <CloseIcon />
                    </IconButton>
                </div>
            )}
        </div>
    );
});

export default RFPNameFilter;
