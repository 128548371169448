import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'plugins/axios';
import Cookies from "js-cookie"

interface Subscription {
    id: string;
    user_id: string;
    company_id: string;
    customer_id: string;
    subscription_id: string;
    quantity: string;
    status: string;
    start_date: string;
    end_date: string;
}

interface SubscriptionState {
    subscriptionCount: Subscription[];
    status: 'idle' | 'loading' | 'failed';
}

const initialState: SubscriptionState = {
    subscriptionCount: [],
    status: 'idle',
};

export const fetchCompanySubscriptions= createAsyncThunk('subscriptions/fetchsubscriptions', async () => {
    const response = await axios.get('/subscriptions');
    return response.data;
});

export const CreateCompanySubscriptions = createAsyncThunk("subscriptions/create", async () => {
      try {
          const response = await axios.post("/subscriptions/create");
          console.log("🚀 ~ CreateCompanySubscriptions ~ response:", response)
          return response.data;
      } catch (err) {
        return err;
      }
    }
  );

const SubscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompanySubscriptions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCompanySubscriptions.fulfilled, (state, action) => {
                state.status = 'idle';
                state.subscriptionCount = action.payload.subscriptions;
            })
            .addCase(fetchCompanySubscriptions.rejected, (state) => {
                state.status = 'failed';
            })

            .addCase(CreateCompanySubscriptions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(CreateCompanySubscriptions.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(CreateCompanySubscriptions.rejected, (state) => {
                state.status = 'failed';
            })
    }
});

export const selectSubscriptionCount = (state) => state.subscriptions.subscriptionCount;
export default SubscriptionsSlice.reducer;
