import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "plugins/axios";
import Cookies from "js-cookie";

// @ts-ignore
import { uploadFile } from "react-s3";
import toast from "react-hot-toast";
window.Buffer = window.Buffer || require("buffer").Buffer;

const S3_BUCKET = "bevbridge-assets";
const REGION = "us-east-1";
const ACCESS_KEY = "AKIAQJX3Z43FJW3MEBDC";
const SECRET_ACCESS_KEY = "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";

interface Item {
  id: number;
  name: string;
}

interface ItemsState {
  items: Item[];
  products: any[];
  rfpProducts: any[];
  companyItems: [];
  currentItem: {};
  companyUsers: [];
  favorites: any[];
  status: "idle" | "loading" | "error";
  supplierUploads: any[];
}

const initialState: ItemsState = {
  items: [],
  products: [],
  companyItems: [],
  rfpProducts: [],
  currentItem: {},
  companyUsers: [],
  favorites: [],
  status: "idle",
  supplierUploads: [],
};


export const uploadImageToS3 = createAsyncThunk(
  "items/uploadImageToS3",
  async (file: {}) => {
    const config = {
      bucketName: S3_BUCKET,
      region: REGION,
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      dirName: file.dirName,
    };
    const newFileName = file.newFileName;
    var url = await uploadFile(file.file, config, newFileName)
      .then((data) => {
        return data.location;
      })
      .catch((err) => console.error(err));
    return url;
  }
);

/* fetch rfps */
export const fetchItems = createAsyncThunk(
  "items/fetchItems",
  async (fetchParams = {}, thunkAPI) => {
    const params = {
      sortType: fetchParams.sortType,
      sortBy: fetchParams.sortBy,
    };
    if (fetchParams.query) {
      params.query = fetchParams.query;
    }
    if (fetchParams.limit) {
      params.limit = fetchParams.limit;
      params.page = 1;
    }
    if (fetchParams.filterBy) {
      params.filterBy = JSON.stringify(fetchParams.filterBy);
    }
    console.log(params);
    const response = await axios.get("/rfps", { params });
    return response.data;
  }
);

export const fetchSupplierUploads = createAsyncThunk("items/fetchSupplierUploads", async (rfp_id, thunkAPI) => {
  const params = {
    rfp_id: rfp_id,
  };
  const response = await axios.get('/supplier_uploads', { params });
  return response.data;
});

export const fetchComapnyRfps = createAsyncThunk(
  "items/fetchCompanyRfps",
  async (fetchItems = {}, thunkAPI) => {
    const params = {
      query: fetchItems.query,
    };
    const response = await axios.get(
      `/companies_rfps/${fetchItems.id}?query=${fetchItems.query}`
    );
    return response.data;
  }
);

export const sendContactEmail = createAsyncThunk("items/sendContactEmail", async (data, thunkAPI) => {
  const formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key]);
  }
  const response = await axios.post('/contact/create', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}
);


/* fetch rfp products */
export const fetchRfpProducts = createAsyncThunk(
  "items/fetchRfpProducts",
  async (filterBy: any, thunkAPI) => {
    const params = {
      rfp_id: filterBy.rfp_id,
    };
    try {
      const response = await axios.get("/submissions", { params });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const addRfpRequests = createAsyncThunk(
  "items/addRfpRequests",
  async (data) => {

    try {
      const formData = new FormData();
      data.requests.map((request) => {
        formData.append("requests[]", JSON.stringify(request));
      });
      formData.append('rfp_id', data.rfp_id);
      const response = await axios.post("/requests/bulk", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

/* fetch rfp products */
export const fetchRfpProductsByID = createAsyncThunk(
  "items/fetchRfpProductsByID",
  async (id: number, thunkAPI) => {
    try {
      const response = await axios.get(`/rfps/${id}`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

/* fetch rfp */
export const fetchItem = createAsyncThunk(
  "items/fetchItem",
  async (filterBy = {}, thunkAPI) => {
    const params = {
      filterBy: JSON.stringify(filterBy.filters),
      sortBy: filterBy.sortBy,
      sortType: filterBy.sortType,
    };
    try {
      const response = await axios.get(`/rfps/${filterBy.id}`, { params });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

/* add rfp */
export const addRFP = createAsyncThunk(
  "rfps/create",
  async (data: any, thunkAPI) => {
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const response = await axios.post("/rfps/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

/* add CSV file rfp */
export const addCSV = createAsyncThunk(
  "requests/bulk",
  async (data: any, thunkAPI) => {

    try {
      const formData = new FormData();
      requestData.requests.forEach((request, index) => {
        formData.append(`requests[${index}]`, JSON.stringify(request));
      });

      const response = await axios.post("/requests/bulk", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const inviteBeveragePartners = createAsyncThunk(
  "rfps/inviteBeveragePartners",
  async (data, thunkAPI) => {
    try {
      const params = {
        emails: data.emails,
        rfp_id: data.rfp_id,
        type: data.type,
      };

      const response = await axios.get("/invite", { params });
      return response.data;
    } catch (err) {
      if (err?.message === "Request failed with status code 500") {
        toast.error("Invalid Supplier Company. Please check the company domain");
      }
      return thunkAPI.rejectWithValue(err);
    }
  }
);

/* update rfp */
export const updateRFP = createAsyncThunk(
  "items/updateRFP",
  async (data: any, thunkAPI) => {
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const response = await axios.put(
        "/rfps/" + data.id + "/update",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const addRfpProducts = createAsyncThunk(
  "items/addRfpProducts",
  async (data: any, thunkAPI) => {
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const response = await axios.post("/rfpsProducts/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchCompanyUsers = createAsyncThunk(
  "items/fetchCompanyUsers",
  async (company_id) => {
    const params = {
      company_id: company_id,
    };
    const response = await axios.get("/users", { params });
    return response.data;
  }
);

//Favorites

export const fetchFavorites = createAsyncThunk(
  "favorites/fetchFavorites",
  async (id, thunkAPI) => {
    const params = {
      rfp_id: id,
    };
    try {
      const response = await axios.get(`/favorite`, { params });

      return response.data.success;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const addRfpSupplierUploads = createAsyncThunk('items/addRfpSupplierUploads', async (data, thunkAPI) => {
  const formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key]);
  }
  const response = await axios.post('/supplier_uploads/create', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
});

export const addFavorite = createAsyncThunk(
  "favorites/addFavorite",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post("/favorite/create", data, {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      });

      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteFavorite = createAsyncThunk(
  "favorites/deleteFavorite",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(
        `/favorite/${id}/delete`,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const itemsSlice = createSlice({
  name: "rfps",
  initialState,
  reducers: {
    resetItems: (state) => {
      state.items = [];
    },
    resetCurrentItem: (state) => {
      state.currentItem = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchItems.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchItems.fulfilled, (state, action) => {
        state.status = "idle";
        console.log(action);
        state.items = action.payload.success as any;
      })
      .addCase(fetchItems.rejected, (state, action) => {
        state.status = "error";
        console.log(action);
      });

    builder
      .addCase(fetchComapnyRfps.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchComapnyRfps.fulfilled, (state, action) => {
        state.status = "idle";
        state.companyItems = action.payload.success as any;
      })
      .addCase(fetchComapnyRfps.rejected, (state, action) => {
        state.status = "error";
      });

    builder
      .addCase(addRFP.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addRFP.fulfilled, (state, action) => {
        state.status = "idle";
        console.log(action);
      })
      .addCase(addRFP.rejected, (state, action) => {
        console.log(action);
        state.status = "error";
      });

    builder
      .addCase(addCSV.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addCSV.fulfilled, (state, action) => {
        state.status = "idle";
        console.log(action);
      })
      .addCase(addCSV.rejected, (state, action) => {
        console.log(action);
        state.status = "error";
      });

    builder
      .addCase(updateRFP.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateRFP.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(updateRFP.rejected, (state, action) => {
        state.status = "error";
      });

    builder
      .addCase(fetchRfpProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRfpProducts.fulfilled, (state, action) => {
        state.status = "idle";
        console.log(action);
        state.products = action.payload.success as any;
      })
      .addCase(fetchRfpProducts.rejected, (state, action) => {
        state.status = "error";
        console.log(action);
      });

    builder
      .addCase(fetchItem.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchItem.fulfilled, (state, action) => {
        state.status = "idle";
        console.log(action);
        state.currentItem = action.payload.success as any;
      })
      .addCase(fetchItem.rejected, (state, action) => {
        state.status = "error";
        console.log(action);
      });

    builder
      .addCase(uploadImageToS3.pending, (state) => {
        state.status = "loading";
      })
      .addCase(uploadImageToS3.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(uploadImageToS3.rejected, (state, action) => {
        state.status = "error";
      });

    builder
      .addCase(fetchRfpProductsByID.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRfpProductsByID.fulfilled, (state, action) => {
        state.status = "idle";
        state.rfpProducts = action.payload.success as any;
      })
      .addCase(fetchRfpProductsByID.rejected, (state, action) => {
        state.status = "error";
      });

    builder
      .addCase(fetchCompanyUsers.pending, (state, action) => {
        console.log(action);
        state.status = "loading";
      })
      .addCase(fetchCompanyUsers.fulfilled, (state, action) => {
        console.log(action);
        state.status = "idle";
        state.companyUsers = action.payload.success;
      })
      .addCase(fetchCompanyUsers.rejected, (state, action) => {
        console.log(action);
        state.status = "error";
      });
    //Favorites

    builder
      .addCase(fetchFavorites.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFavorites.fulfilled, (state, action) => {
        state.status = "idle";
        state.favorites = action.payload;
      })
      .addCase(fetchFavorites.rejected, (state, action) => {
        state.status = "error";
      });

    builder
      .addCase(sendContactEmail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendContactEmail.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(sendContactEmail.rejected, (state, action) => {
        state.status = "error";
      });



    builder.addCase(addFavorite.fulfilled, (state, action) => {
      state.favorites.push(action.payload);
    });

    builder.addCase(deleteFavorite.fulfilled, (state, action) => {
      state.favorites = state.favorites.filter(
        (f) => f.id !== action.payload.id
      );
    });

    builder.addCase(fetchSupplierUploads.fulfilled, (state, action) => {
      state.supplierUploads = action.payload.success;
    });

    builder.addCase(addRfpSupplierUploads.fulfilled, (state, action) => {
      // state.supplierUploads = action.payload.success;
    });
  },
});

export const selectItems = (state: any) => state.rfps.items;
export const selectStatus = (state: any) => state.rfps.status;
export const selectRfpProducts = (state: any) => state.rfps.products;
export const selectRfpProductsRequests = (state: any) => state.rfps.rfpProducts;
export const selectCurrentItem = (state: any) => state.rfps.currentItem;
export const selectCompanyItems = (state: any) => state.rfps.companyItems;
export const selectCompanyUsers = (state: any) => state.rfps.companyUsers;
export const selectFavorites = (state: any) => state.rfps.favorites;
export const selectSupplierUploads = (state: any) => state.rfps.supplierUploads;
export default itemsSlice.reducer;
