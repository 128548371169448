import axios from 'axios';
import Cookies from 'js-cookie';

const token = Cookies.get('session');
const login_as_token = Cookies.get('login_as');

const AxiosPlugin = axios.create({
  baseURL: 'https://api-stage.bevbridge.com/',
});

AxiosPlugin.interceptors.request.use((config) => {
  config.params = {
    ...config.params,
    token: login_as_token ?? token,
  };

  return config;
});

export default AxiosPlugin;