import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'plugins/axios';
import toast from 'react-hot-toast';
// @ts-ignore
import { uploadFile } from 'react-s3';
import Cookies from "js-cookie";

interface User {
    id: number;
    name: string;
}

interface UsersState {
    status: 'idle' | 'loading' | 'error';
}

const initialState: UsersState = {
    status: 'idle',
};

const S3_BUCKET = "bevbridge-assets";
const REGION = "us-east-1";
const ACCESS_KEY = "AKIAQJX3Z43FJW3MEBDC";
const SECRET_ACCESS_KEY = "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";

export const EditUser = createAsyncThunk('companies/EditUser', async (data) => {
    try {
        const formData = new FormData();
        for (const key in data) {
            formData.append(key, data[key]);
        }
        const response = await axios.put(`/users_rep/${data.id}/update`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error: any) {
        return error.response?.data.error
    }
});

export const getHelp = createAsyncThunk('getHelp/create', async (data) => {

    try {
        const formData = new FormData();
        for (const key in data) {
            formData.append(key, data[key]);
        }
        const response = await axios.post(`/get_help/create`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error: any) {
        return error.response?.data.error
    }
});

export const uploadImageToS3 = createAsyncThunk('items/uploadImageToS3', async (file: {}) => {
    const config = {
        bucketName: S3_BUCKET,
        region: REGION,
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_ACCESS_KEY,
        dirName: file.dirName,
    }
    var url = await uploadFile(file.file, config)
        .then(data => {
            return data.location
        })
        .catch(err => console.error(err))
    return url;
});



const UsersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(EditUser.pending, (state) => {
            state.status = 'loading';
        }).addCase(EditUser.fulfilled, (state, action) => {
            state.status = 'idle';
            state.agencies = action.payload.success;
        }).addCase(EditUser.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(uploadImageToS3.pending, (state) => {
            state.status = 'loading';
        }).addCase(uploadImageToS3.fulfilled, (state, action) => {
            state.status = 'idle';
            console.log(action);
            state.currentItem = action.payload.success as any;
        }).addCase(uploadImageToS3.rejected, (state, action) => {
            state.status = 'error';
            console.log(action);
        });

        builder.addCase(getHelp.pending, (state) => {
            state.status = 'loading';
        }).addCase(getHelp.fulfilled, (state, action) => {
            state.status = 'idle';
            console.log(action);
            state.currentItem = action.payload.success as any;
        }).addCase(getHelp.rejected, (state, action) => {
            state.status = 'error';
            console.log(action);
        });
    }
});


export default UsersSlice.reducer;
