import { selectSupplierUploads, fetchSupplierUploads, uploadImageToS3, addRfpSupplierUploads } from 'src/plugins/redux/reducers/RFPsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { currentUserSelector } from "src/plugins/redux/reducers/AuthReducer";
import {
    setHeaderTitle,
    setHeaderActionBtns,
    setHeaderArrowBack,
    setShowSearch,
} from "src/plugins/redux/reducers/HeaderReducer";

interface supplierUploadsProps {
    uploads: any;
    rfp_id: string;
}

export default function supplierUploads({ uploads, rfp_id }: supplierUploadsProps) {

    const dispatch = useDispatch();
    const [rfpUploads, setRfpUploads] = useState<any>({});
    const supplier_uploads = useSelector(selectSupplierUploads);

    const [supplierUploads, setSupplierUploads] = useState<any>({})

    const currentUser = useSelector(currentUserSelector);

    useEffect(() => {
        dispatch(setHeaderTitle("Suppliers"));
        setRfpUploads({
            rfp_presentation: uploads?.rfp_presentation == 1,
            lto: uploads?.lto == 1,
            training_resources: uploads?.training_resources == 1,
            recipes: uploads?.recipes == 1,
            pricing_data: uploads?.pricing_data == 1,
            sales_data: uploads?.sales_data == 1,
            trend_data: uploads?.trend_data == 1
        });
        if (rfp_id) {
            dispatch(fetchSupplierUploads(rfp_id));
        }
    }, [uploads, rfp_id]);

    useEffect(() => {
        if (supplier_uploads) {
            supplier_uploads?.map((upload: any) => {
                setSupplierUploads((prevFiles) => ({
                    ...prevFiles,
                    [upload.assets_type]: upload.asset_file
                }));
            });
        }
    }, [supplier_uploads]);



    const handleSupplierFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const field_name = event.target.name;
            const file = event.target.files[0];
            const supplier_assets = supplierUploads;
            const newName = generateFileName(file.name,field_name)
            const newFile = new File([file], newName, { type: file.type })


            var key = event.target.name;
            if (newFile) {
                const reader = new FileReader();
                reader.onload = async (event) => {
                    const file_url = await dispatch(
                        uploadImageToS3({ file: newFile, dirName: "rfp" })
                    );
                    supplier_assets[key] = file_url ? file_url.payload : "";
                    const response = await dispatch(
                        addRfpSupplierUploads({
                            supplier_assets: JSON.stringify(supplier_assets),
                            rfp_id: rfp_id,
                        })
                    );
                    if (response.payload?.success) {
                        dispatch(fetchSupplierUploads(rfp_id));
                    }
                };
                reader.readAsDataURL(newFile);
            }

        } catch (error) {
            console.log(error);
        }
    };

    const generateFileName = (originalName: string, field_name) => {
        const supplierId = currentUser.company_id
        const rfpId = rfp_id
        const fieldName = field_name
        const originalfileName = originalName
        const newName = `${supplierId}_${rfpId}_${fieldName}_${originalfileName}`
        return `${newName}`
    }

    const handleRemoveSupplierFile = async (key: string) => {
        const supplier_assets = supplierUploads;
        delete supplier_assets[key];
        const response = await dispatch(
            addRfpSupplierUploads({
                supplier_assets: JSON.stringify(supplier_assets),
                rfp_id: rfp_id,
            })
        );
        if (response.payload?.success) {
            dispatch(fetchSupplierUploads(rfp_id));
        }
    }

    return (
        <div className="my-5">
            <h2 className="fw-bold mb-5">Presentation Upload</h2>
            <div className="d-flex align-items-center gap-3">
                {rfpUploads && Object.keys(rfpUploads)?.map((upload: any, index: number) => {
                    if (rfpUploads[upload]) {
                        switch (upload) {
                            case 'rfp_presentation':
                                return (
                                    <div className="d-inline-block">
                                        <label className="w-100">RFP Presentation</label>
                                        <div>
                                            {supplierUploads.supplier_uploads_rfp_presentation ? (
                                                <div className="d-flex align-items-center gap-1">
                                                    <span className="text-primary-blue file-name">
                                                        {supplierUploads?.supplier_uploads_rfp_presentation.substring(
                                                            supplierUploads?.supplier_uploads_rfp_presentation.lastIndexOf("/") + 1
                                                        )}
                                                    </span>
                                                    <i
                                                        className="fa fa-times fa-lg cursor-pointer"
                                                        onClick={() => handleRemoveSupplierFile('supplier_uploads_rfp_presentation')}
                                                    ></i>
                                                </div>
                                            ) : (
                                                <label className="btn btn-outline-custom-primary btn-pill m-0 d-flex align-items-center gap-1">
                                                    <input
                                                        type="file"
                                                        name="supplier_uploads_rfp_presentation"
                                                        className="d-none rfp-csv-file"
                                                        onChange={handleSupplierFileChange}
                                                    />
                                                    Browse
                                                    <i className="la la-upload"></i>
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                )
                                break;
                            case 'lto':
                                return (
                                    <div className="d-inline-block">
                                        <label className="w-100">LTOs & Programming</label>
                                        <div>
                                            {supplierUploads.supplier_uploads_lto ? (
                                                <div className="d-flex align-items-center gap-1">
                                                    <span className="text-primary-blue file-name">
                                                        {supplierUploads?.supplier_uploads_lto.substring(
                                                            supplierUploads?.supplier_uploads_lto.lastIndexOf("/") + 1
                                                        )}
                                                    </span>
                                                    <i
                                                        className="fa fa-times fa-lg cursor-pointer"
                                                        onClick={() => handleRemoveSupplierFile('supplier_uploads_lto')}
                                                    ></i>
                                                </div>
                                            ) : (
                                                <label className="btn btn-outline-custom-primary btn-pill m-0 d-flex align-items-center gap-1">
                                                    <input
                                                        type="file"
                                                        name="supplier_uploads_lto"
                                                        className="d-none rfp-csv-file"
                                                        onChange={handleSupplierFileChange}
                                                    />
                                                    Browse
                                                    <i className="la la-upload"></i>
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                )
                                break;
                            case 'training_resources':
                                return (
                                    <>
                                        <div className="d-inline-block">
                                            <label className="w-100">Training Resources</label>
                                            <div>
                                                {supplierUploads.supplier_uploads_training_resources ? (
                                                    <div className="d-flex align-items-center gap-1">
                                                        <span className="text-primary-blue file-name">
                                                            {supplierUploads?.supplier_uploads_training_resources.substring(
                                                                supplierUploads?.supplier_uploads_training_resources.lastIndexOf("/") + 1
                                                            )}
                                                        </span>
                                                        <i
                                                            className="fa fa-times fa-lg cursor-pointer"
                                                            onClick={() => handleRemoveSupplierFile('supplier_uploads_training_resources')}
                                                        ></i>
                                                    </div>
                                                ) : (
                                                    <label className="btn btn-outline-custom-primary btn-pill m-0 d-flex align-items-center gap-1">
                                                        <input
                                                            type="file"
                                                            name="supplier_uploads_training_resources"
                                                            className="d-none rfp-csv-file"
                                                            onChange={handleSupplierFileChange}
                                                        />
                                                        Browse
                                                        <i className="la la-upload"></i>
                                                    </label>
                                                )}
                                            </div>
                                        </div>



                                    </>
                                )
                                break;
                            case 'recipes':
                                return (
                                    <div className="d-inline-block">
                                        <label className="w-100">Recipes</label>
                                        <div>
                                            {supplierUploads.supplier_uploads_recipes ? (
                                                <div className="d-flex align-items-center gap-1">
                                                    <span className="text-primary-blue file-name">
                                                        {supplierUploads?.supplier_uploads_recipes.substring(
                                                            supplierUploads?.supplier_uploads_recipes.lastIndexOf("/") + 1
                                                        )}
                                                    </span>
                                                    <i
                                                        className="fa fa-times fa-lg cursor-pointer"
                                                        onClick={() => handleRemoveSupplierFile('supplier_uploads_recipes')}
                                                    ></i>
                                                </div>
                                            ) : (
                                                <label className="btn btn-outline-custom-primary btn-pill m-0 d-flex align-items-center gap-1">
                                                    <input
                                                        type="file"
                                                        name="supplier_uploads_recipes"
                                                        className="d-none rfp-csv-file"
                                                        onChange={handleSupplierFileChange}
                                                    />
                                                    Browse
                                                    <i className="la la-upload"></i>
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                )
                                break;
                            case 'pricing_data':
                                return (
                                    <div className="d-inline-block">
                                        <label className="w-100">Pricing Data</label>
                                        <div>
                                            {supplierUploads.supplier_uploads_pricing_data ? (
                                                <div className="d-flex align-items-center gap-1">
                                                    <span className="text-primary-blue file-name">
                                                        {supplierUploads?.supplier_uploads_pricing_data.substring(
                                                            supplierUploads?.supplier_uploads_pricing_data.lastIndexOf("/") + 1
                                                        )}
                                                    </span>
                                                    <i
                                                        className="fa fa-times fa-lg cursor-pointer"
                                                        onClick={() => handleRemoveSupplierFile('pricing_data')}
                                                    ></i>
                                                </div>
                                            ) : (
                                                <label className="btn btn-outline-custom-primary btn-pill m-0 d-flex align-items-center gap-1">
                                                    <input
                                                        type="file"
                                                        name="supplier_uploads_pricing_data"
                                                        className="d-none rfp-csv-file"
                                                        onChange={handleSupplierFileChange}
                                                    />
                                                    Browse
                                                    <i className="la la-upload"></i>
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                )
                                break;
                            case 'sales_data':
                                return (
                                    <div className="d-inline-block">
                                        <label className="w-100">Sales Data</label>
                                        <div>
                                            {supplierUploads.supplier_uploads_sales_data ? (
                                                <div className="d-flex align-items-center gap-1">
                                                    <span className="text-primary-blue file-name">
                                                        {supplierUploads?.supplier_uploads_sales_data.substring(
                                                            supplierUploads?.supplier_uploads_sales_data.lastIndexOf("/") + 1
                                                        )}
                                                    </span>
                                                    <i
                                                        className="fa fa-times fa-lg cursor-pointer"
                                                        onClick={() => handleRemoveSupplierFile('supplier_uploads_sales_data')}
                                                    ></i>
                                                </div>
                                            ) : (
                                                <label className="btn btn-outline-custom-primary btn-pill m-0 d-flex align-items-center gap-1">
                                                    <input
                                                        type="file"
                                                        name="supplier_uploads_sales_data"
                                                        className="d-none rfp-csv-file"
                                                        onChange={handleSupplierFileChange}
                                                    />
                                                    Browse
                                                    <i className="la la-upload"></i>
                                                </label>
                                            )}
                                        </div>
                                    </div>

                                )
                                break;
                            case 'trend_data':
                                return (
                                    <div className="d-inline-block">
                                        <label className="w-100">Trend Data</label>
                                        <div>
                                            {supplierUploads.supplier_uploads_trend_data ? (
                                                <div className="d-flex align-items-center gap-1">
                                                    <span className="text-primary-blue file-name">
                                                        {supplierUploads?.supplier_uploads_trend_data.substring(
                                                            supplierUploads?.supplier_uploads_trend_data.lastIndexOf("/") + 1
                                                        )}
                                                    </span>
                                                    <i
                                                        className="fa fa-times fa-lg cursor-pointer"
                                                        onClick={() => handleRemoveSupplierFile('supplier_uploads_trend_data')}
                                                    ></i>
                                                </div>
                                            ) : (
                                                <label className="btn btn-outline-custom-primary btn-pill m-0 d-flex align-items-center gap-1">
                                                    <input
                                                        type="file"
                                                        name="supplier_uploads_trend_data"
                                                        className="d-none rfp-csv-file"
                                                        onChange={handleSupplierFileChange}
                                                    />
                                                    Browse
                                                    <i className="la la-upload"></i>
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                )
                                break;
                            default:
                                break;
                        }
                    }

                })}
                {!rfpUploads || rfpUploads.length === 0 && <p>No uploads available</p>}
            </div>
        </div>
    )
}

