import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    title: 'Manage RFPs',
    actionBtns: [{
        'link': '/create',
        'text': 'Add RFP'
    }],
    arrowBack: false,
    showSearch: false,
    search: null,
    titleLink: null,
    showPremiumHeader: false
};

const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        setHeaderTitle: (state, action) => {
            state.title = action.payload;
        },
        setHeaderActionBtns: (state, action) => {
            state.actionBtns = action.payload;
        },
        setHeaderArrowBack: (state, action) => {
            state.arrowBack = action.payload;
        },
        setShowSearch: (state, action) => {
            state.showSearch = action.payload;
        },
        setSearchValue: (state, action) => {
            state.search = action.payload;
        },
        setTitleLink: (state, action) => {
            state.titleLink = action.payload;
        },
        setShowPremiumHeader: (state, action) => {
            state.showPremiumHeader = action.payload;
        }
    },
});

export const { setHeaderTitle, setHeaderActionBtns, setHeaderArrowBack, setShowSearch, setSearchValue, setTitleLink, setShowPremiumHeader } = headerSlice.actions;
export default headerSlice.reducer;

